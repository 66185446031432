import { Subject } from 'rxjs';

import { Utils } from '@weavix/components/src/utils/utils';
import { MapCompany } from '@weavix/models/src/map/map';
import { CompanyServiceStub } from '@weavix/services/company.service';

import { StateServiceBase, StateUpdate } from './state-base.service';

export class CompanyStateService extends StateServiceBase {
    companies: Map<string, MapCompany> = new Map();
    companies$: Subject<StateUpdate<MapCompany>> = new Subject();

    constructor(
        private companyService: CompanyServiceStub
    ) {
        super();
    }

    async loadData(c: any, accountId: string, facilityId?: string, tags?: string[]) {
        this.companies = Utils.toMap(await this.companyService.getAll(c, tags));
    }

    async stop() {
        super.stop();
        this.companies.clear();
    }

    protected async setupSubscriptions(c: any) {
        return this.companyService.subscribeCompanyUpdates(c).subscribe(resp => {
            const companyId = resp.replacements[1];
            this.updateFromMap(this.companies, { [companyId]: resp.payload }, this.companies$);
        });
    }
}
