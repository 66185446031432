import { css } from '@weavix/domain/src/utils/css';

export enum DefaultItemType {
    Drum = 'drum',
    PressureVessel = 'pressure-vessel',
    Tank = 'tank'
}

export const defaultItemTypeSvgs: {[key in DefaultItemType]: (fillColor: string) => string } = {
    [DefaultItemType.Drum]: (fillColor: string = css.colors.DK_GRAY) => {
        return (`
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 500 500" style="enable-background:new 0 0 500 500;" xml:space="preserve" fill=${fillColor}>
                <path d="M494.9,269.3c-0.2,0-0.4,0-0.6,0.1v-0.1h-20.6c0.1-2.5,0.2-5.1,0.2-7.7c0-42.2-15.2-76.4-33.9-76.4h-18.3v-33.6
                c0,0.1,0,0.3-0.1,0.4c-1-3.1-10.9-5.6-23.1-5.6s-22.1,2.5-23.1,5.6c-0.1-0.1-0.1-0.3-0.1-0.4v33.6H126.8v-32.9c0,0.1,0,0.3-0.1,0.4
                c-1-3.1-10.9-5.6-23.1-5.6s-22.1,2.5-23.1,5.6c-0.1-0.1-0.1-0.3-0.1-0.4v32.9H64.5c-18.7,0-33.9,34.2-33.9,76.4
                c0,2.8,0.1,5.6,0.2,8.4H6.6v0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-3.1,0-5.6,10.1-5.6,22.5C0.5,304.9,3,315,6.1,315c0.2,0,0.4,0,0.6-0.1
                v0.1h33.7c6.2,14.2,14.7,23,24.2,23h45.2v14.6h11.5v-14.6h270.6v14.6h11.5v-14.6h18.5v-0.2h20.5c8.7-1.3,16.6-10,22.3-23.4h29.8
                v-0.1c0.2,0.1,0.4,0.1,0.6,0.1c3.1,0,5.6-10.1,5.6-22.5C500.5,279.4,498,269.3,494.9,269.3z"/>
            </svg>
        `);
    },
    [DefaultItemType.PressureVessel]: (fillColor: string = css.colors.DK_GRAY) => {
        return (`
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 500 500" style="enable-background:new 0 0 500 500;" xml:space="preserve" fill=${fillColor}>
                <path d="M363.6,116.1c0-0.3,0-0.5,0-0.8c0-2.2-0.7-4.2-1.9-5.9c-1.9-3.6-5.1-5.9-8.7-5.9h-2.2c-0.2,0-0.4,0-0.6,0c-0.3,0-0.6,0-1,0
                    h-33.9V77.9h-0.1c-2.1-34.6-34.9-62.1-75.1-62.1c-6.2,0-12.2,0.7-18,1.9V5c0,0.1,0,0.3-0.1,0.4c-0.9-2.7-9.6-4.9-20.3-4.9
                    s-19.4,2.2-20.3,4.9c0-0.1-0.1-0.3-0.1-0.4v35.3h0.2c-9.6,10.4-15.7,23.4-16.6,37.7h-0.1v34.9h-23.6v0.1c-0.2-0.1-0.3-0.1-0.5-0.1
                    c-2.7,0-4.9,8.9-4.9,19.8c0,10.9,2.2,19.8,4.9,19.8c0.2,0,0.3,0,0.5-0.1v0.1h23.6v69.7h-23.6v0.1c-0.2-0.1-0.3-0.1-0.5-0.1
                    c-2.7,0-4.9,8.9-4.9,19.8c0,10.9,2.2,19.8,4.9,19.8c0.2,0,0.3,0,0.5-0.1v0.1h23.6v69.7h-23.6v0.1c-0.2-0.1-0.3-0.1-0.5-0.1
                    c-2.7,0-4.9,8.9-4.9,19.8c0,10.9,2.2,19.8,4.9,19.8c0.2,0,0.3,0,0.5-0.1v0.1h23.6v19.3h-8.4v98.7h1.1c6.4,6.4,40.7,11.4,82,11.4
                    c41.3,0,75.6-4.9,82-11.4h1.1v-98.7h-7.5V127.2h24.9v263.5V488h23.5v-97.3V117.4C363.7,117,363.7,116.6,363.6,116.1z M241.5,476.9
                    c-16,0-29-13-29-29c0-16,13-29,29-29c16,0,29,13,29,29C270.5,463.9,257.5,476.9,241.5,476.9z"/>
            </svg>
        `);
    },
    [DefaultItemType.Tank]: (fillColor: string = css.colors.DK_GRAY) => {
        return (`
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="-200 0 700 500" style="enable-background:new 0 0 500 500;" xml:space="preserve"  fill=${fillColor}>
                <path d="M277.5,54.3c-22.4,0-43.9,0.8-63.9,2.3V8.9c0,0.3-0.1,0.5-0.2,0.7C211.7,4.2,194.6,0,173.7,0s-38,4.2-39.7,9.6
                    c-0.1-0.2-0.2-0.5-0.2-0.7v59C101.1,75.5,81.1,85.7,81.1,97v43.3H34.9v0.2c-0.3-0.1-0.6-0.2-1-0.2c-5.3,0-9.6,17.3-9.6,38.7
                    c0,21.4,4.3,38.7,9.6,38.7c0.3,0,0.6-0.1,1-0.2v0.2h46.2v127.2H34.9v0.2c-0.3-0.1-0.6-0.2-1-0.2c-5.3,0-9.6,17.3-9.6,38.7
                    c0,21.4,4.3,38.7,9.6,38.7c0.3,0,0.6-0.1,1-0.2v0.2h46.2v34.9c0,23.6,87.9,42.7,196.4,42.7s196.4-19.1,196.4-42.7V97
                    C473.9,73.4,385.9,54.3,277.5,54.3z"/>
            </svg>
        `);
    },
};

export const defaultItemTypeEntries: {[key in DefaultItemType]: {[key: string]: string }} = {
    [DefaultItemType.Drum]: {
        top1: 'Top 1',
        top2: 'Top 2',
        bottom1: 'Bottom 1',
        bottom2: 'Bottom 2',
    },
    [DefaultItemType.PressureVessel]: {
        top: 'Top',
        entry3: 'Entry 3',
        entry2: 'Entry 2',
        entry1: 'Entry 1',
        skirt: 'Skirt',
    },
    [DefaultItemType.Tank]: {
        top: 'Top',
        entry2: 'Entry 2',
        entry1: 'Entry 1',
    },
};

export const defaultEntryStyles: {[key in DefaultItemType]: {[key: string]: any}} = {
    [DefaultItemType.Drum]: {
        [defaultItemTypeEntries[DefaultItemType.Drum].top1]: { top: '30px', left: '0px' },
        [defaultItemTypeEntries[DefaultItemType.Drum].top2]: { top: '30px', right: '0px' },
        [defaultItemTypeEntries[DefaultItemType.Drum].bottom1]: { bottom: '30px', left: '0px' },
        [defaultItemTypeEntries[DefaultItemType.Drum].bottom2]: { bottom: '30px', right: '0px' },
    },
    [DefaultItemType.PressureVessel]: {
        [defaultItemTypeEntries[DefaultItemType.PressureVessel].top]: { top: '30px', right: '0px' },
        [defaultItemTypeEntries[DefaultItemType.PressureVessel].entry3]: { top: '30px', left: '0px' },
        [defaultItemTypeEntries[DefaultItemType.PressureVessel].entry2]: { top: '160px', left: '0px' },
        [defaultItemTypeEntries[DefaultItemType.PressureVessel].entry1]: { bottom: '30px', left: '0px' },
        [defaultItemTypeEntries[DefaultItemType.PressureVessel].skirt]: { bottom: '30px', right: '0px' },
    },
    [DefaultItemType.Tank]: {
        [defaultItemTypeEntries[DefaultItemType.Tank].top]: { top: '30px', left: '0px' },
        [defaultItemTypeEntries[DefaultItemType.Tank].entry2]: { top: '115px', left: '0px' },
        [defaultItemTypeEntries[DefaultItemType.Tank].entry1]: { bottom: '30px', left: '0px' },
    },
};
