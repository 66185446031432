import { Context, createStore, ExtendedModel, idProp, model, Model, prop } from '@weavix/mobx';
import { myUserId } from '../my-profile-store/my-profile-store';
import { userRef } from '../users-store/users-store';

export const getPerson = (accountId: string, personId = myUserId()) => {
    return peopleContext.get().get(getFullId(accountId, personId));
};

@model('Person')
export class Person extends Model({
    id: idProp, // === userId, identical for same person on different accounts
    userId: prop<string>(), // same as ID if person is still on the account. otherwise blank
    accountId: prop<string>(),
    personId: prop<string>(),
    crafts: prop<string[]>(),
    group: prop<string>(),
    wispId: prop<string>(),
    tags: prop<string[]>(),
    properties: prop<Array<{ key: string; value: string; }>>(),
    folderId: prop<string>(),
}) {
    constructor(...args) {
        super(...args);
        this.id = args[0].personId; // id from sync service is accountId-personId
    }

    update(arg) {
        super.update({ ...arg, id: arg.personId });
    }

    get user() {
        if (!this.userId) return;
        return userRef(this.id).maybeCurrent;
    }

    get companyId() {
        return this.user.companyId;
    }
}

const base = createStore<Person>('people', Person);

export const peopleContext = base.context as Context<PeopleStore>;
export const personRef = base.ref;

export const accountPersonRef = (accountId: string, personId: string) => {
    return personRef(getFullId(accountId, personId));
};

const getFullId = (accountId: string, id: string) => `${accountId}-${id}`;

@model('PeopleStore')
export class PeopleStore extends ExtendedModel(base.Store, {
}) {
    getPerson(personId: string, accountId: string, person?: Person) {
        const id = getFullId(accountId, personId);
        return this.get(id, person);
    }
}
