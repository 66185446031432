import { FilterResultType, Geofence, MapFilterResult, MapFilterView, MapPerson } from '@weavix/models/src/map/map';
import { ReplaySubject, Subject } from 'rxjs';

export abstract class MapSearchServiceStub {
    mapPersonCenter: Subject<string> = new Subject();
    mapPersonBadgeUpdate: Subject<MapPerson> = new Subject<MapPerson>();
    mapObjectClick: Subject<MapFilterResult> = new Subject();
    setSelectedMapObject$: Subject<{ id: string, type: FilterResultType }> = new Subject();
    clusterItemClick: Subject<any> = new Subject();
    toggleFilterPanel: Subject<void> = new Subject();
    activeViewStack: MapFilterView[] = [MapFilterView.Filter];
    activeViewChange$: Subject<MapFilterView> = new Subject();
    activeSearchFilter$: ReplaySubject<MapFilterResult> = new ReplaySubject();
    activeSearchQuery: string;
    selectedFilter: MapFilterResult;
    updateSearch$: Subject<string> = new Subject();
    selectedResult: MapFilterResult;
    handleResultClick$: Subject<MapFilterResult> = new Subject();

    abstract clearViewStack(): void;
    abstract clearResultSelection(): void;
    abstract clearFilterSelection(): void;
    abstract setSelection(selection?: MapFilterResult): void;
    abstract setActiveSearchFilter(cat?: MapFilterResult): void;
    abstract setActiveView(view?: MapFilterView): void;
    abstract getActiveView(): MapFilterView;
    abstract getFilter(geofenceMap: Map<string, Geofence>): any;
    abstract silentClearViewStack(): void;
    abstract goBack(): void;
    abstract updateSearch(query?: string);
}
