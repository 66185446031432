
import * as mediasoupClient from 'mediasoup-client';
import { MediasoupTransport } from './mediasoup-transport';
import { WebrtcInterface } from './webrtc-interface';

const camEncodings = [
    { scaleResolutionDownBy: 4, maxBitrate: 200000, scalabilityMode: 'S1T3' },
    { scaleResolutionDownBy: 2, maxBitrate: 1000000, scalabilityMode: 'S1T3' },
    { scaleResolutionDownBy: 1, maxBitrate: 5000000, scalabilityMode: 'S1T3' },
];

const screenEncodings = [
    { maxBitrate: 1000000, scalabilityMode: 'S1T3' },
    { maxBitrate: 5000000, scalabilityMode: 'S1T3' },
];

const audioCodecOptions = { opusStereo: false, opusDtx: true };
const videoCodecOptions = { videoGoogleStartBitrate: 1000 };

export class MediasoupConnection extends WebrtcInterface {
    private transport: MediasoupTransport;
    private screenshareProducer: mediasoupClient.types.Producer;
    private videoProducer: mediasoupClient.types.Producer;
    private audioProducer: mediasoupClient.types.Producer;
    private consumers: {[id: string]: Promise<mediasoupClient.types.Consumer>} = {};
    private spatialLayer: number;

    private lastStats: Map<string, any>;

    get audioEnabled() {
        return this.audioTrack?.enabled;
    }

    get videoEnabled() {
        return this.videoTrack?.enabled;
    }

    setAudio(track: MediaStreamTrack) {
        if (!track) {
            this.audioTrack?.stop();
        }
        this.audioTrack = track;
        this.audioProducer?.replaceTrack({ track });
        this.setStreams();
    }

    async setVideo(track: MediaStreamTrack, screenshare?: boolean) {
        if (screenshare) {
            if (this.videoProducer) {
                this.videoProducer.close();
                await this.transport.answer({ closeProducer: this.videoProducer.id });
            }
            if (!this.screenshareProducer && track) {
                this.screenshareProducer = await this.transport.transport.produce({ stopTracks: false, track, encodings: screenEncodings, codecOptions: videoCodecOptions });
                this.updateProducerLayer();
            }
        } else {
            if (this.screenshareProducer) {
                this.screenshareProducer.close();
                await this.transport.answer({ closeProducer: this.screenshareProducer.id });
            }
            if (!this.videoProducer && track) {
                this.videoProducer = await this.transport.transport.produce({ stopTracks: false, track, encodings: camEncodings, codecOptions: videoCodecOptions });
                this.updateProducerLayer();
            }
        }
        if (!track) {
            this.videoTrack?.stop();
        }
        this.videoTrack = track;
        this.videoProducer?.replaceTrack({ track });
        this.screenshareProducer?.replaceTrack({ track });
        this.setStreams();
    }

    close(reason) {
        if (this.closed) return;
        super.close(reason);

        if (this.screenshareProducer) {
            this.transport?.answer({ closeProducer: this.screenshareProducer.id });
            this.screenshareProducer.close();
        }
        if (this.videoProducer) {
            this.transport?.answer({ closeProducer: this.videoProducer.id });
            this.videoProducer.close();
        }
        if (this.audioProducer) {
            this.transport?.answer({ closeProducer: this.audioProducer.id });
            this.audioProducer.close();
        }
        Object.values(this.consumers).forEach(async consumer => {
            this.transport?.answer({ closeConsumer: (await consumer).id });
            (await consumer).close();
        });
        this.transport?.removeConnection(this, reason);
    }

    async transportReady() {
        // Clear out old stuff, transport is closed
        this.consumers = {};
        this.audioProducer = null;
        this.videoProducer = null;
        this.screenshareProducer = null;
        this.lastStats = null;

        const people = await WebrtcInterface.getPeople();
        this.spatialLayer = people <= 2 ? 0 : people <= 4 ? 1 : 2;
        if (!this.broadcastId) {
            if (this.audioTrack) this.audioProducer = await this.transport.transport.produce({ stopTracks: false, track: this.audioTrack, codecOptions: audioCodecOptions });
            if (this.videoTrack) {
                const encodings = this.screenshare ? screenEncodings : camEncodings;
                const producer = await this.transport.transport.produce({ stopTracks: false, track: this.videoTrack, encodings, codecOptions: videoCodecOptions });
                if (this.screenshare) this.screenshareProducer = producer;
                else this.videoProducer = producer;
                this.updateProducerLayer();
            }
        } else {
            await this.transport.answer({ broadcastId: this.broadcastId, rtpCapabilities: this.transport.rtpCapabilities });
        }
        this.setStreams();
    }

    async start() {
        this.transport = await MediasoupTransport.connect(this.mediaService, this.meetingId, this.broadcastServer, !!this.broadcastId, this);
        if (this.transport.transport) await this.transportReady();
    }

    async consumed({ id, producerId, kind, rtpParameters, appData }) {
        if (this.consumers[id]) return;
        this.consumers[id] = this.transport.transport.consume({ id, producerId, kind, rtpParameters, appData });
        const consumer = await this.consumers[id];
        if (kind === 'audio') {
            this.audioTrack = consumer.track;
        } else if (kind === 'video') {
            this.videoTrack = consumer.track;
        }
        this.updateConsumerLayer();
        await this.transport.answer({ resumeConsumer: id });
        this.setStreams();
    }

    async consumerClosed({ id }) {
        const consumer = await this.consumers[id];
        if (!consumer) return;

        delete this.consumers[id];
        if (this.audioTrack === consumer.track) this.audioTrack = null;
        if (this.videoTrack === consumer.track) this.videoTrack = null;
        consumer?.close();
        this.setStreams();
    }

    async consumerPaused({ id }) {
        const consumer = await this.consumers[id];
        consumer?.pause();
    }

    async consumerResumed({ id }) {
        const consumer = await this.consumers[id];
        consumer?.resume();
    }

    get connected() { return this.transport?.connected; }

    async updatePeople(people: number) {
        const spatialLayer = people <= 2 ? 0 : people <= 4 ? 1 : 2;
        if (this.spatialLayer === spatialLayer) return;
        this.spatialLayer = spatialLayer;

        this.updateProducerLayer();
        this.updateConsumerLayer();
    }

    private async updateProducerLayer() {
        try {
            this.videoProducer?.setMaxSpatialLayer(Math.max(0, camEncodings.length - 1 - this.spatialLayer));
            this.screenshareProducer?.setMaxSpatialLayer(Math.max(0, screenEncodings.length - 1 - this.spatialLayer));
        } catch (e) {
            console.error(e);
        }
    }

    private async updateConsumerLayer() {
        try {
            const videoConsumer = (await Promise.all(Object.values(this.consumers))).find(x => x.kind === 'video');
            if (!videoConsumer) return;
            const { spatialLayers, temporalLayers } = mediasoupClient.parseScalabilityMode(videoConsumer.rtpParameters.encodings[0].scalabilityMode || 'S1T1');
            await this.transport.answer({ broadcastId: this.broadcastId, spatialLayer: Math.max(spatialLayers - 1 - this.spatialLayer, 0), temporalLayer: temporalLayers - 1 });
        } catch (e) {
            console.error(e);
        }
    }

    async checkStats() {
        const sender = !this.broadcastId;
        const stats: Map<string, any> = new Map();
        (await this.transport.getStats()).forEach((value, key) => stats.set(key, value));
        let packets = 0;
        let nacks = 0;
        let audio = 0;
        let video = 0;
        const type = sender ? 'outbound-rtp' : 'inbound-rtp';
        let found = false;
        const consumers = await Promise.all(Object.values(this.consumers));
        // eslint-disable-next-line complexity
        stats.forEach(stat => {
            if (stat.type === type && (!this.broadcastId || consumers.some(x => x.rtpParameters.encodings.some(y => y.ssrc === stat.ssrc)))) {
                const previous = this.lastStats?.get(stat.id);
                if (previous) {
                    found = true;
                    packets += (stat[sender ? 'packetsSent' : 'packetsReceived'] ?? 0) - (previous[sender ? 'packetsSent' : 'packetsReceived'] ?? 0);
                    nacks += (stat[sender ? 'nackCount' : 'packetsLost'] ?? 0) - (previous[sender ? 'nackCount' : 'packetsLost'] ?? 0);
                    if (stat.kind === 'audio') audio += (stat[sender ? 'bytesSent' : 'bytesReceived'] ?? 0) - (previous[sender ? 'bytesSent' : 'bytesReceived'] ?? 0);
                    if (stat.kind === 'video') video += (stat[sender ? 'bytesSent' : 'bytesReceived'] ?? 0) - (previous[sender ? 'bytesSent' : 'bytesReceived'] ?? 0);
                }
            }
        });
        this.lastStats = stats;
        if (!found) return;

        const audioKbps = Math.round(audio * 8 / 10000);
        const videoKbps = Math.round(video * 8 / 10000);
        const loss = nacks / packets;
        // eslint-disable-next-line no-console
        console.log(`${sender ? 'Sent' : 'Received'} audio ${audioKbps} kbps video ${videoKbps} kbps packet loss ${loss} ${this.personId}`);
        if (videoKbps <= 24) this.poor$.next(true);
        else this.poor$.next(false);
    }
}
