import { Injectable } from '@angular/core';
import { myUserId } from 'crews/app/models-mobx/my-profile-store/my-profile-store';
import { isEqual } from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

export interface AvatarPopoverTrigger {
    containerBounds: { x: number, y: number, height: number };
    personId?: string;
}

export interface PopoverEvent {
    position: [number, number];
    data?: any;
}

@Injectable({
    providedIn: 'root',
})
export class PopoverService {
    currentUserId = myUserId();

    hoverEvent: BehaviorSubject<PopoverEvent> = new BehaviorSubject(null);
    closePopover: Subject<void> = new Subject();

    public getDebouncedHoverEventChanges() {
        return this.hoverEvent.pipe(debounceTime(500)).pipe(distinctUntilChanged((prev, curr) => isEqual(prev, curr)));
    }

    public setHoverEventChanges(trigger?: AvatarPopoverTrigger): void {
        let value: PopoverEvent = null;
        if (trigger) {
            const { containerBounds, personId } = trigger;
            if (this.currentUserId === personId) return;
            if (containerBounds) {
                const x = containerBounds.x + 30;
                const y = containerBounds.y + containerBounds.height + 8;
                value = { position: [x, y], data: personId };
            }
        }
        this.hoverEvent.next(value);
    }

    public setPopoverEventChanges(event?: PopoverEvent) {
        this.hoverEvent.next(event);
    }

    public forceClosePopover(): void {
        this.closePopover.next();
    }
}
