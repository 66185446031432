<div *ngIf="avatarProperties"
    class="avatar-container"
    (mouseenter)="handleAvatarMouseEnter(avatarContainer)"
    (mouseleave)="handleAvatarMouseLeave()"
    #avatarContainer
>

    <ng-content></ng-content>

    <ng-container *ngIf="(avatarProperties.img && !imageSrcError)">
        <div
            class="avatar-img bgCenter"
            [ngStyle]="{
                'height.px': avatarProperties.height,
                'width.px': avatarProperties.width || avatarProperties.height,
                'border-radius': getBorderRadius(),
                'background-image': 'url(\'' + avatarProperties.img + '\')',
                'background-repeat': 'no-repeat',
                'border': avatarProperties.outlineColor ? 'solid ' + borderWidth + 'px' : '0px',
                'border-color':  avatarProperties.outlineColor ? getOutlineColor() : null
            }"
            [alt]="avatarProperties.altText || translateService.getImmediate('userImage')"
        ></div>
        <img class="hide" [src]="avatarProperties.img" (error)="imageSrcError = true">
    </ng-container>
    

    <div
        *ngIf="avatarProperties.placeholder && (!avatarProperties.img || imageSrcError)"
        class="flexColumn aiC taC"
        [ngStyle]="{
            'height.px': avatarProperties.height,
            'width.px': avatarProperties.width || avatarProperties.height,
            'backgroundColor': getBackgroundColor(),
            'border-radius': getBorderRadius(),
            'border': avatarProperties.outlineColor ? 'solid ' + borderWidth + 'px' : '0px',
            'border-color':  avatarProperties.outlineColor ? getOutlineColor() : null
        }">
        <i 
            [ngClass]="avatarProperties.placeholder.icon"
            [ngStyle]="{
                'fontSize.px': getFontSize(),
                'color': getIconColor(),
                'margin': 'auto'
            }"
        ></i>
    </div>
    
    <div
        *ngIf="!avatarProperties.placeholder && (!avatarProperties.img || imageSrcError) && avatarProperties.name"
        class="avatar-icon"
        [ngStyle]="{
            'fontSize.px': getFontSize(),
            'height.px': getSize(),
            'width.px': getSize(),
            'display': 'flex',
            'alignItems': 'center',
            'justifyContent': 'center',
            'border-radius': getBorderRadius(),
            'border': avatarProperties.outlineColor ? 'solid ' + borderWidth + 'px' : '0px',
            'border-color':  avatarProperties.outlineColor ? getOutlineColor() : null
        }"
    >
        {{getInitials()}}
    </div>
    
    <svg
        *ngIf="!avatarProperties.placeholder && (!avatarProperties.img || imageSrcError) && !avatarProperties.name"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 496 512"
        class="avatar-icon"
        [ngStyle]="{
            'fontSize.px': avatarProperties.borderWidth,
            'height.px': getSize(),
            'width.px': getSize(),
            'display': 'flex',
            'alignItems': 'center',
            'justifyContent': 'center',
            'border-radius': getBorderRadius(),
            'border': avatarProperties.outlineColor ? 'solid ' + (borderWidth - 1) + 'px' : '0px',
            'border-color':  avatarProperties.outlineColor ? getOutlineColor() : null,
            'fill': teamsService.isTeamsApp() ? 'var(--avatar-background-color)' : null,
            'backgroundColor': teamsService.isTeamsApp() ? 'var(--avatar-font-color)' : null
        }"
    >
        <path d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z"/>
    </svg>

    <button class="primary circle-icon"
        [ngClass]="getCircular() ? 'circular-button-icon' : 'square-button-icon'"
        *ngIf="avatarProperties.editable && !readonly"
        type="button"
        (click)="showEditModal()"
    >
        <i class="fas fa-pen"></i>
    </button>
</div>


<app-modal *ngIf="editModal.isOpen" [modalInput]="editModal" (modalCloseOutput)="handleEditModalClose($event)">
    <app-avatar-editor (editOutput)="handleAvatarEditOutput($event)"></app-avatar-editor> 
</app-modal>
