import { PermissionAction } from '@weavix/permissions/src/permissions.model';
import { FilterFolder, FolderType } from '../folder/folder';

export interface TableOptions {
    title: string;
    translate?: boolean;
    rowCount?: boolean;
    back?: boolean | (() => void);
    keyCol: string;
    hideHeader?: boolean;
    columns: TableColumn[];
    rowEdits: RowEdit[];
    canAdd?: boolean;
    canImport?: boolean;
    addOptions?: Array<{
        display: string;
        id: string;
        onClick: () => void
    }>;
    search?: {
        show?: boolean;
        fields?: string[];
        customSearch?: boolean;
        query?: string;
        callback?: ((columnNames: string[], value: string) => any);
    };
    pagination?: {
        initPageSize?: number;
        showMoreSize?: number;
    };
    filters?: TableFilter[];
    tagFilters?: boolean;
    craftFilters?: boolean;
    select?: {
        selectable?: boolean;
        multiSelect?: boolean;
        showCheckboxes?: boolean;
        rowsClickable?: boolean; // if you want action on row click
        rowsDblClickable?: boolean;
        checkOnClick?: boolean;
        highlightOnClick?: boolean;
    };
    format?: (row) => any;
    bulk?: {
        url: (id: string) => string;
        cache: string;
    };
    bulkEdits?: BulkEdit[];
    tableEdits?: TableEdit[];
    presetSearchQuery?: string;
    folderType?: FolderType;
    viewPermissionAction?: PermissionAction;
    editPermissionAction?: PermissionAction;
    facilityId?: string;
    routerLink?: ((row) => string);
    folderId?: string;
    minHeight?: number;
    deleteMessageKey?: string;
    noData?: {
        isContentTemplate?: boolean;
        messageKey?: string;
    };
    hideTableIntro?: boolean;
    folderIsLink?: boolean;
    showStickyButtons?: boolean;
    rowsExpandContainer?: boolean;
    timeAgoColumnToggle?: boolean;
    csvExport?: boolean;
    disableRow?: (row) => boolean; // remove click action from specified rows
    locked?: (row) => boolean;
    onBack?: () => any;
    importKey?: string;
    duration?: number;
    settingsKey?: string;
    paginate?: {
        enabled: boolean;
        pageSize: number;
        isLazyLoaded?: boolean;
        loadMore?: ((limit: number, sortColumn?: TableColumn, direction?: PaginationDirection, reload?: boolean) => any);
    };
    hasSidebar?: boolean;
    isSubTable?: boolean;
    columnAlignmentOffset?: number; // if you need to offset the column headers for an icon in a table row
    hasDefaultSort?: boolean;
}

export interface TableRow {
    original: any;
    key: string;
    items: RowItem[];
    selected?: boolean;
    highlighted?: boolean;
    edits?: RowEdit[];
    isFolder?: boolean;
    link?: string;
    clickable?: boolean;
    checkbox?: boolean;
    locked?: boolean;
    style?: any;
    subTable: {
        show: boolean;
        options: TableOptions;
        rows: any[];
    };
    rowHeight?: boolean;
}

export interface RowItem {
    value: any;
    tooltip: any;
    sort: any;
    colKey: string;
    minWidth?: number;
    maxWidth?: number;
    class?: string;
    clickable?: boolean;
    pending?: boolean;
    prefix?: RowItemIcon;
    postfix?: RowItemIcon;
    overridesSelection?: boolean;
}
export interface SubRowItem extends RowItem {
    type: RowItemType; // sub row items have no column mapping, so we set their type (for meow)
}

export interface RowItemIcon {
    value: string;
    tooltip?: string;
    clickable?: boolean;
}

export type RowEditFilter = (row: any, edit: RowEdit) => boolean;

export interface RowEdit {
    title: string;
    action: RowEditAction;
    icon: string;
    disabled?: boolean | ((row: any) => boolean);
    show?: (row: any) => boolean;
}

export enum RowEditAction {
    view = 'view',
    edit = 'edit',
    copy = 'copy',
    delete = 'delete',
    download = 'download',
    register = 'register',
    print = 'print',
    propertyUpdate = 'propertyUpdate',
    enable = 'enable',
    disable = 'disable',
    fillout = 'fillout',
    generateReport = 'generateReport',
    checkoutEveryone = 'checkoutEveryone',
    redo = 'redo',
    cancel = 'cancel',
    channel = 'channel',
    goToMap = 'goToMap',
    move = 'move',
    logOut = 'logout',
    sms = 'sms',
    assign = 'assign',
    unassign = 'unassign',
    remove = 'remove',
    resend = 'resend',
    revoke = 'revoke'
}

export const commonRowEdits: { [key: string]: RowEdit} = {
    delete: {
        title: 'generics.delete',
        icon: 'fas fa-trash',
        action: RowEditAction.delete,
    },
    edit: {
        title: 'generics.edit',
        icon: 'fas fa-pencil-alt',
        action: RowEditAction.edit,
    },
    copy: {
        title: 'generics.copy',
        icon: 'fas fa-copy',
        action: RowEditAction.copy,
    }
};

export interface TableEdit {
    title: string;
    action: TableEditAction;
    icon: string;
    static?: boolean;
    disabled?: boolean | (() => boolean);
    show?: () => boolean;
}

export enum TableEditAction {
    copy = 'copy',
    download = 'download',
    import = 'import',
    share = 'share',
    view = 'view',
}

export interface BulkEdit {
    title: string;
    action: string;
    icon: string;
}

export enum BulkEditAction {
    delete = 'delete',
    tag = 'tag',
    enable = 'enable',
    disable = 'disable'
}

export interface TableFilter {
    title: string;
    selected: boolean;
    key?: any;
    filterFn(row: TableRow): boolean;
}

export enum RowItemType {
    checkbox = 'checkbox',
    color = 'color',
    date = 'date',
    icon = 'icon',
    number = 'number',
    text = 'text',
    timeAgo = 'timeAgo',
    time = 'time',
    toggle = 'toggle',
    person = 'person' // avatar - name/crafts - company logo
}

export interface TableColumn {
    title: string;
    translate?: boolean;
    colKey: string;
    dataColKey?: string;
    type: RowItemType | ((row: any) => RowItemType);
    sort?: TableColumnSort;
    minWidth?: number;
    maxWidth?: number;
    columnHeadTooltip?: string; // tooltip for column header hover
    overridesSelection?: boolean;
    clickable?(row: any): boolean;
    class?(row: any): string;
    value?(row: any): any;
    tooltip?(row: any): any; // tooltip for column value hover
    pending?(row: any): boolean;
    prefix?(row: any): RowItemIcon;
    postfix?(row: any): RowItemIcon;
}

export interface TableColumnSort {
    sortable?: boolean;
    selected?: boolean;
    sortAsc?: boolean;
    fn?(row: any): any;
}

export interface FolderChange {
    folder: FilterFolder;
}

export enum TableSetting {
    folderTree = 'folderTree'
}

export const commonBulkEdits: { [key: string]: BulkEdit } = {
    delete : {
        title: 'generics.delete',
        icon: 'fas fa-trash',
        action: BulkEditAction.delete
    }
};

export enum PaginationDirection {
    desc = 'descending',
    asc = 'ascending'
}

export interface Paginator {
    totalItems: number;
    currentPage: number;
    pageSize: number;
    totalPages: number;
    startIndex: number;
    endIndex: number;
    direction: PaginationDirection;
    changedDirection: boolean;
}
