import { Injectable } from '@angular/core';
import { FacilityAlertItem } from '@weavix/models/src/alert/facility-alerts';
import { AlertMaydayServiceStub } from '@weavix/services/src/alert-mayday.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class AlertMaydayService extends AlertMaydayServiceStub {
    private alerts$: BehaviorSubject<FacilityAlertItem[]>;

    constructor() {
        super();
        this.alerts$ = new BehaviorSubject([]);
    }

    public get alerts(): Observable<FacilityAlertItem[]> {
        return this.alerts$.asObservable();
    }

    public setAlerts(values: FacilityAlertItem[]) {
        this.alerts$.next(values);
    }

    public addTempAlert(alerts: FacilityAlertItem[], newAlert: FacilityAlertItem): void {
        const updatedAlerts: FacilityAlertItem[] = [...alerts, newAlert];
        this.setAlerts(updatedAlerts);
    }

    public removeTempAlert(): void {
        this.setAlerts(this.alerts$.value.filter(alert => alert.id !== 'temporary'));
    }
}
