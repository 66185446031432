export function isTeamsApp(): boolean {
    return document.location.href.includes('teams');
}

export function getTeamsAppId(domain: string): string {
    switch (domain) {
        case 'localhost': return '232107d4-cf39-45bf-8b81-882ae9b95490';
        case 'weavixdev.com': return '232107d4-cf39-45bf-8b81-882ae9b95491';
        case 'weavixstage.com': return '232107d4-cf39-45bf-8b81-882ae9b95492';
        case 'weavixdemo.com': return '232107d4-cf39-45bf-8b81-882ae9b95493';
        case 'weavix.com': return '232107d4-cf39-45bf-8b81-882ae9b95494';
        default: throw new Error(`Unknown domain: ${domain}`);
    }
}
