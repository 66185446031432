import { Injectable, OnDestroy } from '@angular/core';
import { UploadServiceStub } from '@weavix/services/src/upload.service';
import { ImageUpload, VideoUpload } from '../models/upload.model';
import { HttpService } from './http.service';


@Injectable()
export class UploadService extends UploadServiceStub {

    constructor(
        private httpService: HttpService,
    ) {
        super();
    }

    upload(component: OnDestroy, file: File) {
        const formData = new FormData();
        formData.append('file', file, file.name);
        return this.httpService.upload<ImageUpload>(component, '/core/uploads', formData);
    }

    uploadVideo(component: OnDestroy, file: File) {
        const formData = new FormData();
        formData.append('file', file, file.name);
        return this.httpService.upload<VideoUpload>(component, '/core/uploads/video', formData);
    }

    delete(component: OnDestroy, id: string) {
        return this.httpService.delete(component, `/core/uploads/${id}`);
    }
}
