<ng-container *ngIf="attachment" [ngSwitch]="attachment?.type">    
    <div 
        class="attachment-container"
        [ngClass]="{'disable': disableAttachment}"
        (click)="isGif ? onGifClick() : null"
    >
        <button id="remove-attachment-button"
            class="close-button circle-icon small cancel"
            *ngIf="deletable && !disableAttachment && !teamsApp"
            (click)="this.removeAttachment.emit(attachment)"
        >
            <app-icon class="cPointer" [faIcon]="'fas fa-times'"></app-icon>
        </button>

        <ng-container *ngSwitchCase="attachmentType.Image">
            <ng-container *ngIf="!isGif && (!teamsApp || !pending)">
                <img class="image-attachment"
                    [src]="attachment?.file?.uri ? attachment?.file?.uri : attachment?.thumbnailUri"
                    [alt]="attachment?.file?.name"
                />
            </ng-container>
            <ng-container *ngIf="isGif && (!teamsApp || !pending)">
                <img class="image-attachment gif"
                    #gif
                    *ngIf="attachment.thumbnailUri !== undefined"
                    [src]="attachment.thumbnailUri"
                />
                <i
                    class="fas fa-play-circle"
                    *ngIf="!gifTimeout && !attachment.loading"
                    [style]="{'position': 'absolute', 'font-size': '30px', 'color': 'white'}"
                ></i>
            </ng-container>
            <ng-container *ngIf="teamsApp && pending">
                <div class="teams-pending-attachment-div">
                    <img class="teams-pending-img"
                        [src]="attachment?.file?.uri ? attachment?.file?.uri : attachment?.thumbnailUri"
                        [alt]="attachment?.file?.name"
                    />
                    <text class='teams-pending-attachment-text'>{{attachment.file.name}}</text>
                    <div class="teams-remove-container"
                        (click)="this.removeAttachment.emit(attachment)"
                        (mouseenter)="closeIcon = 'fas fa-times'"
                        (mouseleave)="closeIcon = 'fal fa-times'"
                    >
                        <app-icon [faIcon]="closeIcon"></app-icon>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    
        <ng-container *ngSwitchCase="attachmentType.File">
            <ng-container *ngIf="!teamsApp">
                <div class="file-attachment">
                    <app-icon faIcon="fas fa-file"></app-icon>
                    <p class="file-name">{{ attachment?.file?.name }}</p>
                </div>
            </ng-container>
            <div *ngIf="teamsApp" class="teams-pending-attachment-div">
                <app-icon [faIcon]="'fas fa-file'"></app-icon>
                <text class='teams-pending-attachment-text'>{{attachment.file.name}}</text>
                <div class="teams-remove-container"
                    (click)="this.removeAttachment.emit(attachment)"
                    (mouseenter)="closeIcon = 'fas fa-times'"
                    (mouseleave)="closeIcon = 'fal fa-times'"
                >
                    <app-icon [faIcon]="closeIcon"></app-icon>
                </div>
            </div>
        </ng-container>

        <ng-container *ngSwitchCase="attachmentType.Video">
            <ng-container *ngIf="attachment?.thumbnailUri && (!teamsApp || !pending)">
                <img class="image-attachment"
                    [src]="attachment?.thumbnailUri"
                    [alt]="attachment?.file?.name"
                />
                <i 
                    class="fas fa-play-circle"
                    *ngIf="!attachment?.file?.play && !attachment.loading" 
                    [style]="{'position': 'absolute', 'font-size': '30px', 'color': 'white'}"
                ></i>
            </ng-container>
                
            <div *ngIf="!attachment?.thumbnailUri && (!teamsApp || !pending)" class="file-attachment">
                <i
                    class="fas fa-play-circle"
                    *ngIf="!attachment?.file?.play && !attachment.loading"
                    [style]="{'position': 'absolute', 'font-size': '30px', 'color': 'white'}"
                ></i>
                <p class="file-name">{{ attachment?.file?.name }}</p>
            </div>
            <div *ngIf="teamsApp && pending" class="teams-pending-attachment-div">
                <app-icon [faIcon]="'fas fa-play-circle'"></app-icon>
                <text class='teams-pending-attachment-text'>{{attachment.file.name}}</text>
                <div class="teams-remove-container"
                    (click)="this.removeAttachment.emit(attachment)"
                    (mouseenter)="closeIcon = 'fas fa-times'"
                    (mouseleave)="closeIcon = 'fal fa-times'"
                >
                    <app-icon [faIcon]="closeIcon"></app-icon>
                </div>
            </div>
        </ng-container>
        <div style="
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateY(-50%) translateX(-50%);
            "
        >
            <mat-progress-spinner
                *ngIf="attachment.loading"
                class="pending-spinner"
                diameter="30"
                mode="indeterminate"
            ></mat-progress-spinner>
        </div>
    </div>
</ng-container>
