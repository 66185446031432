import { Subject } from 'rxjs';


import { Utils } from '@weavix/components/src/utils/utils';
import { BadgeUpdate } from '@weavix/models/src/badges/badge-update';
import { MapPerson } from '@weavix/models/src/map/map';
import { StateServiceBase, StateUpdate } from './state-base.service';
import { PersonServiceStub } from '@weavix/services/src/person.service';
import { FacilityServiceStub } from '@weavix/services/src/facility.service';
import { ProfileServiceStub } from '@weavix/services/src/profile.service';
import { PermissionAction } from '@weavix/permissions/src/permissions.model';

export class AccountPersonStateService extends StateServiceBase {
    people: Map<string, MapPerson> = new Map();
    people$: Subject<StateUpdate<MapPerson>> = new Subject();
    badgeUpdate$: Subject<{ personId: string, badgeUpdate: BadgeUpdate }> = new Subject();

    constructor(
        private personService: PersonServiceStub,
        private facilityService: FacilityServiceStub,
        private profileService: ProfileServiceStub
    ) {
        super();
    }

    async loadData(c: any, accountId: string, facilityId?: string, tags?: string[]) {
        if (!this.profileService.hasPermissionInAnyFacility(PermissionAction.ViewWorkerLiveLook)) return;
        const newPeople = await this.personService.getPeople(c, tags);
        const newPeopleWithCopiedData = newPeople.map(newPerson => ({
            ...this.people.get(newPerson.id),
            ...newPerson,
        }));
        this.people = Utils.toMap(newPeopleWithCopiedData);
    }

    async stop() {
        super.stop();
        this.people.clear();
    }

    protected async setupSubscriptions(c: any, accountId: string, facilityId: string, tags?: string[]) {
        const subs = (this.profileService.hasPermissionInAnyFacility(PermissionAction.ViewWorkerLiveLook))
            ? [
                (await this.personService.subscribePersonUpdates(c)).subscribe(async (result) => {
                    const personId = result.topic[1];
                    if (!this.people?.has(personId)) return;
                    this.updateFromMap(this.people, { [personId]: result.payload }, this.people$);
                }),
                (await this.personService.subscribeBadgeUpdates(c)).subscribe(async (result) => {
                    const personId = result.topic[1];
                    if (!this.people?.has(personId)) return;
                    this.badgeUpdate$.next({ personId: personId, badgeUpdate: result.payload });
                }),
                (await this.facilityService.subscribeFacilityUpdates(c, accountId, null)).subscribe(() => {
                    this.loadData(c, accountId, null, tags);
                })
            ]
            : [];
        return subs;
    }
}
