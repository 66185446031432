import { Geofence } from '@weavix/models/src/map/map';
import { EventType } from '@weavix/models/src/badges/event';
import { ObservableSubscribe, TopicPayload } from '@weavix/mqtt';


export abstract class GeofenceServiceStub {
    abstract getAll(component: any, facilityId?: string, tags?: string[]): Promise<Geofence[]>;
    abstract getGeofenceEventString(eventType: EventType.GeofenceEnter | EventType.GeofenceExit | EventType.GeofenceDisconnect, name: string): string;
    abstract subscribeGeofenceUpdates(c: any): ObservableSubscribe<TopicPayload<any>>;
}
