import { createContext, model, Model, modelAction, prop } from '@weavix/mobx';
import { UserAccount } from '@weavix/sync/src/sync.service';

export const myProfileContext = createContext<MyProfileStore>();
export const myUserId = () => myProfileContext.getDefault().userId;
export const myCompanyId = () => myProfileContext.getDefault().companyId;

@model('MyProfileStore')
export class MyProfileStore extends Model({
    channelId: prop<string>(),
}) {
    user: UserAccount;

    get userId() {
        return this.user?.id;
    }

    get accounts() {
        return this.user?.accounts ?? [];
    }

    get companyId() {
        return this.user?.company?.id;
    }

    constructor() {
        super();
        myProfileContext.setDefault(this);
    }

    @modelAction
    clearUser() {
        this.user = undefined;
    }

    setUser(user: UserAccount) {
        this.user = user;
    }

    @modelAction
    setChannel(channelId: string) {
        this.channelId = channelId;
    }
}
