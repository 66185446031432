import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, ReplaySubject } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { Facility } from '@weavix/models/src/facility/facility';
import { FacilityServiceStub } from '@weavix/services/src/facility.service';
import { TranslationServiceStub } from '@weavix/services/src/translation.service';
import { AddFacilityPersonRequest, Simulator, UpdateSettingsRequest } from '../models/facility.model';
import { Topic } from '@weavix/models/src/topic/topic';
import { GeofenceService } from './geofence.service';
import { CacheContext, HttpService } from './http.service';
import { ItemService } from './item.service';
import { PubSubService } from './pub-sub.service';

@Injectable()
export class FacilityService extends FacilityServiceStub {
    constructor(
        private httpService: HttpService,
        private pubSubService: PubSubService,
        private translationService: TranslationServiceStub,
        private router: Router,
    ) {
        super();
        this.currentFacility$.subscribe(f => {
            this.facilityId = f?.id;
        });
    }

    static baseUrl = '/track/facilities';
    static facilityPeopleUrl = '/core/facility-people';
    static cacheCollection = 'facilities';

    facilityId: string;
    public currentFacility$: ReplaySubject<Facility> = new ReplaySubject(1);
    private readonly cacheContext: CacheContext = { collection: FacilityService.cacheCollection, maxAge: 1800000 };

    static url = (id?: string) => id ? `${FacilityService.baseUrl}/${id}` : FacilityService.baseUrl;

    async getCurrentFacility() {
        return await this.currentFacility$.pipe(take(1)).toPromise();
    }

    public getCurrentFacility$(): Observable<Facility> {
        return this.currentFacility$.asObservable();
    }

    public getCurrentFacilityId$(): Observable<string> {
        return this.getCurrentFacility$().pipe(map((facility) => facility.id));
    }

    public getLastFacilityIdFromStorage(): string | null {
        return localStorage.getItem('navigation-facility');
    }

    async getCurrentFacilityId() {
        return (await this.getCurrentFacility())?.id;
    }

    setCurrentFacility(facility: Facility) {
        localStorage.setItem('navigation-facility', facility.id);
        this.currentFacility$.next(facility);
    }

    /**
     * Make sure current facility is set and redirect to /facilities if not
     */
    async validateCurrentFacility(): Promise<boolean> {
        const fac = await this.getCurrentFacility();
        if (!fac || !fac.id) {
            this.router.navigateByUrl('/settings/facilities');
            return false;
        }
        return true;
    }

    async validateRouteFacility(component: any, id: string) {
        try {
            const facility = await this.httpService.get<Facility>(component, FacilityService.url(id), null, this.cacheContext);
            if (facility) {
                this.setCurrentFacility(facility);
                return Promise.resolve(true);
            }
            return Promise.resolve(false);
        } catch (e) {
            return Promise.resolve(false);
        }
    }

    getMyFacilities(component: any, cache: boolean = true) {
        return this.httpService.get<Facility[]>(component, `${FacilityService.url()}/mine`, {}, cache ? this.cacheContext : null);
    }

    getMeFacilities(component: any, cache: boolean = true) {
        return this.httpService.get<Facility[]>(component, '/core/me/facilities', {}, cache ? this.cacheContext : null);
    }

    getAll(component: any, tags?: string[], clearCache: boolean = false) {
        if (clearCache) HttpService.clearCache(this.cacheContext.collection);
        return this.httpService.get<Facility[]>(component, `${FacilityService.url()}`, { tags }, this.cacheContext);
    }

    getSimulator(component: any, facilityId: string) {
        return this.httpService.get<Simulator>(component, `/simulator/${facilityId}`);
    }

    updateSimulator(component: any, facilityId: string, simulator: Simulator) {
        return this.httpService.post<Simulator>(component, `/simulator/${facilityId}`, simulator);
    }

    get(component: any, id: string, cache: boolean = false) {
        return this.httpService.get<Facility>(component, FacilityService.url(id), null, cache ? this.cacheContext : null);
    }

    async getWithAccountId(component: any, id: string, accountId: string) {
        return this.httpService.get<Facility>(component, FacilityService.url(id), null, null, null, accountId);
    }

    add(component: any, facility: Facility) {
        return this.httpService.post<Facility>(component, FacilityService.url(), facility, this.cacheContext);
    }

    addPerson(component: any, request: AddFacilityPersonRequest) {
        HttpService.clearCache(this.cacheContext.collection);
        return this.httpService.post<Facility>(component, `${FacilityService.facilityPeopleUrl}/person`, request);
    }

    removePerson(component: any, facilityId: string, personId: string) {
        HttpService.clearCache(this.cacheContext.collection);
        return this.httpService.delete<Facility>(component, `${FacilityService.facilityPeopleUrl}/${facilityId}/person/${personId}`);
    }

    update(component: any, id: string, facility: Facility) {
        HttpService.clearCache(this.cacheContext.collection);
        return this.httpService.put<Facility>(component, FacilityService.url(id), facility, this.cacheContext);
    }

    updateSettings(component: any, id: string, settings: UpdateSettingsRequest) {
        HttpService.clearCache(this.cacheContext.collection);
        return this.httpService.put<UpdateSettingsRequest>(component, `${FacilityService.url(id)}/settings`, { settings });
    }

    delete(component: any, id: string) {
        GeofenceService.clearCache();
        ItemService.clearItemCache();
        return this.httpService.delete<void>(component, FacilityService.url(id), null, this.cacheContext);
    }

    subscribeFacilityUpdates(c: any, accountId: string, facilityId: string) {
        return this.pubSubService.subscribe<any>(c, Topic.AccountMapFacilities, [accountId, facilityId || '+']);
    }

    getBadges(component: any, facilityId: string) {
        return this.httpService.get<any[]>(component, `${FacilityService.facilityPeopleUrl}/${facilityId}/badges`);
    }

    async getWeather(component: any, id: string, language: string = this.translationService.getLocale()): Promise<{ icon: string, temperature: string }> {
        return await this.httpService.get<{ icon: string, temperature: string }>(component, `${FacilityService.url(id)}/weather`, { language });
    }
}
