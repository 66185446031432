import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { CompanyServiceStub } from '@weavix/services/src/company.service';
import { Company } from '../models/company.model';
import { SelectionOptions } from '../models/folder.model';
import { Resource } from '../models/folderized-data.model';
import { Topic } from '@weavix/models/src/topic/topic';
import { Utils } from '../utils/utils';
import { AccountService } from './account.service';
import { AlertService } from './alert.service';
import { CacheContext, HttpService } from './http.service';
import { PubSubService } from './pub-sub.service';

@Injectable()
export class CompanyService extends CompanyServiceStub {

    constructor(
        private httpService: HttpService,
        private pubSubService: PubSubService,
        private accountService: AccountService,
        private alertsService: AlertService,
    ) {
        super();
    }

    static baseUrl = '/core/companies';
    static cacheCollection = 'companies';
    private readonly cacheContext: CacheContext = { collection: CompanyService.cacheCollection, maxAge: 1800000 };
    private colors$: BehaviorSubject<string[]>;

    private cache: {[id: string]: Company};
    private cacheCreated;
    private updateSubscription: Subscription;

    static url = (id?: string) => id ? `${CompanyService.baseUrl}/${id}` : CompanyService.baseUrl;

    async getAll(component: any, tags?: string[], cache: boolean = false) {
        if (cache) {
            await this.checkCache(component);
            return tags ? Object.values(this.cache).filter(p => tags.some(t => p.tags && !!p.tags.find(t2 => t2 === t)))
                : Object.values(this.cache);
        }

        return await this.httpService.get<Company[]>(component, CompanyService.url(), { tags }, this.cacheContext);
    }

    async getCompanyResources(component, selectionOptions?: SelectionOptions): Promise<Resource[]> {
        try {
            let resources: Resource[] = [];
            resources = (await this.getAll(component)).map(c => this.makeResource(c as Company, selectionOptions ? selectionOptions : { ids: true }));
            return Utils.sortAlphabetical(resources, (item) => item.displayText);
        } catch (e) {
            this.alertsService.sendError(e, 'ERRORS.COMPANIES.GET');
            return [];
        }
    }

    makeResource(c: Company, selectionOptions: SelectionOptions): Resource {
        return {
            displayText: c.name,
            key: c.id,
            data: c,
            parentId: c.folderId,
            isSelectable: selectionOptions.ids ? true : false,
        };
    }

    async get(component: any, id: string, cache: boolean = false, skipNoCache: boolean = false) {
        if (cache) {
            await this.checkCache(component);
            if (this.cache[id]) return this.cache[id];
        }
        if (skipNoCache) return null;
        return await this.httpService.get<Company>(component, CompanyService.url(id), null, this.cacheContext);
    }

    save(component: any, company: Company) {
        return company.id
            ? this.update(component, company.id, company)
            : this.add(component, company);
    }

    private add(component: any, company: Company) {
        return this.httpService.post<Company>(component, CompanyService.url(), company, this.cacheContext);
    }

    private update(component: any, id: string, company: Company) {
        return this.httpService.put<Company>(component, CompanyService.url(id), company, this.cacheContext);
    }

    delete(component: any, id: string) {
        return this.httpService.delete<void>(component, CompanyService.url(id), null, this.cacheContext);
    }

    async colors(component: any) {
        if (!this.colors$) {
            this.colors$ = new BehaviorSubject(await this.httpService.get<string[]>(component, `${CompanyService.url()}/colors`, null, this.cacheContext, null));
        }
        return await this.colors$.pipe(take(1)).toPromise();
    }

    subscribeCompanyUpdates(c: any, id: string = '+') {
        return this.pubSubService.subscribe<Company>(c, Topic.AccountCompanyUpdated, [this.accountService.getAccountId(), id]);
    }

    async checkCache(component) {
        if (!this.updateSubscription) {
            this.updateSubscription = this.accountService.account$.subscribe(async account => {
                const sub = this.pubSubService.subscribe<Company>(null, Topic.AccountCompanyUpdated, [account.id, '+']);
                sub.subscribe(payload => {
                    if (Object.keys(payload.payload).length) {
                        this.cache[payload.replacements[1]] = payload.payload;
                    } else {
                        delete this.cache[payload.replacements[1]];
                    }
                });
            });
        }
        if (!this.cacheCreated || this.cacheCreated.getTime() < new Date().getTime() - 1800000) {
            try {
                const companies = await this.httpService.get<Company[]>(component, CompanyService.url());
                this.cache = companies.reduce((arr, p) => (arr[p.id] = p, arr), {} as any);
                this.cacheCreated = new Date();
            } catch (e) {
                this.alertsService.sendError(e, 'ERRORS.COMPANIES.GET');
                throw e;
            }
        }
    }
}
