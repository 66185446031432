import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import * as _ from 'lodash';

import { AutoUnsubscribe } from '@weavix/components/src/utils/utils';
import { Point } from '@weavix/models/src/map/location';
import { MapView, RichMarker as RichMarkerInterface, RichMarkerOptions } from '@weavix/models/src/map/map';
import { Person } from '@weavix/models/src/person/person';
import { ResultType } from '@weavix/models/src/search/search';

import { MapServiceStub } from '../../services/map.service';
import { MapControl } from '../map-control/map-control.component';


declare const RichMarker: new (opts: RichMarkerOptions) => RichMarkerInterface;

export const MAX_ZOOM_LEVEL = 20;

export enum MapLayerToggles {
    Geofences = 'geofences',
    HeatMap = 'heat-map',
    Individuals = 'individuals',
    Items = 'items',
}
export interface MapFeatures {
    buttons?: {
        zoom: boolean;
        hideShowLabels: boolean;
    };
    actions?: {
        panning: boolean;
        mapViewUpdate: boolean;
        clustering: boolean;
    };
    mapFilter?: {
        mapLayerToggleState?: {[key in MapLayerToggles]?: boolean};
    };
    mapControls?: {[key: string]: MapControl | MapControl[]};
}

export interface MapOptions {
    mapTypeId?: google.maps.MapTypeId;
    streetViewControl?: boolean;
    tilt?: number;
    fullscreenControl?: boolean;
    disableDefaultUI?: boolean;
    gestureHandling?: string;
    backgroundColor?: string;
    hasPoi?: boolean;
    hasTransit?: boolean;
    hasRoad?: boolean;
}

@AutoUnsubscribe()
@Component({
    selector: 'app-simple-map',
    templateUrl: './simple-map.component.html',
    styleUrls: ['./simple-map.component.scss']
})
export class SimpleMapComponent implements AfterViewInit, OnInit, OnChanges {
    @Input() person: Person;
    @Input() location: number[];
    @Input() height: string = '100%';
    @Input() width: string = '100%';
    @Input() icon?: { value: string, color: string };

    @Input() mapView: MapView;
    @Input() mapOptions: MapOptions;
    @Input() features: MapFeatures;

    @Output() mapClickPosition: EventEmitter<Point> = new EventEmitter();
    @Output() mapViewChange: EventEmitter<MapView> = new EventEmitter();
    @Output() mapInitiated: EventEmitter<google.maps.Map> = new EventEmitter();

    @ViewChild('map', { static: true }) mapEl: ElementRef;

    constructor(
        public mapService: MapServiceStub
    ) {
        this.setupInitialMapSettings();
    }

    private gMap: google.maps.Map;
    private options: google.maps.MapOptions;
    private mapDrawn: boolean;
    private infoWindow: google.maps.InfoWindow = new google.maps.InfoWindow();
    private marker: google.maps.Marker;

    private defaultMapView: MapView = {
        lat: 36.45323439943358,
        lng: -95.18690655612475,
        bounds: null,
        zoom: 16
    };

    private drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: null,
        drawingControl: false,
        polygonOptions: { strokeColor: 'red' },
        circleOptions: { strokeColor: 'red' }
    });

    get mapLoaded(): boolean { return this.mapDrawn; }
    get mapLat(): number { return this.person?.badge?.location?.[1] ?? this.location?.[1] ?? this.mapView?.lat ?? this.defaultMapView.lat; }
    get mapLng(): number { return this.person?.badge?.location?.[0] ?? this.location?.[0] ?? this.mapView?.lng ?? this.defaultMapView.lng; }

    async ngOnInit(): Promise<void> {
        this.mapService.setupDashboardDisplay(this);
    }

    async ngAfterViewInit(): Promise<void> {
        await this.configureMapView();
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        await this.configureMapView();
    }

    private mapConfig(): google.maps.MapOptions {
        return {
            center: new google.maps.LatLng(this.mapLat, this.mapLng),
            zoom: this.mapView.zoom,
            mapTypeId: this.mapOptions?.mapTypeId ?? google.maps.MapTypeId.HYBRID,
            streetViewControl: this.mapOptions?.streetViewControl ?? false,
            tilt: this.mapOptions?.tilt ?? 0,
            fullscreenControl: this.mapOptions?.fullscreenControl ?? false,
            disableDefaultUI: this.mapOptions?.disableDefaultUI ?? true,
            gestureHandling: (this.mapOptions?.gestureHandling ?? (this.features?.actions?.panning ? 'greedy' : 'none')) as google.maps.GestureHandlingOptions,
            backgroundColor: this.mapOptions?.backgroundColor ?? 'transparent',
            styles: [
                {
                    stylers: [ { saturation: 0 }]
                },
                {
                    featureType: 'poi',
                    stylers: [{ visibility: this.mapOptions?.hasPoi ? 'on' : 'off' }, { saturation: 0 }]
                },
                {
                    featureType: 'transit',
                    stylers: [{ visibility: this.mapOptions?.hasTransit ? 'on' : 'off' }, { saturation: 0 }]
                },
                {
                    featureType: 'road',
                    stylers: [{ visibility: this.mapOptions?.hasRoad ? 'on' : 'off' }, { saturation: 0 }]
                }
            ]
        };
    }

    private setupInitialMapSettings(): void {
        this.features = this.features || {
            buttons: {
                zoom: false,
                hideShowLabels: false
            },
            actions: {
                panning: true,
                mapViewUpdate: false,
                clustering: true
            },
            mapFilter: {
                mapLayerToggleState: {
                    [MapLayerToggles.Geofences]: false,
                    [MapLayerToggles.Items]: false
                }
            },
            mapControls: {
                recenter: {
                    id: 'recenter',
                    onClick: () => this.handleRecenter(),
                    tooltip: 'shared.map.controls.recenter',
                    icon: 'fas fa-compress-arrows-alt',
                    size: 20
                },
                zoomIn: {
                    id: 'zoom-in',
                    onClick: () => this.handleZoomIn(),
                    tooltip: 'shared.map.controls.zoom-in',
                    icon: 'fas fa-plus',
                    size: 20
                },
                zoomOut: {
                    id: 'zoom-out',
                    onClick: () => this.handleZoomOut(),
                    tooltip: 'shared.map.controls.zoom-out',
                    icon: 'fas fa-minus',
                    size: 20
                }
            }
        };
        this.mapView = this.mapView || this.defaultMapView;
    }

    private getMapMarker(faIcon: string, color: string) {
        return `
        <div class="map-marker">
            <div class="map-marker-icon-container fa-stack">
                <i class="icon-map-marker fa-stack-2x fa-inverse border"></i>
                <i class="icon-map-marker fa-stack-2x background" style="color: ${color}"></i>
                <i class="fas fa-${faIcon} fa-stack-1x fa-inverse icon" style="line-height: inherit"></i>
            </div>
        </div>`;
    }

    private getPersonMarker(person: Person, selected = false) {
        const company = _.get(person, 'company', null);
        const color = _.get(company, 'color', '#ffffff');
        const avatar = person?.avatarFile;
        return `
        <div class="map-marker ${selected ? 'selected' : 'not-selected'}" data-id="${person.id}">
            <div class="map-marker-icon-container fa-stack">
                <i class="icon-map-marker fa-stack-2x fa-inverse border"></i>
                <i class="icon-map-marker fa-stack-2x background" style="color: ${color}"></i>
                ${avatar ? `<img class="map-avatar" src="${avatar}"></img>` : this.getInitialsHtml(person.firstName?.charAt(0), person.lastName?.charAt(0))}
            </div>
        </div>`;
    }

    private getInitialsHtml(first: string, last: string) {
        return `
            <div class="map-avatar-initials">
                <span class="text">
                ${first ? first.toUpperCase() : ''}${last ? last.toUpperCase() : ''}
                </span>
            </div>
        `;
    }

    private async configureMapView() {
        this.options = this.mapConfig();
        this.gMap = new google.maps.Map(this.mapEl.nativeElement, this.options);
        this.gMap.setCenter(this.options.center);
        this.gMap.setZoom(this.options.zoom);

        this.drawingManager.setMap(this.gMap);

        this.gMap.addListener('click', (mapsMouseEvent) => {
            this.mapClickPosition.emit([mapsMouseEvent.latLng.lng(), mapsMouseEvent.latLng.lat()]);
        });

        google.maps.event.addListener(this.gMap, 'zoom_changed', () => {
            this.infoWindow.close();
        });

        google.maps.event.addListener(this.gMap, 'idle', async () => {
            if (!this.mapDrawn) {
                this.mapDrawn = true;
                this.mapInitiated.emit(this.gMap);
            }
            this.triggerMapViewChange();
        });

        if (this.location || this.person) this.setupSingleLocation();
    }

    private setupSingleLocation(): void {
        this.marker = new RichMarker({
            id: 'value',
            position: new google.maps.LatLng(this.mapLat, this.mapLng),
            content: this.person ? this.getPersonMarker(this.person) : this.getMapMarker(this.icon.value, this.icon.color),
            removeIfClustered: false,
            type: this.person ? ResultType.Person : ResultType.Alerts
        });
        this.marker.setMap(this.gMap);
    }

    private triggerMapViewChange() {
        this.mapView = this.getCurrentGMapView();
        this.mapViewChange.emit(this.mapView);
    }

    private getCurrentGMapView(): MapView {
        const center = this.gMap.getCenter();
        if (!center) return this.mapView;
        return {
            lat: center.lat(),
            lng: center.lng(),
            bounds: this.gMap.getBounds(),
            zoom: this.gMap.getZoom()
        };
    }

    async resetMap() {
        this.mapDrawn = false;
    }

    public handleZoomIn() {
        this.mapView.zoom = this.gMap.getZoom() + 1;
        this.gMap.setZoom(this.mapView.zoom);
        this.triggerMapViewChange();
    }

    public handleZoomOut() {
        this.mapView.zoom = this.gMap.getZoom() - 1;
        this.gMap.setZoom(this.mapView.zoom);
        this.triggerMapViewChange();
    }

    public handleRecenter() {
        this.gMap.setCenter(this.options.center);
        this.gMap.setZoom(this.options.zoom);
        this.triggerMapViewChange();
    }

}
