import { sleep } from './sleep';

/**
 * Retries a function until it succeeds or until cancelled.
 *
 * @export
 * @template T The return type of the function that will be retried
 * @param {() => T} fn The function to retry
 * @param {() => boolean} cancelled A function that returns whether or not we should cancel
 * @return {*}  {Promise<T>}
 */
export function retryCancel<T>(fn: () => Promise<T>, cancelled: () => boolean | Promise<boolean>): Promise<T> {
    let timeout = 1000;
    return new Promise(async resolve => {
        while (!await cancelled()) {
            try {
                const result = await fn();
                if (await cancelled()) return;
                return resolve(result);
            } catch (e) {
                console.warn(e.message);
                await sleep(timeout);
                timeout = Math.min(15000, timeout * 2);
            }
        }
    });
}
