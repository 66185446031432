import { BehaviorSubject, Subscription } from 'rxjs';
import { retryCancel } from '@weavix/utils/src/retry-cancel';
import { SyncHttp, SyncMqtt, SyncStorage } from './sync.model';
import { SyncModule } from './sync.module';
import { PriorityQueue } from './priority-queue';
import { PermissionAction } from '@weavix/permissions/src/permissions.model';

type AccountEvent = { id: string; removed?: boolean };
export interface UserAccount {
    id: string;
    accounts: Array<AccountInfo>;
    company: { id: string };
    bleIds?: number[];
    phone?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    locale?: string;
    enabledActions?:PermissionAction[];
}

export interface AccountInfo {
    id: string; 
    companyId?: string;
    companyIds?: string[];
    enabledActions?: string[];
    allCompanies?: boolean;
    image: string; 
    name: string; 
    features: Array<{ key: string; value: number; }>; 
    master?: boolean; 
    color?: string
}

export type SyncDestroyer = () => void;
export type SyncTrigger = (user: UserAccount, cache?: boolean) => SyncDestroyer | Promise<SyncDestroyer>;

export class SyncService {
    static triggers: SyncTrigger[] = [];
    static destroyers: (SyncDestroyer | Promise<SyncDestroyer>)[] = [];
    static instance: SyncService;
    static version = 'v2'; // update this to force re-sync on client update

    // Triggers are triggered when the user is set or changed, destroyed on logout or user is changed
    static addTrigger(trigger: SyncTrigger) {
        this.triggers.push(trigger);
        const user = SyncService.instance?.user$?.value;
        if (user) SyncService.destroyers.push(trigger(user, true));
    }

    private userId?: string;
    private priorityQueue = new PriorityQueue();

    priorityLoading$ = new BehaviorSubject(0);
    lazyLoading$ = new BehaviorSubject(0);

    user$ = new BehaviorSubject<UserAccount | null>(null);
    private accountSubscription: Subscription | undefined;

    constructor(private http: SyncHttp, private mqtt: SyncMqtt, private storage: SyncStorage<any>) {}

    async loggedIn() {
        const properties = await this.storage.get('0');
        this.userId = properties?.version === SyncService.version ? properties.userId : '-1';
        const user = this.userId !== '-1' ? await this.storage.get(this.userId) : null;
        if (user) await this.trigger(user, true);
        const wait = this.subscribeUserAccounts();
        if (!user) {
            await wait;
            return false;
        }
        return true;
    }

    private async subscribeUserAccounts() {
        const refreshAccount = async () => {
            const user = await this.get<any>('/core/me/user');
            this.userId = user.id;
            await this.storage.add(user);
            await this.storage.add({ id: '0', userId: user.id, version: SyncService.version })
            await this.trigger(user);
        };
        const refreshSubscription = async () => {
            const sub = this.subscribe<AccountEvent>(`user/${this.userId}/account-added`);
            this.accountSubscription = (await sub).subscribe(refreshAccount, () => this.subscribeUserAccounts());
        };
        const userPromise = refreshAccount();
        if (this.userId === '-1') await userPromise;
        refreshSubscription();
        await userPromise;
    }

    async waitForModules() {
        await new Promise<void>(resolve => {
            this.priorityLoading$.subscribe(val => {
                if (val <= 0) resolve();
            });
        });
        await new Promise<void>(resolve => {
            this.lazyLoading$.subscribe(val => {
                if (val <= 0) resolve();
            });
        });
    }

    async loggedOut(reset = false) {
        this.accountSubscription?.unsubscribe();
        if (reset) {
            const userId = (await this.storage.get('0'))?.userId;
            if (userId) await this.storage.remove(userId);
            await this.storage.remove('0');
        }
        this.userId = undefined;
        await this.trigger(null);

        await Promise.all(SyncModule.modules.map(async module => {
            await module.close(reset);
        }));
    }

    async trigger(user: UserAccount, cache = false) {
        try { (await Promise.all(SyncService.destroyers)).forEach(x => x()); } catch (e) { console.error(e); }
        SyncService.destroyers = user ? SyncService.triggers.map(x => x(user, cache)) : [];
        this.user$.next(user);
    }

    subscribe<T>(topic: string, cancelled: () => boolean = () => !this.userId, lazy = false) {
        const fn = () => retryCancel(async () => {
            const result = this.mqtt.subscribe<T>(topic);
            await result.subscribed;
            return result;
        }, cancelled);
        return lazy ? this.priorityQueue.queue(fn) : this.priorityQueue.run(fn);
    }

    async get<T>(path: string, params?: any, cancelled: () => boolean = () => !this.userId, lazy = false) {
        console.log(`GET ${path} ${this.user$.value?.id}`);
        const fn = () => retryCancel(() => this.http.get<T>(path, params), cancelled);
        return lazy ? this.priorityQueue.queue(fn) : this.priorityQueue.run(fn);
    }

    async post<t>(path: string, body?: any, params?: any, cancelled: () => boolean = () => !this.userId, lazy = false) {
        console.log(`POST ${path} ${this.user$.value?.id}`);
        const fn = () => retryCancel(() => this.http.post<t>(path, body, params), cancelled);
        return lazy ? this.priorityQueue.queue(fn) : this.priorityQueue.run(fn);
    }
}
