import { CardAction, CardDescription, CardStatusIcon } from '../card/card';
import { AppIcon, IconType } from '../icon/icon';

export interface ResultGroup {
    name: string;
    type: Searchable;
    svgIcon?: string;
    faIcon?: string;
    matIcon?: string;
    img?: string;
    results?: SearchResult<any>[];
}

export enum Searchable {
    All = 'all',
    People = 'people',
    Items = 'items',
    ItemTypes = 'itemTypes',
    Geofences = 'geofences',
    Forms = 'forms',
    Facilities = 'facilities',
    Rules = 'rules',
    Channels = 'channels',
    PermissionGroups = 'permissionGroups',
    Companies = 'companies',
    Crafts = 'crafts'
}

export enum ResultType {
    Person = 'person',
    Items = 'items',
    Alerts = 'alerts',
    ItemTypes = 'itemTypes',
    Geofence = 'geofence',
    MapView = 'mapView',
    Company = 'company',
    Craft = 'craft',
    Form = 'form',
    Rule = 'rule',
    Channel = 'channel',
    Tag = 'tag',
    Badge = 'badge',
    PermissionGroup = 'permissionGroup',
    Walt = 'walt',
    Wilma = 'wilma'
}

export enum GenericResultType {
    people = 'people',
    geofence = 'geofence',
    geofences = 'geofences',
    item = 'item',
    items = 'items'
}

export enum SearchAction {
    View = 'view',
    ViewOnMap = 'viewOnMap',
    SecondaryView = 'secondaryView'
}

export interface SearchRequest {
    text?: string;
    targets?: SearchTarget[];
    facilityId?: string;
    map?: boolean;
    date?: Date;
}

export interface BackendSearchResult {
    type: SearchTarget;
    data: any;
}
export interface ResultAction {
    type: SearchAction;
    displayText: string;
    urlNav: string;
}

export type SearchResult<T> = T & {
    displayText: string;
    actions: CardAction[];
    icon: AppIcon;
    status?: CardStatusIcon;
    descriptions?: string[];
    type?: ResultType;
};

export interface BackendSearchResult {
    type: SearchTarget;
    data: any;
}

export interface ResultIcon {
    type: IconType;
    value: string;
    color?: string;
    width?: string;
    height?: string;
}

export interface SearchMapping {
    preResults: ResultMapping[];
    group: ResultGroup;
}

export enum SearchTarget {
    Channels = 'channels',
    ChannelMessages = 'channelMessages',
    Companies = 'companies',
    Crafts = 'crafts',
    Items = 'items',
    ItemTypes = 'itemTypes',
    People = 'people',
    Facilities = 'facilities',
    Geofences = 'geofences',
    WorkForms = 'workForms',
    FormSubmissions = 'formSubmissions',
    Groups = 'groups',
    Rules = 'rules',
    Keys = 'keys',
    Badges = 'badges',
    Tags = 'tags'
}

export interface ResultMapping {
    target: SearchTarget;
    group?: (result: any) => ResultGroup;
    displayText: (result: any) => string;
    descriptions?: (result: any) => CardDescription[];
    actions: (result: any) => CardAction[];
    icon: (result: any) => AppIcon;
    status?: (result: any) => CardStatusIcon;
    type?: ResultType;
}

export enum QueryParams {
    TableSearch = 'search'
}
