interface FeatureBackendResult {
    apiUrl: string,
    mqttUrl: string,
};

export async function getFeatureBackend(version: string, fetch: (url: string) => Promise<any>): Promise<FeatureBackendResult | null> {
    // only on a feature branch pointed at dev
    if (version.match(/^[0-9]/) || version.match(/^(demo|stage|BUILD)/)) {
        return null;
    }

    const feature = version.match(/[a-z0-9\-]+/i)[0];
    const featureApiUrl = `https://api-${feature}.weavixdev.com`;
    const featureMqttUrl = `mqtt-${feature}.weavixdev.com`;

    let hasFeatureBackend = false;
    try {
        const featureVersion = await fetch(`${featureApiUrl}/info`);
        hasFeatureBackend = featureVersion.version.startsWith(feature);
    } catch {
        console.warn(`${feature} has no feature backend`);
    }

    return {
        apiUrl: hasFeatureBackend ? featureApiUrl : 'https://api.weavixdev.com',
        mqttUrl: hasFeatureBackend ? featureMqttUrl : 'mqtt.weavixdev.com'
    };
}