
import * as mediasoupClient from 'mediasoup-client';
import { v4 as uuid } from 'uuid';
import { MediaEndedReason, MediaStreamData } from 'weavix-shared/models/media.model';
import { sleep } from 'weavix-shared/utils/sleep';
import { MediaService } from './media.service';
import { getRTCConfiguration } from './webrtc-connection';
import { WEBRTC_CONNECTIONS } from './webrtc-interface';

export interface MediasoupHandler {
    broadcastId?: string;
    transportReady();
    consumed(obj: any);
    consumerClosed(obj: any);
    consumerPaused(obj: any);
    consumerResumed(obj: any);
}

export class MediasoupTransport {
    private static transports: {[key: string]: Promise<MediasoupTransport>} = {};
    private connections: {[id: string]: MediasoupHandler} = {};
    closed: boolean;
    peerData: MediaStreamData;

    transport: mediasoupClient.types.Transport;
    rtpCapabilities?: any;
    stats?: Map<string, any>;
    lastStats?: number;

    callbacks = {};

    static async connect(mediaService: MediaService, meetingId: string, server: string, receiver: boolean, connection: MediasoupHandler) {
        const key = `${meetingId}:${server}:${receiver}`;
        if (!MediasoupTransport.transports[key]) {
            MediasoupTransport.transports[key] = new MediasoupTransport(mediaService, meetingId, server, receiver).reconnect();
        }
        const transport = await MediasoupTransport.transports[key];
        transport.addConnection(connection);
        return transport;
    }

    constructor(public mediaService: MediaService, public meetingId: string, public server: string, public receiver: boolean) {}

    addConnection(connection: MediasoupHandler) {
        this.connections[connection.broadcastId || ''] = connection;
    }

    async removeConnection(connection: MediasoupHandler, reason?) {
        delete this.connections[connection.broadcastId || ''];
        if (reason !== MediaEndedReason.Closed) await sleep(5000);
        if (Object.keys(this.connections).length > 100) {
            this.closed = true;
            const key = `${this.meetingId}:${this.server}:${this.receiver}`;
            delete MediasoupTransport.transports[key];

            this.close(reason);
        }
    }

    private close(reason?) {
        this.transport?.close();
        this.transport = null;
        if (this.peerData) {
            delete WEBRTC_CONNECTIONS[this.peerData.id];
            this.mediaService.end(this.peerData.meetingId, this.peerData.id, reason || MediaEndedReason.Closed).catch(() => {});
            this.peerData = null;
        }
    }

    async getStats() {
        if (!this.stats || this.lastStats <= new Date().getTime() - 2000) {
            this.lastStats = new Date().getTime();
            this.stats = await (this.transport?.handler as any)?._pc?.getStats();
        }
        return this.stats;
    }

    private async reconnect() {
        if (this.closed) return;

        try {
            await this.connect();
            await Promise.all(Object.values(this.connections).map(connection => connection.transportReady()));
        } catch (e) {
            console.error(e);
        }
        return this;
    }

    private async connect() {
        let connectTimer = setTimeout(() => this.reconnect(), 15000);

        const peerData = this.receiver ? await this.mediaService.receiveStart(null, { meetingId: this.meetingId, server: this.server, mediasoup: true })
            : await this.mediaService.sendStart(null, { meetingId: this.meetingId, mediasoup: true });
        WEBRTC_CONNECTIONS[peerData.id] = this;

        const {
            id,
            iceParameters,
            iceCandidates,
            dtlsParameters,
            sctpParameters,
            rtpCapabilities,
        } = peerData.localDescription;

        const rtcConfig = getRTCConfiguration(peerData.turn);

        const device = new mediasoupClient.Device();
        await device.load({ routerRtpCapabilities: rtpCapabilities });

        const transportParams = {
            id,
            iceParameters,
            iceCandidates,
            dtlsParameters: { ...dtlsParameters, role: 'auto' },
            sctpParameters,
            iceServers: rtcConfig.iceServers,
        };
        const transport = this.receiver ? device.createRecvTransport(transportParams) : device.createSendTransport(transportParams);
        transport.on('connectionstatechange', (connectionState) => {
            // eslint-disable-next-line no-console
            console.log(`WEBRTC CONNECTION ${connectionState} ${this.receiver ? 'receiver' : 'sender'}`);
            if (connectionState === 'connected') {
                clearTimeout(connectTimer);
                connectTimer = null;
            } else {
                if (!connectTimer) connectTimer = setTimeout(() => this.reconnect(), 15000);
            }
        });
        transport.on('connect', async ({ dtlsParameters }, callback, errback) => {
            try {
                await this.answer({ dtlsParameters });
                callback();
            } catch (e) {
                console.error(e);
                errback(e);
            }
        });
        transport.on('produce', async ({ kind, rtpParameters, appData }, callback, errback) => {
            try {
                const id = uuid();
                this.callbacks[id] = callback;
                await this.answer({ id, kind, rtpParameters, appData });
            } catch (e) {
                console.error(e);
                errback(e);
            }
        });


        this.close(MediaEndedReason.Disconnected);
        this.rtpCapabilities = device.rtpCapabilities;
        this.peerData = peerData;
        this.transport = transport;
        if (this.closed) this.close(MediaEndedReason.Closed);
    }

    async produced(obj) {
        this.callbacks[obj.id]?.(obj);
        delete this.callbacks[obj.id];
    }

    async consumed(obj) {
        const handler = this.connections[obj.broadcastId];
        handler?.consumed(obj);
    }

    async consumerClosed(obj) {
        const handler = this.connections[obj.broadcastId];
        handler?.consumerClosed(obj);
    }

    async consumerPaused(obj) {
        const handler = this.connections[obj.broadcastId];
        handler?.consumerPaused(obj);
    }

    async consumerResumed(obj) {
        const handler = this.connections[obj.broadcastId];
        handler?.consumerResumed(obj);
    }

    get connected() { return this.transport?.connectionState === 'connected'; }

    async answer(data) {
        return await this.mediaService.answer(this.peerData.id, data);
    }
}
