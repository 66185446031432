import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { SwPush } from '@angular/service-worker';
import { take } from 'rxjs/operators';
import { HttpService } from './http.service';

const VAPID_SUBSCRIPTION_RADIO = 'VAPID_SUBSCRIPTION_RADIO';

// Don't provide in root. Only want one instance in crews
@Injectable()
export class PushService {
    enabled: boolean;
    accountId: string;

    constructor(private swPush: SwPush,
            private httpService: HttpService,
            private router: Router) {
        this.swPush.notificationClicks.subscribe(
            async ({ action, notification }) => {
                const data = notification.data;
                let url;
                if (data.topic === 'channel') {
                    url = `/radio?id=${data.data.channelId}`;
                    if (data.accountId !== this.accountId) {
                        url += `&accountId=${data.accountId}`;
                    }
                } else if (data.topic === 'meeting') {
                    url = `/meeting/join/${data.data.id}/account/${data.accountId}`;
                }
                if (url) this.router.navigateByUrl(url);
            });
    }

    readonly VAPID_PUBLIC_KEY_RADIO = 'BDVu2RcchjFYk9ZqAWL8aXo4zEKTiethfjBR1XW80dQmPrANM0g07by72JlcFAV6MHGWUDZlJEPzoRVl_uySqZg';

    async register(userId: string, accountId: string) {
        try {
            const locale = localStorage.getItem('lang') || 'en';
            await this.httpService.put(null, `/core/users/${userId}`, { locale });
            await this.resetSubscriptionIfServerIsMismatched(userId);
            const data = await this.swPush.requestSubscription({ serverPublicKey: this.VAPID_PUBLIC_KEY_RADIO });
            await this.httpService.put(null, `/core/users/${userId}/add-push`, { id: data, type: 'vapid-radio', locale });
            localStorage.setItem(VAPID_SUBSCRIPTION_RADIO, JSON.stringify(data));
            this.enabled = true;
            this.accountId = accountId;
        } catch (e) {
            const subId = localStorage.getItem(VAPID_SUBSCRIPTION_RADIO);
            if (subId) {
                try {
                    await this.deregister(userId);
                } catch (e) {
                    console.error(e);
                }
            }
            if (e instanceof DOMException && e.name === 'NotAllowedError') {
                console.warn('Denied permission for web push notifications.', e);
            } else {
                console.error('Failed to register push notifications.', e);
            }
        }
    }

    async resetSubscriptionIfServerIsMismatched(userId: string) {
        const existingSubscription = await this.swPush.subscription.pipe(take(1)).toPromise();
        if (existingSubscription) {
            const appServerKey = this.arrayBufferToBase64(existingSubscription.options.applicationServerKey);
            if (appServerKey !== VAPID_SUBSCRIPTION_RADIO) {
                this.swPush.unsubscribe();
                this.deregister(userId);
            }
        }
    }

    async deregister(userId: string) {
        const vapidCrews = localStorage.getItem(VAPID_SUBSCRIPTION_RADIO);
        if (vapidCrews) {
            localStorage.removeItem(VAPID_SUBSCRIPTION_RADIO);

            const data = JSON.parse(vapidCrews);
            try {
                await this.httpService.put(null, `/core/users/${userId}/remove-push`, { id: data, type: 'vapid-radio' });
            } catch (e) {
                console.error('Failed to deregister push notifications.', e);
            }
        }
    }

    private arrayBufferToBase64(buf: ArrayBuffer): string {
        const arrayBufferToString = String.fromCharCode.apply(null, new Uint8Array(buf));
        return btoa(arrayBufferToString);
    }
}
