import { Facility } from '@weavix/models/src/facility/facility';
import { ObservableSubscribe, TopicPayload } from '@weavix/mqtt/src/mqtt-service';
import { ReplaySubject } from 'rxjs';

export abstract class FacilityServiceStub {
    public currentFacility$: ReplaySubject<Facility> = new ReplaySubject(1);

    abstract get(component: any, id: string, cache?: boolean): Promise<Facility>;
    abstract getCurrentFacility(): Promise<Facility>;
    abstract subscribeFacilityUpdates(c: any, accountId: string, facilityId: string): ObservableSubscribe<TopicPayload<any>>;
}
