import { Injectable } from '@angular/core';
import { Utils } from '@weavix/components/src/utils/utils';
import { Craft } from 'weavix-shared/models/craft.model';
import {
    AddMeetingAttendeesRequest, AttendeeUpdateRequest, CreateMediaRequest, CreateMeetingRequest, InviteeUpdateRequest, JoinMeetingRequest, MediaEndedReason,
    MediaLocalDescription, MediaStreamData, Meeting, MeetingAnnotateUpdate, MeetingAttendee, MeetingConnection, MeetingToken, MeetingUpdateRequest,
} from 'weavix-shared/models/media.model';
import { Topic } from '@weavix/models/src/topic/topic';
import { AccountService } from 'weavix-shared/services/account.service';
import { HttpService } from 'weavix-shared/services/http.service';
import { PubSubService } from 'weavix-shared/services/pub-sub.service';
import { getItem, setItem } from 'weavix-shared/utils/utils';

@Injectable({
    providedIn: 'root',
})
export class MediaService {
    meetingId: string;

    constructor(
        private httpService: HttpService,
        private pubSubService: PubSubService,
        private accountService: AccountService,
    ) {
    }

    private getUrl(path: string, meetingId?: string, accountId?: string) {
        const token = this.findMeetingToken(meetingId ?? this.meetingId);
        return token?.token ? `/meeting/${token.token}${path}` : accountId ? `/a/${accountId}${path}` : path;
    }

    findMeetingToken(meetingId: string): MeetingToken {
        const text = getItem(meetingId);
        try {
            return JSON.parse(text);
        } catch (e) {
            return null;
        }
    }

    async sendStart(component, body: CreateMediaRequest) {
        return await this.httpService.post<MediaStreamData>(component, this.getUrl('/media/broadcaster', body.meetingId), body);
    }

    async setBandwidth(component, id: string, bandwidth: number) {
        return await this.httpService.put<MediaStreamData>(component, this.getUrl(`/media/${id}/bandwidth`), { bandwidth });
    }

    async answer(id: string, body: MediaLocalDescription) {
        const accountId = await this.accountService.getAccountId();
        const token = this.findMeetingToken(this.meetingId);
        if (token) await this.pubSubService.publish(Topic.MeetingAnswer, [token.token, id], body);
        else {
            const user = await this.accountService.getCurrentUser();
            const personId = user.accounts?.find(x => x.id === accountId)?.personId;
            await this.pubSubService.publish(Topic.AccountPersonAnswer, [accountId, personId, id], body);
        }
    }

    async end(component, id: string, reason: MediaEndedReason) {
        return await this.httpService.delete<MediaStreamData>(component, this.getUrl(`/media/${id}`), { reason });
    }

    async receiveAudio(component, meetingId: string) {
        return await this.httpService.post<MediaStreamData>(component, this.getUrl('/media/audio-viewer'), { meetingId });
    }

    async receiveStart(component, body: CreateMediaRequest) {
        return await this.httpService.post<MediaStreamData>(component, this.getUrl('/media/viewer'), body);
    }

    async getMeeting(component, id: string, accountId: string) {
        return await this.httpService.get<Meeting>(component, this.getUrl(`/media/meetings/${id}`, id, accountId));
    }

    async getRecurringMeeting(component, id: string, accountId: string) {
        return await this.httpService.get<Meeting>(component, this.getUrl(`/media/meetings/${id}/recurring`, id, accountId));
    }

    async getActiveMeetings(component) {
        return await this.httpService.get<Meeting[]>(component, `/media/meetings/active`);
    }

    async getScheduledMeetings(component, from: Date, to: Date) {
        return await this.httpService.get<Meeting[]>(component, `/media/meetings/scheduled`, { from, to });
    }

    async getPastMeetings(component) {
        return await this.httpService.get<Meeting[]>(component, `/media/meetings/past`);
    }

    async createMeeting(component, body: CreateMeetingRequest) {
        const accountId = await this.accountService.getAccountId();
        return await this.httpService.post<Meeting>(component, `/a/${accountId}/media/meetings`, body);
    }

    async updateMeeting(component: any, id: string, body: MeetingUpdateRequest) {
        return await this.httpService.put<Meeting>(component, `/media/meetings/${id}`, body);
    }

    async deleteMeeting(component: any, id: string) {
        return await this.httpService.delete<Meeting>(component, `/media/meetings/${id}`);
    }

    async addMeetingAttendees(component: any, id: string, body: AddMeetingAttendeesRequest) {
        return await this.httpService.post<Meeting>(component, this.getUrl(`/media/meetings/${id}/attendee`, id), body);
    }

    async joinMeeting(component: any, id: string, body: JoinMeetingRequest) {
        return await this.httpService.post<MeetingAttendee>(component, this.getUrl(`/media/meetings/${id}/join`, id), body);
    }

    async getMeetingAttendees(component, meetingId: string) {
        return await this.httpService.get<MeetingAttendee[]>(component, this.getUrl(`/media/meetings/${meetingId}/attendees`, meetingId));
    }

    async updateMyAttendee(component, meetingId: string, body: AttendeeUpdateRequest) {
        return await this.httpService.put<MeetingAttendee>(component, this.getUrl(`/media/meetings/${meetingId}/attendeeUpdate`, meetingId), body);
    }

    async sendClientDisconnect(component, meetingId: string) {
        return await this.httpService.put<any>(component, this.getUrl(`/media/meetings/${meetingId}/disconnect`, meetingId), {});
    }

    async updateInvitee(component, meetingId: string, body: InviteeUpdateRequest) {
        return await this.httpService.put<MeetingAttendee>(component, this.getUrl(`/media/meetings/${meetingId}/inviteeUpdate`, meetingId), body);
    }

    async getCraft(component, craftId: string) {
        return await this.httpService.get<Craft>(component, this.getUrl(`/core/crafts/${craftId}`));
    }

    async getCrafts(component) {
        return await this.httpService.get<Craft[]>(component, this.getUrl('/core/crafts'));
    }

    async getCraftsMap(component) {
        return Utils.toObjectMap(await this.getCrafts(component), x => x.id, x => x);
    }

    async getMeetingToken(component, token: string, onWeavix) {
        const meetingId = getItem(token);
        if (meetingId) {
            const existing = this.findMeetingToken(meetingId);
            if (existing) return existing;
        }
        const found = await this.httpService.get<MeetingToken>(component, `/account/meeting/${token}`);
        if (!found.token) {
            onWeavix(found);
            return found;
        }
        setItem(found.meetingId, JSON.stringify(found));
        setItem(token, found.meetingId);
        return found;
    }

    subscribeMeetingUpdates(component, personId: string) {
        const accountId = this.accountService.getAccountId();
        return this.pubSubService.subscribe<Meeting>(component, Topic.AccountPersonMeeting, [accountId, personId]);
    }

    subscribeAttendeeUpdates(component, meetingId: string, personId: string) {
        const accountId = this.accountService.getAccountId();
        const token = this.findMeetingToken(meetingId);
        return token ? this.pubSubService.subscribe<Partial<MeetingAttendee>>(component, Topic.MeetingAttendee, [token.token, personId])
            : this.pubSubService.subscribe<Partial<MeetingAttendee>>(component, Topic.AccountMeetingAttendee, [accountId, meetingId, personId]);
    }

    subscribeAttendeeDisconnects(component, meetingId: string, personId: string) {
        const accountId = this.accountService.getAccountId();
        const token = this.findMeetingToken(meetingId);
        return token ? this.pubSubService.subscribe<any>(component, Topic.MeetingAttendeeDisconnect, [token.token, personId])
            : this.pubSubService.subscribe<Meeting>(component, Topic.AccountMeetingAttendeeDisconnect, [accountId, meetingId, personId]);
    }

    async subscribeConnections(component, meetingId: string, personId: string) {
        const accountId = this.accountService.getAccountId();
        const token = this.findMeetingToken(meetingId);
        const obs = token ? this.pubSubService.subscribe<any>(component, Topic.MeetingConnection, [token.token])
            : this.pubSubService.subscribe<MeetingConnection>(component, Topic.AccountPersonConnection, [accountId, personId]);
        await obs.subscribed; // Make sure we are subscribed before taking on connections
        return obs;
    }

    subscribeAnnotationUpdates(component, meetingId: string, targetAttendeeId: string) {
        const accountId = this.accountService.getAccountId();
        const token = this.findMeetingToken(meetingId);
        return token ? this.pubSubService.subscribe<MeetingAnnotateUpdate>(component, Topic.MeetingAttendeeAnnotate, [token.token, targetAttendeeId], Infinity)
            : this.pubSubService.subscribe<MeetingAnnotateUpdate>(component, Topic.AccountMeetingAttendeeAnnotate, [accountId, meetingId, targetAttendeeId], Infinity);
    }

    async publishAnnotationUpdate(meetingId: string, targetAttendeeId: string, update: MeetingAnnotateUpdate) {
        const accountId = await this.accountService.getAccountId();
        const token = this.findMeetingToken(meetingId);
        if (token) this.pubSubService.publish(Topic.MeetingAttendeeAnnotate, [token.token, targetAttendeeId], update);
        else this.pubSubService.publish(Topic.AccountMeetingAttendeeAnnotate, [accountId, meetingId, targetAttendeeId], update);
    }
}
