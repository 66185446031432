
import { MediaStreamData } from 'weavix-shared/models/media.model';
import { sleep } from 'weavix-shared/utils/sleep';
import { isFirefox } from 'weavix-shared/utils/utils';
import { WebrtcInterface } from './webrtc-interface';

const commonAudio = false;
const MIN_AUDIO_BANDWIDTH = commonAudio ? 64000 : 24000;
const MIN_VIDEO_BANDWIDTH = 1000;
export const DEFAULT_BANDWIDTH = 768000 + 128000;


function setBandwidth(sdp, bandwidth: number) {
    const type = isFirefox() ? 'TIAS' : 'AS';
    if (type === 'AS') bandwidth = Math.ceil(bandwidth / 1000);
    const div = type === 'AS' ? 1 : 1000;
    const audio = Math.max(Math.ceil(bandwidth * 1 / 5), MIN_AUDIO_BANDWIDTH * div / 1000);
    const video = Math.max(bandwidth - audio, MIN_VIDEO_BANDWIDTH * div / 1000);
    sdp = sdp
        .replace(/;x-google-(max|min|start)-bitrate=[0-9]+/g, '')
        .replace(/b=AS:.*\r\n/, '')
        .replace(/b=TIAS:.*\r\n/, '')
        .replace(/a=mid:0\r\n/g, `a=mid:0\r\nb=${type}:${(audio)}\r\n`)
        .replace(/a=mid:1\r\n/g, `a=mid:1\r\nb=${type}:${(video)}\r\n`)
        .replace(/a=mid:audio\r\n/g, `a=mid:audio\r\nb=${type}:${(audio)}\r\n`)
        .replace(/a=mid:video\r\n/g, `a=mid:video\r\nb=${type}:${(video)}\r\n`)
        .replace(/a=fmtp:(.*)\r\n/g, `a=fmtp:$1;x-google-max-bitrate=${Math.ceil(video / div)};x-google-min-bitrate=0;x-google-start-bitrate=${Math.ceil(video / div)}\r\n`);
    sdp = sdp
        .replace(/a=extmap:(13) .*\r\n/g, ''); // Firefox does not support this extension and itbreaks video
    return sdp; // prioritizeCodec(sdp, 'H264');
}

async function updateBandwidth(pc: RTCPeerConnection, bandwidth: number) {
    // eslint-disable-next-line no-console
    console.log(`Updating pc bandwidth ${bandwidth}`);
    await pc.setRemoteDescription({
        type: pc.remoteDescription.type,
        sdp: setBandwidth(pc.remoteDescription.sdp, bandwidth),
    });
    const answer = await pc.createAnswer();
    await pc.setLocalDescription({
        type: answer.type,
        sdp: setBandwidth(answer.sdp, bandwidth),
    });
}

const WEAVIX_TURN_USERNAME = 'weavix';
const WEAVIX_TURN_PASSWORD = 'walt';

const bandwidthMultipliers: {[id: string]: number} = {};

// sdpSemantics: https://www.chromestatus.com/feature/5723303167655936
const baseRTCConfiguration: RTCConfiguration & { sdpSemantics: string } = {
    iceServers: [{ urls: 'stun:stun.l.google.com:19302' }],
    sdpSemantics: 'unified-plan',
};

function waitForIceGathering(peerConnection: RTCPeerConnection) {
    return new Promise<void>((resolve) => {
        const timeout = setTimeout(() => {
            // eslint-disable-next-line no-console
            console.log('[webrtc] gathering timeout');
            resolve();
        }, 1000);

        peerConnection.onicecandidate = candidate => {
            if (!candidate) {
                // eslint-disable-next-line no-console
                console.log('[webrtc] gathering complete');
                clearTimeout(timeout);
                resolve();
            }
        };
    });
}

export function getRTCConfiguration(weavixTurnUrls: string | string[]) {
    const config = { ...baseRTCConfiguration };
    if (weavixTurnUrls) {
        config.iceServers.push({ urls: weavixTurnUrls, username: WEAVIX_TURN_USERNAME, credential: WEAVIX_TURN_PASSWORD });
    }
    return config;
}

export class WebrtcConnection extends WebrtcInterface {

    get audioEnabled() {
        return this.audioTrack?.enabled;
    }

    get videoEnabled() {
        return this.videoTrack?.enabled;
    }

    get connected() { return this.peerConnection.iceConnectionState === 'connected' || this.peerConnection.iceConnectionState === 'completed'; }

    private videoSender: RTCRtpSender;
    private audioSender: RTCRtpSender;
    private peerConnection: RTCPeerConnection;
    private bandwidth: number;
    private server: MediaStreamData;

    private lastStats: Map<string, any>;

    setAudio(track: MediaStreamTrack) {
        if (!track) {
            this.audioTrack?.stop();
        }
        this.audioTrack = track;
        this.audioSender?.replaceTrack(track);
        this.setStreams();
    }

    setVideo(track: MediaStreamTrack, screenshare?: boolean) {
        if (!track) {
            this.videoTrack?.stop();
        }
        this.videoTrack = track;
        this.videoSender?.replaceTrack(track);
        this.setStreams();
    }

    close(reason) {
        if (this.closed) return;
        super.close(reason);

        if (this.server) {
            this.mediaService.end(null, this.server.id, reason).catch(() => {});
        }

        if (this.peerConnection) {
            this.peerConnection.close();
        }
    }

    async start() {
        let connectTimer;
        const reconnect = async () => {
            if (closed) return;
            this.reconnect$.next(true);
        };
        connectTimer = setTimeout(reconnect, 15000);

        const bandwidth = DEFAULT_BANDWIDTH / await WebrtcInterface.getPeople();
        this.server = this.broadcastId === 'audio' ? await this.mediaService.receiveAudio(null, this.meetingId)
            : this.broadcastId ? await this.mediaService.receiveStart(null, { meetingId: this.meetingId, id: this.broadcastId })
            : await this.mediaService.sendStart(null, { meetingId: this.meetingId, bandwidth });
        this.peerConnection = new RTCPeerConnection(getRTCConfiguration(this.server.turn));

        this.peerConnection.oniceconnectionstatechange = () => {
            // eslint-disable-next-line no-console
            console.log(`WEBRTC CONNECTION ${this.peerConnection.iceConnectionState}`);
            if (this.peerConnection.iceConnectionState === 'connected' || this.peerConnection.iceConnectionState === 'completed') {
                if (connectTimer) clearTimeout(connectTimer);
                connectTimer = null;
            } else {
                if (!connectTimer) connectTimer = setTimeout(reconnect, 10000);
            }
        };

        const gathering = waitForIceGathering(this.peerConnection);

        if (!this.broadcastId) {
            const deviceStream = new MediaStream([this.audioTrack, this.videoTrack].filter(x => !!x));
            deviceStream.getTracks().forEach(x => this.peerConnection.addTrack(x, deviceStream));
        }

        this.server.localDescription.sdp = setBandwidth(this.server.localDescription.sdp, bandwidth);
        await this.peerConnection.setRemoteDescription(this.server.localDescription);

        const answer = await this.peerConnection.createAnswer();
        answer.sdp = setBandwidth(answer.sdp, bandwidth);
        await this.peerConnection.setLocalDescription(answer);

        await gathering;
        await this.mediaService.answer(this.server.id, this.peerConnection.localDescription);

        this.audioSender = this.peerConnection.getSenders().find(x => x.track?.kind === 'audio');
        this.videoSender = this.peerConnection.getSenders().find(x => x.track?.kind === 'video');

        if (this.broadcastId) {
            this.audioTrack = this.peerConnection.getReceivers().find(x => x.track?.kind === 'audio')?.track;
            this.videoTrack = this.peerConnection.getReceivers().find(x => x.track?.kind === 'audio')?.track;
        }
        this.setStreams();
    }

    async setScreenShareEnabled(enabled: boolean) {
        try {
            await sleep(1000);
            const sender = await this.peerConnection.getSenders()[0];
            if (sender) {
                const parameters = sender.getParameters();
                parameters.degradationPreference = enabled ? 'maintain-resolution' : 'balanced';
                // eslint-disable-next-line no-console
                console.log(`Setting screenshare ${enabled}`);
                // await sender.setParameters(parameters);
            }
        } catch (e) {
            console.error(e);
        }
    }

    async updatePeople(people: number) {
        const bandwidth = DEFAULT_BANDWIDTH * 1 / Math.max(people, 1);
        bandwidthMultipliers[this.personId] = 1;
        await this.updateBandwidth(bandwidth);
    }

    async updateBandwidth(bandwidth: number) {
        try {
            if (this.peerConnection) await updateBandwidth(this.peerConnection, bandwidth);
            if (this.server) await this.mediaService.setBandwidth(null, this.server.id, bandwidth);
        } catch (e) {
            console.error(e);
        }
    }

    async checkStats() {
        const sender = !this.broadcastId;
        if (!bandwidthMultipliers[this.personId]) bandwidthMultipliers[this.personId] = 1;
        const previousBandwidth = bandwidthMultipliers[this.personId] * this.bandwidth;
        const stats: Map<string, any> = new Map();
        (await this.peerConnection.getStats()).forEach((value, key) => stats.set(key, value));
        let packets = 0;
        let nacks = 0;
        let audio = 0;
        let video = 0;
        const type = sender ? 'outbound-rtp' : 'inbound-rtp';
        let found = false;
        // eslint-disable-next-line complexity
        stats.forEach(stat => {
            if (stat.type === type) {
                const previous = this.lastStats?.get(stat.id);
                if (previous) {
                    found = true;
                    packets += (stat[sender ? 'packetsSent' : 'packetsReceived'] ?? 0) - (previous[sender ? 'packetsSent' : 'packetsReceived'] ?? 0);
                    nacks += (stat[sender ? 'nackCount' : 'packetsLost'] ?? 0) - (previous[sender ? 'nackCount' : 'packetsLost'] ?? 0);
                    if (stat.kind === 'audio') audio += (stat[sender ? 'bytesSent' : 'bytesReceived'] ?? 0) - (previous[sender ? 'bytesSent' : 'bytesReceived'] ?? 0);
                    if (stat.kind === 'video') video += (stat[sender ? 'bytesSent' : 'bytesReceived'] ?? 0) - (previous[sender ? 'bytesSent' : 'bytesReceived'] ?? 0);
                }
            }
        });
        this.lastStats = stats;
        if (!found) return;

        const audioKbps = Math.round(audio * 8 / 10000);
        const videoKbps = Math.round(video * 8 / 10000);
        const loss = nacks / packets;
        // eslint-disable-next-line no-console
        console.log(`${sender ? 'Sent' : 'Received'} audio ${audioKbps} kbps video ${videoKbps} kbps packet loss ${loss} ${this.personId}`);
        if (packets > 0 && nacks >= 0) {
            if (loss < 0.02) bandwidthMultipliers[this.personId] *= 1.08;
            else if (loss > 0.1) bandwidthMultipliers[this.personId] *= (1 - loss * 0.5);
            if (bandwidthMultipliers[this.personId] >= 1) bandwidthMultipliers[this.personId] = 1;
            const scaledBandwidth = bandwidthMultipliers[this.personId] * this.bandwidth;
            // eslint-disable-next-line no-console
            if (previousBandwidth !== scaledBandwidth) {
                this.updateBandwidth(scaledBandwidth);
            }
        }
        if (videoKbps <= 24) this.poor$.next(true);
        else this.poor$.next(false);
    }
}
