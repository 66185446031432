import { Component, Input } from '@angular/core';
import { css } from '@weavix/domain/utils/css';
import { TranslationServiceStub } from '@weavix/services/translation.service';

export interface MapControl {
    id: string;
    onClick?: () => void;
    tooltip: string;
    icon: string;
    iconHover?: string;
    isActive?: () => boolean;
    hide?: () => boolean;
    color?: string;
    options?: MapControl[];
    size?: number;
}

@Component({
    selector: 'app-map-control',
    templateUrl: './map-control.component.html',
    styleUrls: ['./map-control.component.scss', './map-control-teams.component.scss']
})
export class MapControlComponent {
    teamsApp = true;

    @Input() control: MapControl;
    displayOptions: boolean = false;
    colors = css.colors;
    buttonHovers: { [id: string]: boolean } = {}

    constructor(public translateService: TranslationServiceStub) {}

    toggleOptions() {
        this.displayOptions = !this.displayOptions;
    }

    closeOptions(): void {
        this.displayOptions = null;
    }

    handleControlOptionClick(option: MapControl): void {
        option?.onClick();
    }
}
