<div 
    class="map-contents"
    [style.height]="height"
    [style.width]="width"
>

    <ng-container *ngIf="mapLoaded">
        <div class="map-controls top right">
            <app-map-control *ngIf="features?.mapControls.recenter" class="control" [control]="features?.mapControls.recenter"></app-map-control>
            <app-map-control *ngIf="features?.mapControls.zoomIn" class="control" [control]="features?.mapControls.zoomIn"></app-map-control>
            <app-map-control *ngIf="features?.mapControls.zoomOut" class="control" [control]="features?.mapControls.zoomOut"></app-map-control>
            <app-map-control *ngIf="features?.mapControls.viewInMap" class="control" [control]="features?.mapControls.viewInMap"></app-map-control>
            <app-map-control *ngIf="features?.mapControls.mapLayers" class="control" [control]="features?.mapControls.mapLayers"></app-map-control>
        </div>
    </ng-container>

    <div #map class="map"></div>
</div>
