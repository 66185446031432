import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

import { BadgeUpdate } from '@weavix/models/src/badges/badge-update';
import { BadgeEvent } from '@weavix/models/src/badges/event';
import { AddUserToAccountRequest, CreateNewUserPersonRequest, PendingPerson, Person, PersonUpdate, SearchedUser, UserInviteActionRequest } from '@weavix/models/src/person/person';

import { AlertService } from './alert.service';
import { CacheContext, HttpService } from './http.service';
import { PubSubService } from './pub-sub.service';

import { PersonServiceStub } from '@weavix/services/src/person.service';
import { getRootStore } from 'crews/app/models-mobx/root-store/root-store';
import { Topic } from '@weavix/models/src/topic/topic';
import { AccountService } from './account.service';

export enum DeviceStatus {
    Active = 'active', // within a minute activity
    Inactive = 'inactive', // within 10 minutes activity
    Offline = 'offline', // last activity 1+ day ago
    Unregistered = 'unregistered'
}

@Injectable()
export class PersonService extends PersonServiceStub {
    constructor(
        private httpService: HttpService,
        private pubSubService: PubSubService,
        private accountService: AccountService,
        private alertsService: AlertService,
    ) {
        super();
    }

    static readonly ACTIVE_DEVICE_TIME_MINUTES = 30;
    static readonly INACTIVE_DEVICE_TIME_DAYS = 1;
    static baseUrl = '/core/people';
    static userUrl = '/core/users';
    static facilityPeopleUrl = '/core/facility-people';
    static cacheCollection = 'people';
    private static readonly cacheContext: CacheContext = { collection: PersonService.cacheCollection, maxAge: 1800000 };

    private cache: {[id: string]: Person};
    private cacheCreated;

    private updateSubscription: Subscription;
    static url = (id?: string) => id ? `${PersonService.baseUrl}/${id}` : PersonService.baseUrl;

    static hasActiveDevice(p: Person) {
        return !!p.badge?.location // has a location
            && !p.badge?.offline
            && !!p.badge.facilities?.length;
    }

    static hasActivatedInactiveDevice(p: Person, atTime?: number) {
        return p.badge?.location // has a location
            && !p.badge?.offline && (!p.badge?.date
                || new Date(p.badge?.date).getTime() < moment(atTime).subtract(this.INACTIVE_DEVICE_TIME_DAYS, 'days').valueOf()); // outside inactive time
    }

    static isActiveEvent(e: BadgeEvent, atTime?: number) {
        return new Date(e?.date ?? 1).getTime() > moment(atTime).subtract(this.ACTIVE_DEVICE_TIME_MINUTES, 'minutes').valueOf(); // within active time
    }

    static personIsInFacility(person: Person, facilityId: string): boolean {
        return (person?.badge?.facilities || []).some(f => f.id === facilityId) && PersonService.hasActiveDevice(person);
    }

    getUserFromStore(id: string) {
        return getRootStore().usersStore.get(id);
    }

    async getPeople(component, tags?: string[], cache: boolean = false): Promise<Person[]> {
        if (cache) {
            await this.checkCache(component);
            return tags ? Object.values(this.cache).filter(p => tags.some(t => p.tags && !!p.tags.find(t2 => t2 === t)))
                : Object.values(this.cache);
        }

        try {
            return await this.httpService.get<Person[]>(component, PersonService.url(), { tags }, null, (person: Person) => person.fullName);
        } catch (e) {
            this.alertsService.sendError(e, 'ERRORS.PEOPLE.GET');
            this.alertsService.setAppLoading(false);
            throw e;
        }
    }

    async getPendingPeople(component): Promise<PendingPerson[]> {
        try {
            return await this.httpService.get<PendingPerson[]>(component, `${PersonService.url()}/pending`, undefined, null, (person: PendingPerson) => person.fullName);
        } catch (e) {
            this.alertsService.sendError(e, 'ERRORS.PEOPLE.GET');
            this.alertsService.setAppLoading(false);
            throw e;
        }
    }

    async getFacilityPeople(component, facilityId: string, tags?: string[]) {
        try {
            return await this.httpService.get<Person[]>(component, `${PersonService.facilityPeopleUrl}/${facilityId}`, { tags }, PersonService.cacheContext, (person: Person) => person.fullName);
        } catch (e) {
            this.alertsService.sendError(e, 'ERRORS.PEOPLE.GET');
            this.alertsService.setAppLoading(false);
            throw e;
        }
    }

    getStaticPerson(id: string) {
        return this.cache && this.cache[id];
    }

    async getPerson(component, id: string, cache: boolean = false, skipNoCache: boolean = false) {
        if (cache) {
            await this.checkCache(component);
            if (this.cache[id]) return this.cache[id];
        }
        if (skipNoCache) return null;

        try {
            return await this.httpService.get<Person>(component, PersonService.url(id), null, null);
        } catch (e) {
            this.alertsService.sendError(e, 'ERRORS.PEOPLE.GET');
            this.alertsService.setAppLoading(false);
            throw e;
        }
    }

    async getPersonByPhone(component, phone: string) {
        return await this.httpService.post<Person[]>(component, `${PersonService.userUrl}/search`, { 'phoneNumbers': [ phone ] });
    }

    async getPersonByEmail(component, email: string) {
        return await this.httpService.post<Person[]>(component, `${PersonService.userUrl}/search`, { 'emails': [ email ] });
    }

    async createUserAndAddToAccount(component, newUser: CreateNewUserPersonRequest) {
        const data = await this.httpService.post<Person>(component, `${PersonService.url()}/new-user`, newUser);
        if (this.cache) this.cache[data.id] = data;
        return data;
    }

    async addUserToAccount(component, addUser: AddUserToAccountRequest) {
        const data = await this.httpService.post<Person>(component, `${PersonService.url()}/add-user`, addUser);
        if (this.cache) this.cache[data.id] = data;
        return data;
    }

    async inviteNewUser(component, newUser: CreateNewUserPersonRequest) {
        return await this.httpService.post<PendingPerson>(component, `${PersonService.url()}/invite-new-user`, newUser);
    }

    async inviteUser(component, addUser: AddUserToAccountRequest) {
        return await this.httpService.post<PendingPerson>(component, `${PersonService.url()}/invite-user`, addUser);
    }

    async resendUserInvite(component, request: UserInviteActionRequest) {
        return await this.httpService.post<PendingPerson>(component, `${PersonService.url()}/resend-invite`, request);
    }

    async revokeUserInvite(component, request: UserInviteActionRequest) {
        return await this.httpService.post<void>(component, `${PersonService.url()}/revoke-invite`, request);
    }

    async updatePerson(component, id: string, personData: PersonUpdate): Promise<Person> {
        const data = await this.httpService.put<Person>(component, PersonService.url(id), personData);
        if (this.cache) this.cache[data.id] = data;
        return data;
    }

    async deletePerson(component, id: string): Promise<Person> {
        const result = await this.httpService.delete<Person>(component, PersonService.url(id), null);
        if (this.cache) delete this.cache[id];
        return result;
    }

    async checkCache(component) {
        if (!this.updateSubscription) {
            this.updateSubscription = this.accountService.account$.subscribe(async account => {
                const sub = this.pubSubService.subscribe<Person>(null, Topic.AccountPersonUpdated, [account.id, '+']);
                sub.subscribe(payload => {
                    if (Object.keys(payload.payload).length) {
                        this.cache[payload.replacements[1]] = payload.payload;
                    } else {
                        delete this.cache[payload.replacements[1]];
                    }
                });
            });
        }
        if (!this.cacheCreated || this.cacheCreated.getTime() < new Date().getTime() - 1800000) {
            try {
                let people = await this.httpService.get<Person[]>(component, PersonService.url());
                const connectedPeople = await this.httpService.get<Person[]>(component, '/core/person-connections/people');
                people = [...people, ...connectedPeople];
                this.cache = people.reduce((arr, p) => (arr[p.id] = p, arr), {} as any);
                this.cacheCreated = new Date();
            } catch (e) {
                this.alertsService.sendError(e, 'ERRORS.PEOPLE.GET');
                this.alertsService.setAppLoading(false);
                throw e;
            }
        }
    }

    async searchUsers(component, text: string, facilityId?: string) {
        return this.httpService.get<SearchedUser[]>(component, `${PersonService.url()}/search-users`, { text, facilityId });
    }

    subscribePersonUpdates(c: any) {
        return this.pubSubService.subscribe<Person>(c, Topic.AccountPersonUpdated, [this.accountService.getAccountId(), '+']);
    }

    subscribeBadgeUpdates(c: any, accountId?: string, id?: string) {
        return this.pubSubService.subscribe<BadgeUpdate>(c, Topic.AccountPersonBadgeUpdated, [accountId ?? this.accountService.getAccountId(), id ?? '+']);
    }

    subscribeFacilityBadgeUpdates(c: any, facilityId: string) {
        return this.pubSubService.subscribe<BadgeUpdate>(c, Topic.AccountFacilityPersonBadgeUpdated, [this.accountService.getAccountId(), facilityId, '+']);
    }
}
