export abstract class ThemeServiceStub {

    static lightTheme: boolean;

    static setLightTheme(lightTheme: boolean) {
        this.lightTheme = lightTheme;
    }

    static getLightTheme(): boolean {
        return this.lightTheme;
    }
}
