import { Buffer } from 'buffer';

window.global = window as any;

global.Buffer = Buffer;

global.process = {
    title: 'browser',
    browser: true,
    env: {
        NODE_ENV: 'production',
    },
    nextTick: (fn: any, ...args: any[]) => {
        setTimeout(fn, 0, ...args);
    },
} as any;

export function bootstrap() {
    /*
        We don't actually need this function.
        But it keeps other files from complaining about an unused import.

        We do need the stuff outside of this function to run as part
        of the require/import cycle, so it has to happen before this
        function runs.
    */
}
