import { debounce } from 'lodash';
import { Observable, Subscription, timer } from 'rxjs';
import { take } from 'rxjs/operators';

export class LazyStorage<T> {
    private static write$?: Observable<any>;
    private writeSubscription?: Subscription;
    private cache?: { [key: string]: T };
    reset = debounce(() => {
        this.cache = undefined;
    }, 15000);

    constructor(public key: string) {}

    clear() {
        this.cache = undefined;
        localStorage.setItem(this.key, '{}');
    }

    get map() {
        if (!this.cache) {
            const dataText = localStorage.getItem(this.key) ?? '{}';
            this.cache = JSON.parse(dataText) as { [key: string]: T };
        }
        this.reset();
        return this.cache;
    }

    get(key: string): T {
        return this.map[key];
    }

    set(key: string, value: T) {
        this.map[key] = value;
        this.write();
    }

    remove(key: string) {
        delete this.map[key];
        this.write();
    }

    // Ensures all lazy storage gets flushed in the order they were written to avoid data inconsistencies
    private write() {
        if (!this.writeSubscription) {
            if (!LazyStorage.write$) {
                LazyStorage.write$ = timer(250).pipe(take(1));
                LazyStorage.write$.subscribe(() => (LazyStorage.write$ = undefined));
            }
            this.writeSubscription = LazyStorage.write$.subscribe(() => {
                this.writeSubscription = undefined;

                localStorage.setItem(this.key, JSON.stringify(this.map));
            });
        }
    }
}
