import { ConfinedSpaceItem, Entry } from './confined-space.model';
import { Taggable } from './core.model';
import { Point } from './location.model';

export interface ItemType extends Taggable {
    name: string;
    description?: string;
    mapItem: boolean;
    dynamicMapItem?: boolean;
    baseType?: ItemBaseType;
    icon: {
        value: string;
        color: string;
    };
    fields: ItemField[];
    itemCount?: number;
    entries?: Entry[];
    isDefault?: boolean;
}

export enum ItemBaseType {
    ConfinedSpace = 'confined-space'
}

export interface BaseItemField {
    fieldId?: string;
    display: string;
    type: FieldType;
}

export type TextField = BaseItemField;
export type NumberField = BaseItemField;

export interface DropDownField extends BaseItemField {
    options: string[];
}

export type ItemField = TextField | NumberField | DropDownField;

export enum FieldType {
    Dropdown = 'dropdown',
    Text = 'text',
    Number = 'number',
    Fixed = 'fixed',
    Date = 'date'
}
export interface FieldTransfer {
    fieldId?: string;
    display?: string;
    number?: number;
    editing?: boolean;
}

export interface ItemProperty {
    value: any;
    fieldId: string;
}

export interface Item extends Taggable {
    name: string;
    typeId: string;
    location?: Point;
    itemProperties: ItemProperty[];
    attributes?: {
        key: string;
        value: string
    }[];
    wispId?: string;
    wispBattery?: number;
    locationAccuracy?: number;
    locationDate?: Date;
    owner?: string;
    rental?: boolean;
    assignedToId?: string; // person id
    image?: string;
}

export enum EntryType {
    Entrant = 'entrant',
    Entry = 'entry',
    Exit = 'exit',
    AttendantIn = 'attendant-in',
    AttendantOut = 'attendant-out',
    WilmaIn = 'wilma-in',
    WilmaOut = 'wilma-out'
}

export interface ItemLocationUpdate {
    location?: Point;
    locationAccuracy?: number;
    locationDate?: Date;
    locationLevel?: number;
}

export type AnyItem = Item | ConfinedSpaceItem;

export enum ItemErrorReason {
    HasActiveHoleWatch = 'has-active-hole-watch'
}
