export interface Modal {
    isOpen: boolean;
    width?: number;
    height?: number;
    maxHeight?: string;
    maxWidth?: string;
    minHeight?: string;
    minWidth?: string;
    overflow?: string;
    zIndex?: number;
    header?: {
        textKey?: string;
        textAlignment?: 'left' | 'center' | 'right';
        showSeparator?: boolean;
    };
    textKey?: string;
    actions?: ModalActions;
    actionsAlignment?: 'left' | 'center' | 'right' | 'stretch';
    fullScreen?: boolean;
    fitContent?: boolean;
    preview?: boolean;
    content?: boolean;
    params?: any;
    backgroundColor?: string;
    fontColor?: string;
}

export enum ModalActionType {
    cancel = 'cancel',
    delete = 'delete',
    submit = 'submit',
    ok = 'OK'
}

export interface ModalActions {
    [ModalActionType.submit]?: ModalAction;
    [ModalActionType.cancel]?: ModalAction;
    [ModalActionType.delete]?: ModalAction;
}

interface ModalAction {
    show?: boolean;
    textKey?: string;
    buttonClass?: string;
    width?: number;
    disabled?: () => boolean;
    hidden?: () => boolean;
}

export enum ModalType {
    form = 'form',
    geoFenceForm = 'geoFenceForm',
    badgeForm = 'badgeForm',
    badgeImport = 'badgeImport',
    keySecret = 'keySecret',
    folder = 'folder',
    parent = 'parent',
    dashboard = 'dashboard',
    group = 'group',
    assignWisp = 'assignWisp'
}

export const defaultModal: Modal = {
    isOpen: false,
    width: 400,
    overflow: 'visible',
    actions: {
        [ModalActionType.submit]: {
            show: false,
            textKey: 'shared.generics.confirm',
            buttonClass: 'primary'
        },
        [ModalActionType.cancel]: {
            show: false,
            textKey: 'shared.generics.cancel',
            buttonClass: 'cancel'
        },
        [ModalActionType.delete]: {
            show: false,
            textKey: 'shared.generics.delete',
            buttonClass: 'alert'
        }
    }
};

export interface ModalResult {
    action: ModalActionType;
    data?: any;
}
