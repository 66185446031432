import { CommonModule, DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

/* Third Party */
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieService } from 'ngx-cookie-service';
import { DeviceDetectorModule } from 'ngx-device-detector';

/* Modules */
import { SidebarModule } from '../left-navigation-bar/left-navigation-bar.module';
import { SharedModule } from '../shared/shared.module';
import { AppCoreRoutingModule } from './core-routing.module';

/* Components */
import { PageTemplateComponent } from './page-template/page-template.component';

/* Services */
import { DeactivateGuard } from '@weavix/components/src/guards/deactivate.guard';
import { MapStateServiceStub } from '@weavix/maps/src/services/map-state.service';
import { MapServiceStub } from '@weavix/maps/src/services/map.service';
import { AccountServiceStub } from '@weavix/services/src/account.service';
import { AlertMaydayServiceStub } from '@weavix/services/src/alert-mayday.service';
import { AlertServiceStub } from '@weavix/services/src/alert.service';
import { BadgeServiceStub } from '@weavix/services/src/badge.service';
import { ChannelServiceStub } from '@weavix/services/src/channel.service';
import { CommunicationStateServiceStub } from '@weavix/services/src/communication-state.service';
import { CompanyServiceStub } from '@weavix/services/src/company.service';
import { ConfinedSpaceServiceStub } from '@weavix/services/src/confined-space.service';
import { CraftServiceStub } from '@weavix/services/src/craft.service';
import { EnvironmentServiceStub } from '@weavix/services/src/environment.service';
import { FaceapiServiceStub } from '@weavix/services/src/faceapi.service';
import { FacilityServiceStub } from '@weavix/services/src/facility.service';
import { GeofenceServiceStub } from '@weavix/services/src/geofence.service';
import { ItemServiceStub } from '@weavix/services/src/item.service';
import { MapSearchServiceStub } from '@weavix/services/src/map-search.service';
import { MeetingServiceStub } from '@weavix/services/src/meeting.service';
import { ModalServiceStub } from '@weavix/services/src/modal.service';
import { PersonServiceStub } from '@weavix/services/src/person.service';
import { PopoverServiceStub } from '@weavix/services/src/popover.service';
import { ProfileServiceStub } from '@weavix/services/src/profile.service';
import { PttServiceStub } from '@weavix/services/src/ptt.service';
import { PubSubServiceStub } from '@weavix/services/src/pub-sub.service';
import { TeamsServiceStub } from '@weavix/services/src/teams.service';
import { ThemeServiceStub } from '@weavix/services/src/theme.service';
import { TranslationServiceStub } from '@weavix/services/src/translation.service';
import { UploadServiceStub } from '@weavix/services/src/upload.service';
import { WaltServiceStub } from '@weavix/services/src/walt.service';
import { AccountService } from 'weavix-shared/services/account.service';
import { AlertMaydayService } from 'weavix-shared/services/alert-mayday.service';
import { AlertService } from 'weavix-shared/services/alert.service';
import { BadgeService } from 'weavix-shared/services/badge.service';
import { ChannelService } from 'weavix-shared/services/channel.service';
import { CommunicationStateService } from 'weavix-shared/services/communication-state.service';
import { CompanyService } from 'weavix-shared/services/company.service';
import { ConfinedSpaceService } from 'weavix-shared/services/confined-space.service';
import { CraftService } from 'weavix-shared/services/craft.service';
import { EnvironmentService } from 'weavix-shared/services/environment.service';
import { FacilityService } from 'weavix-shared/services/facility.service';
import { FolderService } from 'weavix-shared/services/folder.service';
import { GeofenceService } from 'weavix-shared/services/geofence.service';
import { ItemService } from 'weavix-shared/services/item.service';
import { MapSearchService } from 'weavix-shared/services/map-search.service';
import { MapStateService } from 'weavix-shared/services/map-state.service';
import { MapService } from 'weavix-shared/services/map.service';
import { PersonService } from 'weavix-shared/services/person.service';
import { ProfileService } from 'weavix-shared/services/profile.service';
import { PubSubService } from 'weavix-shared/services/pub-sub.service';
import { PushService } from 'weavix-shared/services/push.service';
import { TeamsService } from 'weavix-shared/services/teams.service';
import { UploadService } from 'weavix-shared/services/upload.service';
import { WaltService } from 'weavix-shared/services/walt.service';
import { PopoverService } from '../shared/popover/popover.service';
import { OpenPageTemplateComponent } from './components/open-page-template/open-page-template.component';
import { FaceapiService } from './services/faceapi.service';
import { MeetingService } from './services/meeting/meeting.service';
import { PttService } from './services/meeting/ptt.service';
import { ModalService } from './services/modal/modal.service';
import { ThemeService } from './services/themeService';
import { TranslationService } from './services/translation/translation.service';

@NgModule({
    declarations: [
        PageTemplateComponent,
        OpenPageTemplateComponent,
    ],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        AppCoreRoutingModule,
        SharedModule,
        SidebarModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        DeviceDetectorModule.forRoot(),
    ],
    providers: [
        /* Use @Injectable({ providedIn: 'root' }) */
        CookieService,
        FolderService,
        PushService,
        { provide: DeactivateGuard, useClass: DeactivateGuard },
        { provide: AlertService, useClass: AlertService },
        { provide: AlertMaydayService, useClass: AlertMaydayService },
        { provide: AccountService, useClass: AccountService },
        { provide: BadgeService, useClass: BadgeService },
        { provide: ChannelService, useClass: ChannelService },
        { provide: CompanyService, useClass: CompanyService },
        { provide: CommunicationStateService, useClass: CommunicationStateService },
        { provide: ConfinedSpaceService, useClass: ConfinedSpaceService },
        { provide: CraftService, useClass: CraftService },
        { provide: EnvironmentService, useClass: EnvironmentService },
        { provide: FaceapiService, useClass: FaceapiService },
        { provide: FacilityService, useClass: FacilityService },
        { provide: GeofenceService, useClass: GeofenceService },
        { provide: ItemService, useClass: ItemService },
        { provide: MapSearchService, useClass: MapSearchService },
        { provide: MapService, useClass: MapService },
        { provide: MapStateService, useClass: MapStateService },
        { provide: MeetingService, useClass: MeetingService },
        { provide: ModalService, useClass: ModalService },
        { provide: PersonService, useClass: PersonService },
        { provide: PopoverService, useClass: PopoverService },
        { provide: ProfileService, useClass: ProfileService },
        { provide: PttService, useClass: PttService },
        { provide: PubSubService, useClass: PubSubService },
        { provide: TeamsService, useClass: TeamsService },
        { provide: ThemeService, useClass: ThemeService },
        { provide: TranslationService, useClass: TranslationService },
        { provide: UploadService, useClass: UploadService },
        { provide: WaltService, useClass: WaltService },
        { provide: AlertServiceStub, useExisting: AlertService },
        { provide: AlertMaydayServiceStub, useExisting: AlertMaydayService },
        { provide: AccountServiceStub, useExisting: AccountService },
        { provide: BadgeServiceStub, useExisting: BadgeService },
        { provide: ChannelServiceStub, useExisting: ChannelService },
        { provide: CompanyServiceStub, useExisting: CompanyService },
        { provide: CommunicationStateServiceStub, useExisting: CommunicationStateService },
        { provide: ConfinedSpaceServiceStub, useExisting: ConfinedSpaceService },
        { provide: CraftServiceStub, useExisting: CraftService },
        { provide: EnvironmentServiceStub, useExisting: EnvironmentService },
        { provide: FaceapiServiceStub, useClass: FaceapiService },
        { provide: FacilityServiceStub, useExisting: FacilityService },
        { provide: GeofenceServiceStub, useExisting: GeofenceService },
        { provide: ItemServiceStub, useExisting: ItemService },
        { provide: MapSearchServiceStub, useExisting: MapSearchService },
        { provide: MapServiceStub, useExisting: MapService },
        { provide: MapStateServiceStub, useExisting: MapStateService },
        { provide: MeetingServiceStub, useExisting: MeetingService },
        { provide: ModalServiceStub, useClass: ModalService },
        { provide: PersonServiceStub, useExisting: PersonService },
        { provide: PopoverServiceStub, useClass: PopoverService },
        { provide: ProfileServiceStub, useExisting: ProfileService },
        { provide: PttServiceStub, useClass: PttService },
        { provide: PubSubServiceStub, useExisting: PubSubService },
        { provide: TeamsServiceStub, useExisting: TeamsService },
        { provide: ThemeServiceStub, useExisting: ThemeService },
        { provide: TranslationServiceStub, useExisting: TranslationService },
        { provide: UploadServiceStub, useClass: UploadService },
        { provide: WaltServiceStub, useExisting: WaltService },
        { provide: Document, useExisting: DOCUMENT },
    ],
    exports: [RouterModule, SharedModule],
})
export class AppCoreModule {}

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
