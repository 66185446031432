import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Location } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DoCheck,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { OpenDirection } from '@weavix/models/src/core/core';
import { SystemFolderType } from '@weavix/models/src/folder/folder';
import {
    BulkEdit,
    PaginationDirection,
    Paginator,
    RowEdit,
    RowItem,
    RowItemType,
    TableColumn,
    TableEdit,
    TableOptions,
    TableRow,
    TableSetting
} from '@weavix/models/src/table/table';
import { PermissionAction } from '@weavix/permissions/src/permissions.model';
import { MapSearchServiceStub } from '@weavix/services/src/map-search.service';
import { ProfileServiceStub } from '@weavix/services/src/profile.service';
import { TeamsServiceStub } from '@weavix/services/src/teams.service';
import { ThemeServiceStub } from '@weavix/services/src/theme.service';
import { TranslationServiceStub } from '@weavix/services/src/translation.service';
import { sleep } from '@weavix/utils/src/sleep';

import { AutoUnsubscribe, Utils } from '../utils/utils';

export const ROOT_FOLDER = 'root-folder';
const SYSTEM_FOLDER_TYPES: string[] = Object.values(SystemFolderType);
const DEFAULT_ROW_ITEM_HEIGHT: number = 40;
const MAX_SUB_TABLE_HEIGHT: number = 300;

@AutoUnsubscribe()
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss', './table-teams.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableComponent implements OnChanges, DoCheck, OnInit, OnDestroy {
    private readonly BACK_TO_TOP_DISTANCE: number = 500;
    private readonly LAZY_LOAD_REMAINING_SCROLL_DISTANCE: number = 0;

    constructor(
        private cdr: ChangeDetectorRef,
        public translationService: TranslationServiceStub,
        private profileService: ProfileServiceStub,
        public teamsService: TeamsServiceStub,
        private route: ActivatedRoute,
        private location: Location,
        private mapSearchService: MapSearchServiceStub
        ) {
            if (this.route.queryParams) Utils.safeSubscribe(this, this.route.queryParams).subscribe(params => this.currRouteParams = params);
            Utils.safeSubscribe(this, this.mapSearchService.toggleFilterPanel).subscribe(() => this.highlightRow(null));
        }
    @Input() options: TableOptions;
    @Input() rows: any[] = [];
    @Input() totalItems: number = 0;
    @Input() isLazyLoading = false;
    @Output() searchOutput: EventEmitter<string> = new EventEmitter();
    @Output() tableEditClickOutput: EventEmitter<{edit: TableEdit}> = new EventEmitter();
    @Output() columnClickOutput: EventEmitter<{row: TableRow, item: RowItem}> = new EventEmitter();
    @Output() colPrefixClickOutput: EventEmitter<{row: TableRow, item: RowItem}> = new EventEmitter();
    @Output() colPostfixClickOutput: EventEmitter<{row: TableRow, item: RowItem}> = new EventEmitter();
    @Output() colEditClickOutput: EventEmitter<{row: TableRow, edit: RowEdit}> = new EventEmitter();
    @Output() lazyLoadMoreItemsOutput: EventEmitter<void> = new EventEmitter();
    currRouteParams: {[key: string]: string};

    // --- Template variables ---
    allRows: TableRow[] = []; // all rows transformed to TableRows
    curRows: TableRow[] = []; // subset of allRows, filtered
    slicedRows: TableRow[] = []; // subset of curRows, currently shown to user
    totalRows: number;
    maxEdits: RowEdit[] = [];

    pageRows: TableRow[] = [];
    headers: TableColumn[] = [];

    // Pagination
    pageStart: number = 0;
    initPageSize: number = 1000;
    showMoreSize: number = 500;
    pageEnd: number = 1;
    curPage: number = 0;
    curDirection: PaginationDirection = PaginationDirection.asc;

    // Search
    showSearch: boolean = true;
    searchQuery: string = '';
    customSearch: boolean = false;
    searchFields: string[] = [];
    noSearchResults: boolean = false;

    selectedFolderKeys: {[key: string]: TableRow} = {};
    selectedKeys: {[key: string]: TableRow} = {};
    objectKeys = Object.keys;
    showCheckboxes: boolean = true;
    multiSelect: boolean = true;
    selectable: boolean = true;
    rowsClickable: boolean = true;
    rowsDblClickable: boolean = false;
    checkOnClick: boolean = false;
    highlightOnClick: boolean = false;
    colKey: {[key: string]: TableColumn} = {};
    rowItemType = RowItemType;
    optionsLoaded: boolean = false;
    openDirection = OpenDirection;

    tableEdits: TableEdit[] = [];
    bulkEdits: BulkEdit[] = [];

    // Tags and filter
    showTagFilter: boolean = false;
    selectedTagIds: string[] = [];
    activeTags: boolean = false;
    showCraftFilter: boolean = false;
    selectedCraftIds: string[] = [];
    activeCrafts: boolean = false;
    activeFilter: boolean = false;
    @ViewChild('tableBody') tableBody: CdkVirtualScrollViewport;
    @ViewChild('tableHeader') tableHeader: ElementRef;
    lazyloadItems$ = new Subject<Event>();
    showBackToTop: boolean = false;
    // --- END Template variables ---

    private isRowDblClick: boolean = true;
    allItems: any[];
    subTableHeight: number;

    lightTheme: boolean;

    get staticTableEdits(): TableEdit[] { return this.options.tableEdits?.filter(t => t.static === true); }
    get dynamicTableEdits(): TableEdit[] { return this.options.tableEdits?.filter(t => !t.static); }

    ngOnInit() {
        this.lightTheme = ThemeServiceStub.getLightTheme();
        if (this.options.pagination) {
            if (this.options.pagination.initPageSize) {
                this.initPageSize = this.options.pagination.initPageSize;
                this.pageEnd = this.initPageSize - 1;
            }
            if (this.options.pagination.showMoreSize) {
                this.showMoreSize = this.options.pagination.showMoreSize;
            }
        }

        if (this.options.paginate) {
            if (this.options.paginate.pageSize) {
                this.initPageSize = this.options.paginate.pageSize;
            }

            if (this.options.paginate?.isLazyLoaded) {
                this.lazyloadItems$
                    .pipe(debounceTime(500))
                    .subscribe(event => this.checkLazyLoading(event));
            }
        }

        if (this.options.isSubTable) {
            this.setSubTableHeight();
        }
    }

    async ngOnChanges(event: SimpleChanges) {
        if (_.get(event.options, 'currentValue.presetSearchQuery') !== _.get(event.options, 'previousValue.presetSearchQuery')) {
            if (this.options.presetSearchQuery) this.searchQuery = this.options.presetSearchQuery;
        }

        if (event.options) {
            await this.setTableOptions();
        }

        if (event.totalItems && event.totalItems.currentValue === 0) {
            this.allRows = [];
            this.rows = [];
            this.curRows = [];
        }

        if (event.rows && !_.isEqual(event.rows.currentValue, event.rows.previousValue) && this.optionsLoaded) {
            this.rowsUpdated();
        }
    }

    async ngDoCheck() {
        if (this.rows && this.rows.length) {
            if (this.rows.length !== this.allRows.length && this.optionsLoaded) {
                this.rowsUpdated();
            }
        }
    }

    ngOnDestroy() {}

    private setSubTableHeight(): void {
        const heightByRows: number = (this.rows?.[0]?.roHeight ?? DEFAULT_ROW_ITEM_HEIGHT) * this.rows.length;
        this.subTableHeight = Math.min(heightByRows, MAX_SUB_TABLE_HEIGHT) + 70; // 70 accounts for some vertical padding in table
    }

    // Called through ViewChild
    clearSelected() {
        this.allRows.forEach(r => r.selected = false);
        this.selectedKeys = {};
        this.selectedFolderKeys = {};
        this.cdr.markForCheck();
    }

    /**
     * Force a single row to update. Rebuilds the specific TableRow object.
     * @param key The row (entity) id. Can be a string or composite key. Also supports an array of keys
     */
    forceRowUpdate(key: {} | {}[]) {
        const keys: {}[] = _.isArray(key) ? <{}[]>key : [<string>key];

        keys.forEach((k) => {
            const rowsWithKey = this.rows.find(x => _.isEqual(x[this.options.keyCol], k));
            if (rowsWithKey) {
                const newTableRow = this.createTableRow(rowsWithKey);
                this.allRows[this.allRows.findIndex(x => _.isEqual(x.key, k))] = newTableRow;
                this.applyFiltersAndSearch(false, false);
            }
        });
    }

    async submitSearchInput() {
        if (this.options.search.callback) {
            if (this.searchQuery.length > 0) {
                this.curPage = 0;
                const results = await this.options.search.callback(this.options.search.fields, this.searchQuery);
                this.noSearchResults = !results;
            }
        } else {
            this.applyFiltersAndSearch(false);
        }
    }

    async onSearchInput() {
        if ( this.searchQuery.length === 0 ) {
            this.curPage = 0;
            const results = await this.options.search.callback(this.options.search.fields, null);
            this.noSearchResults = !results;
        }
    }

    onSortClick(header: TableColumn) {
        this.options.columns.forEach(head => head.sort.selected = false);
        header.sort.selected = true;
        header.sort.sortAsc = !header.sort.sortAsc;

        if (this.options.settingsKey) {
            localStorage.setItem(`${this.options.settingsKey}-sort`, JSON.stringify({ [header.colKey]: header.sort.sortAsc }));
        }

        this.applyFiltersAndSearch(true, false, true);
    }

    hasPageRows() {
        return this.pageRows && this.pageRows.length > 0;
    }

    hasCurRows() {
        return this.curRows && this.curRows.length > 0;
    }

    hasAnyRows() {
        return this.allRows && this.allRows.length > 0;
    }

    hasRows() {
        return this.rows && this.rows.length > 0;
    }

    getNoDataMessage() {
        const messageKey = _.get(this.options, 'noData.messageKey');

        return messageKey ?
            `${this.translationService.getImmediate(messageKey)}` :
            `${this.translationService.getImmediate('add')} ${this.translationService.getImmediate(this.options.title)}`;
    }

    getNoDataIcon() {
        return _.get(this.options, 'noData.icon', {});
    }

    getSelectedKeyCount() {
        return Object.keys(this.selectedKeys).length + Object.keys(this.selectedFolderKeys).length;
    }

    getActionButtonText() {
        const selectedKeyCount = this.getSelectedKeyCount();
        const count = selectedKeyCount > 0 ? ` (${selectedKeyCount}) ${this.translationService.getImmediate('selected')}` : '';
        return `${this.translationService.getImmediate('table.actions')}${count}`;
    }

    private prepareDefaultSort() {
        if (this.options.settingsKey) {
            try {
                const sort = JSON.parse(localStorage.getItem(`${this.options.settingsKey}-sort`) || '{}');
                let empty = true;
                Object.keys(sort).forEach(x => {
                    const found = this.options.columns.find(c => c.colKey === x);
                    if (found) {
                        if (empty) {
                            this.options.columns.forEach(c => c.sort = { selected: false, sortable: true });
                        }
                        empty = false;
                        found.sort = {
                            selected: true,
                            sortable: true,
                            sortAsc: sort[x]
                        };
                    }
                });
                if (!empty) return;
            } catch (e) {
                console.error(e);
            }
        }
        const alreadySorted = this.options.columns.filter(c => c.sort && c.sort.sortable && c.sort.selected);
        if (alreadySorted && alreadySorted.length > 0) return;

        // check if there is a time-ago column to use that first
        // otherwise the first column
        const col = this.options.columns.find(c => c.type === RowItemType.timeAgo) || this.options.columns[0];
        if (col) {
            col.sort = {
                selected: true,
                sortable: true,
                sortAsc: col.type !== RowItemType.timeAgo
            };
        }
    }

    private setRows() {
        // for now there is problems trying to keep the selected
        // rows selected when there is an update
        this.selectedKeys = {};
        this.selectedFolderKeys = {};
        if (!this.rows) return;

        this.maxEdits = [];

        this.allRows = this.rows.map(r => this.createTableRow(r));
    }

    private createTableRow(r: any, options: TableOptions = this.options): TableRow {
        const rItems: RowItem[] = options.columns.map((c): RowItem => ({
            colKey: c.colKey,
            value: c.value ?  c.value(r) : _.get(r, c.colKey),
            tooltip: c.tooltip ?  c.tooltip(r) : null,
            sort: c.sort?.fn ?  c.sort.fn(r) : null,
            minWidth: c.minWidth,
            maxWidth: c.maxWidth,
            class: c.class ? c.class(r) : '',
            pending: c.pending ? c.pending(r) : null,
            prefix: c.prefix ? c.prefix(r) : null,
            postfix: c.postfix ? c.postfix(r) : null,
            clickable: c.clickable ? c.clickable(r) : false,
            overridesSelection: c.overridesSelection
        }));

        const locked = options.locked ? options.locked(r) : false;
        const rowDisabled = options.disableRow ? options.disableRow(r) : false;
        const rEdits: RowEdit[] = _.cloneDeep(options.rowEdits);
        const row: TableRow = {
            key: r[options.keyCol],
            items: rItems,
            style: options.format && options.format(r),
            original: r,
            selected: this.isRowSelected(r[options.keyCol]),
            edits: rEdits,
            clickable: this.rowsClickable && this.hasEditPermission(r.folderId) && !rowDisabled,
            link: options.routerLink ? options.routerLink(r) : null,
            checkbox: this.showCheckboxes && !locked,
            locked: this.showCheckboxes && locked,
            subTable: r.subTable
                ? {
                    ...r.subTable,
                    rows: r.subTable.rows
                } : null
        };
        if (row.edits.length > this.maxEdits.length) this.maxEdits = row.edits;
        return row;
    }

    getDummyEdits(editCount: number) {
        return new Array(Math.max(0, this.maxEdits.length - editCount)).fill(0);
    }

    private isRowSelected(key: string): boolean {
        const currentlyDisplayedRow = this.slicedRows[this.slicedRows.findIndex(x => x.key === key)];
        return currentlyDisplayedRow ? currentlyDisplayedRow.selected : false;
    }

    private rowsUpdated() {
        this.setRows();
        this.applyFiltersAndSearch(true, false);
    }

    private resetCurRows() {
        this.curRows = this.allRows.slice(0, this.allRows.length);
    }

    private updateSlicedRows() {
        this.slicedRows = this.curRows.slice(this.pageStart, this.options.paginate?.isLazyLoaded ? this.curRows.length : this.pageEnd + 1);
        this.totalRows = this.curRows.filter(r => !r.isFolder).length;
        if (this.curDirection === PaginationDirection.desc) this.slicedRows = this.slicedRows.reverse().slice();
    }

    getItemCount(): number {
        return this.curRows.filter(r => !r.isFolder).length;
    }

    private async setTableOptions() {
        if (this.options.hasDefaultSort !== false) {
            this.prepareDefaultSort();
        }

        this.setHeaders();
        if (this.options.search) {
            if (this.options.search.show !== undefined) this.showSearch = this.options.search.show;
            if (this.options.search.fields && this.options.search.fields.length > 0) this.searchFields = this.options.search.fields;
            if (this.options.search && this.options.search.customSearch) this.customSearch = this.options.search.customSearch;
        }
        if (this.options.select) {
            if (this.options.select.rowsDblClickable !== undefined) this.rowsDblClickable = this.options.select.rowsDblClickable;
            if (this.options.select.rowsClickable !== undefined) this.rowsClickable = this.options.select.rowsClickable;
            if (this.options.select.selectable !== undefined) this.selectable = this.options.select.selectable;
            if (this.options.select.multiSelect !== undefined) this.multiSelect = this.options.select.multiSelect;
            if (this.options.select.showCheckboxes !== undefined) this.showCheckboxes = this.options.select.showCheckboxes;
            if (this.options.select.checkOnClick !== undefined) this.checkOnClick = this.options.select.checkOnClick;
            if (this.options.select.highlightOnClick !== undefined) this.highlightOnClick = this.options.select.highlightOnClick;
        }
        if (this.searchFields.length < 1) this.showSearch = false;
        this.optionsLoaded = true;
    }

    private setHeaders() {
        this.colKey['folderName'] = {
            title: 'table.folders.name',
            colKey: 'folderName',
            type: RowItemType.text,
            prefix: (row) => {
                return { value: `<i class="fas fa-folder"></i>`, tooltip: row.name, event: false };
            }
        };
        this.options.columns.forEach(head => {
            this.colKey[head.colKey] = head;
            if (head.sort) {
                head.sort = {
                    sortable: head.sort.sortable === false ? false : true,
                    selected: head.sort.selected === true ? true : false,
                    sortAsc: head.sort.sortAsc === true ? true : false,
                    fn: head.sort.fn
                };
            } else {
                head.sort = {
                    sortable: true,
                    selected: false,
                    sortAsc: false,
                    fn: head.sort.fn
                };
            }
        });
    }

    private async applyFiltersAndSearch(applySort: boolean = false, resetView: boolean = true, reloadData: boolean = false) {
        if (applySort) {
            await this.applySort(reloadData);
        }
        if (!this.options.paginate) {
            this.resetCurRows();
            this.applySearch();
            if (resetView) {
                this.showMore(true);
            } else if (this.curRows.length <= this.initPageSize) {
                this.showMore(true);
            } else {
                this.updateSlicedRows();
            }
        } else {
            this.resetCurRows();
            this.updateSlicedRows();
        }

        this.cdr.markForCheck();
    }

    private applySearch() {
        if (this.customSearch) {
            this.searchOutput.emit(this.searchQuery);
        } else {
            if (this.searchQuery.length > 0 && this.curRows.length > 0 && this.searchFields.length > 0) {
                const searchParts = this.searchQuery.split(' ').map(p => p.toLowerCase());
                this.curRows = this.curRows.filter(r => !r.isFolder);
                this.curRows = this.curRows.filter(r => {
                    return !r.isFolder && searchParts.every(search => {
                        return r.items.some(i => {
                            if (i.colKey === 'name' || this.searchFields.includes(i.colKey)) {
                                if (i.value && i.value.toString().toLowerCase().includes(search)) return true;
                                if (i.prefix && i.prefix.tooltip && i.prefix.tooltip.toString().toLowerCase().includes(search)) return true;
                            }
                            return false;
                        });
                    });
                });
            }
        }
    }

    private async applySort(reloadData: boolean = false) {
        this.headers = this.options.columns.filter(h => h.sort.selected === true);
        if (!this.headers.length) return;

        // Only request from the backend if the data order changes.
        if (this.options.paginate && (this.allRows.length !== this.totalItems) && reloadData) {
            const limit = this.pageEnd - this.pageStart + 1;
            this.curDirection = PaginationDirection.asc;
            await this.options.paginate.loadMore(limit, this.headers[0], this.curDirection, reloadData);
            this.resetCurRows();
            this.updateSlicedRows();
            this.slicedRows.sort((a, b) => {
                for (let i = 1; i < this.headers.length; i++) {
                    const cmp = this.compareFn(a, b, this.headers[i], this.headers[i].sort.sortAsc);
                    if (cmp !== 0) return cmp;
                }
                return 0;
            });
        } else {
            if (!this.options.paginate) {
                this.allRows.sort((a, b) => {
                    for (let i = 0; i < this.headers.length; i++) {
                        const cmp = this.compareFn(a, b, this.headers[i], this.headers[i].sort.sortAsc);
                        if (cmp !== 0) return cmp;
                    }
                    return 0;
                });
                this.allRows.sort((a, b) => a.isFolder && !b.isFolder ? -1 : !a.isFolder && b.isFolder ? 1 :
                    a.isFolder && b.isFolder ? a.key === ROOT_FOLDER ? -1 : b.key === ROOT_FOLDER ? 1 :
                    a.items[0].value.toLowerCase() < b.items[0].value.toLowerCase() ? -1 : 1 : 0);
            } else if (reloadData) {
                this.allRows.sort((a, b) => {
                    for (let i = 0; i < this.headers.length; i++) {
                        const cmp = this.compareFn(a, b, this.headers[i], this.headers[i].sort.sortAsc);
                        if (cmp !== 0) return cmp;
                    }
                    return 0;
                });
                this.allRows.sort((a, b) => a.isFolder && !b.isFolder ? -1 : !a.isFolder && b.isFolder ? 1 :
                    a.isFolder && b.isFolder ? a.key === ROOT_FOLDER ? -1 : b.key === ROOT_FOLDER ? 1 :
                    a.items[0].value.toLowerCase() < b.items[0].value.toLowerCase() ? -1 : 1 : 0);
            } else {
                if (this.curDirection === PaginationDirection.desc) {
                    this.allRows.sort((a, b) => {
                        for (let i = 0; i < this.headers.length; i++) {
                            const cmp = this.compareFn(a, b, this.headers[i], !this.headers[i].sort.sortAsc);
                            if (cmp !== 0) return cmp;
                        }
                        return 0;
                    });
                } else {
                    this.allRows.sort((a, b) => {
                        for (let i = 0; i < this.headers.length; i++) {
                            const cmp = this.compareFn(a, b, this.headers[i], this.headers[i].sort.sortAsc);
                            if (cmp !== 0) return cmp;
                        }
                        return 0;
                    });
                }

                this.allRows.sort((a, b) => a.isFolder && !b.isFolder ? -1 : !a.isFolder && b.isFolder ? 1 :
                    a.isFolder && b.isFolder ? a.key === ROOT_FOLDER ? -1 : b.key === ROOT_FOLDER ? 1 :
                    a.items[0].value.toLowerCase() < b.items[0].value.toLowerCase() ? -1 : 1 : 0);
            }
        }
    }

    private compareFn(a: TableRow, b: TableRow, header: TableColumn, ascending: boolean): number {
        const riA = a.items.find(ri => ri.colKey === header.colKey);
        const riB = b.items.find(ri => ri.colKey === header.colKey);

        if (!riA || !riA.value) return ascending ? -1 : 1;
        if (!riB || !riB.value) return ascending ? 1 : -1;

        let aVal;
        let bVal;
        switch (header.type) {
            case RowItemType.text:
                aVal = String(riA.value).toLowerCase();
                bVal = String(riB.value).toLowerCase();
                break;
            case RowItemType.number:
                if (ascending) {
                    return String(riA.value).localeCompare(String(riB.value), undefined, { numeric: true, sensitivity: 'base' });
                } else {
                    return String(riB.value).localeCompare(String(riA.value), undefined, { numeric: true, sensitivity: 'base' });
                }
            case RowItemType.icon:
                aVal = riA.value.faIcon || riA.value.matIcon || riA.value.svgFile;
                bVal = riB.value.faIcon || riB.value.matIcon || riB.value.svgFile;
                break;
            case RowItemType.date:
            default:
                aVal = riA.value;
                bVal = riB.value;
                break;
        }
        if (riA.sort) aVal = riA.sort;
        if (riB.sort) bVal = riB.sort;
        if (ascending) {
            return aVal < bVal ? -1 : aVal === bVal ? 0 : 1;
        } else {
            return aVal < bVal ? 1 : aVal === bVal ? 0 : -1;
        }
    }

    selectRow(row: TableRow) {
        if (!this.selectable) return;

        if (row.isFolder) {

            if (row.selected) {
                row.selected = false;
                delete this.selectedFolderKeys[row.key];
            } else {
                if (this.multiSelect) {
                    row.selected = true;
                    this.selectedFolderKeys[row.key] = row;
                } else {
                    Object.keys(this.selectedFolderKeys).forEach(key => {
                        this.selectedFolderKeys[key].selected = false;
                        delete this.selectedFolderKeys[key];
                    });
                    row.selected = true;
                    this.selectedFolderKeys[row.key] = row;
                }
            }

        } else {

            if (row.selected) {
                row.selected = false;
                delete this.selectedKeys[row.key];
            } else {
                if (this.multiSelect) {
                    row.selected = true;
                    this.selectedKeys[row.key] = row;
                } else {
                    Object.keys(this.selectedKeys).forEach(key => {
                        this.selectedKeys[key].selected = false;
                        delete this.selectedKeys[key];
                    });
                    row.selected = true;
                    this.selectedKeys[row.key] = row;
                }
            }
        }

        this.cdr.markForCheck();
    }

    highlightRow(row: TableRow) {
        this.allRows.forEach(r => r.highlighted = false);
        if (row) row.highlighted = true;
    }

    async rowClick(row: TableRow, dblClick: boolean = false) {
        if (dblClick) {
            this.isRowDblClick = true;
        } else {
            this.isRowDblClick = false;
            await sleep(250);
            if (!this.isRowDblClick) {
                if (this.checkOnClick) {
                    this.selectRow(row);
                }
                if (this.highlightOnClick) {
                    this.highlightRow(row);
                }
            }
        }
    }

    columnClick(item: RowItem, row: TableRow) {
        this.columnClickOutput.emit({ row, item });
    }

    tableEditClick(edit: TableEdit): void {
        this.tableEditClickOutput.emit({ edit });
    }

    colPrefixClick(item: RowItem, row: TableRow): void {
        this.colPrefixClickOutput.emit({ row, item });
    }

    colPostfixClick(item: RowItem, row: TableRow): void {
        this.colPostfixClickOutput.emit({ row, item });
    }

    colEditClick(edit: RowEdit, row: TableRow): void {
        this.colEditClickOutput.emit({ row, edit });
    }

    handleOverrideSelection(item: RowItem, row: TableRow): void {
        this.unselectAll();
        this.curRows.forEach(r => {
            if (row.original?.id === r.original?.id) {
                if (!r.isFolder) {
                    r.selected = true;
                    this.selectedKeys[r.key] = r;
                } else {
                    if (r.key !== ROOT_FOLDER && !SYSTEM_FOLDER_TYPES.includes(r.key) && !r.locked) {
                        r.selected = true;
                        this.selectedFolderKeys[r.key] = r;
                    }
                }
            }
        });
    }

    selectAll() {
        if (this.areAllSelected()) {
            this.unselectAll();
        } else {
            this.curRows.forEach(r => {
                if (!r.isFolder) {
                    r.selected = true;
                    this.selectedKeys[r.key] = r;
                } else {
                    if (r.key !== ROOT_FOLDER && !SYSTEM_FOLDER_TYPES.includes(r.key) && !r.locked) {
                        r.selected = true;
                        this.selectedFolderKeys[r.key] = r;
                    }
                }
            });
        }
    }

    unselectAll() {
        this.selectedKeys = {};
        this.selectedFolderKeys = {};
        this.curRows.forEach(r => r.selected = false);
    }

    areAllSelected() {
        return this.getSelectedKeyCount() === this.curRows.filter(r => r.key !== ROOT_FOLDER && !SYSTEM_FOLDER_TYPES.includes(r.key) && !r.locked).length;
    }

    showMore(useStart: boolean) {
        this.pageEnd = useStart
            ? Math.min(this.pageStart + (this.initPageSize - 1), this.curRows.length - 1)
            : Math.min(this.pageEnd + this.showMoreSize, this.curRows.length - 1);
        this.updateSlicedRows();
    }

    checkLazyLoading(event: Event) {
        const el = <HTMLElement> event.target;
        const remainingScroll = el.scrollHeight - (el.scrollTop + el.offsetHeight);
        if (remainingScroll <= this.LAZY_LOAD_REMAINING_SCROLL_DISTANCE) {
            this.lazyLoadMoreItemsOutput.emit();
        }
    }

    checkShowBack(event: Event) {
        if ((<HTMLElement>event.target).scrollTop > this.BACK_TO_TOP_DISTANCE) {
            this.showBackToTop = true;
        } else {
            this.showBackToTop = false;
        }
    }

    backToTop() {
        if (this.tableBody) this.tableBody.elementRef.nativeElement.scrollTop = 0;
    }

    handleAnchorClick(event: Event, row: TableRow) {
        event.preventDefault();
        event.stopPropagation();

        this.rowClick(row, false);
    }

    getRowLink(row: TableRow) {
        return this.rowsClickable && row.clickable ? row.link : null;
    }

    getRouteParams(row: TableRow): {[key: string]: string} {
        return { ...this.currRouteParams };
    }

    hasViewPermission(folderId?: string) {
        return this.hasPermission(this.options.viewPermissionAction, folderId);
    }

    hasEditPermission(folderId?: string) {
        return this.hasPermission(this.options.editPermissionAction, folderId);
    }

    hasPermission(action: PermissionAction, folderId?: string) {
        return !action || this.profileService.hasPermission(action, this.options.facilityId, folderId);
    }

    getPermissionTooltip(tip: string, folderId?: string) {
        if (!this.hasEditPermission(folderId)) {
            return this.translationService.getImmediate('generics.forbiddenAction', { action: tip }, true);
        }
        return this.translationService.getImmediate(tip);
    }

    trackByKey = (row: TableRow) => row && row.key;

    getTableSettings() {
        return JSON.parse(localStorage.getItem('table-settings') || '{}');
    }

    getTableSetting(setting: TableSetting) {
        const settings = this.getTableSettings();
        return _.get(settings, [this.options.title, setting]);
    }

    onBack() {
        if (typeof this.options.back === 'function') {
            this.options.back();
        } else {
            this.location.back();
        }
    }

    async onChangePage(paginator: Paginator) {
        if (paginator.currentPage === this.curPage) {
            console.log('Duplicate Call');
            return;
        }

        let sort = paginator.direction;
        if (this.headers[0].sort.sortAsc) {
            sort = (sort === PaginationDirection.asc) ? PaginationDirection.desc : PaginationDirection.asc;
        }

        if (paginator.currentPage === paginator.totalPages && paginator.changedDirection) {
            sort = PaginationDirection.asc;
            this.allRows = [];
        }

        // if (paginator.currentPage === 1 && this.curPage !== 2) {
        if (paginator.currentPage === 1 && paginator.changedDirection) {
            sort = PaginationDirection.desc;
            this.allRows = [];
        }
        // Should be the default page size in all instances but the last page.
        const limit = paginator.endIndex - paginator.startIndex + 1;

        if (paginator.direction === PaginationDirection.desc) {
            this.pageEnd = paginator.totalItems -  paginator.startIndex - 1;
            this.pageStart = paginator.totalItems - paginator.endIndex - 1;
        } else {
            // If paging from the left, copy index.
            this.pageStart = paginator.startIndex;
            this.pageEnd = paginator.endIndex;
        }

        if (this.allRows.length < paginator.endIndex || this.allRows.length < this.pageEnd) {
            await this.options.paginate.loadMore(limit, (this.headers.length > 0) ? this.headers[0] : null, sort, paginator.changedDirection);
        } else {
            this.setRows();
            this.applySort();
            this.resetCurRows();
            this.updateSlicedRows();
        }

        this.curPage = paginator.currentPage;
        this.curDirection = sort;
    }
}
