import { Injectable, OnDestroy } from '@angular/core';
import { getRootStore, RootStore } from '../models-mobx/root-store/root-store';

@Injectable({
    providedIn: 'root',
})
export class RootStoreService implements OnDestroy {
    private rootStore: RootStore;

    public getRootStore(): RootStore {
        if (!this.rootStore) {
            this.rootStore = getRootStore();
        }
        return this.rootStore;
    }

    ngOnDestroy(): void {
        this.rootStore = null;
    }

}
