import { FacilityAlertLevel } from './facility-alerts.model';

export enum NotificationCenterType {
    FacilityAlert = 'facility-alert',
    PersonConnection = 'person-connection',
    PersonConnectionAccepted = 'person-connection-accepted',
    PersonConnectionDeclined = 'person-connection-declined',
    MissedPage = 'missed-page',
    MissedCall = 'missed-call'
}

export enum NotificationCenterAcknowledgement {
    Acknowledged = 'acknowledged',
    Accepted = 'accepted',
    Declined = 'declined',
    Canceled = 'canceled',
    Completed = 'completed',
    Closed = 'closed'
}

export interface Notification {
    id: any;
    created: Date;
    read: boolean;
    removed: Date;
    facilityId: string;
    userId: string;
    accountId: string;
    type: NotificationCenterType;
    dataId: any;
    acknowledgement?: string;
    data: any;
    title?: string;
    message?: string;
    locale?: string;
    location?: number[];
    senderId?: string;
    topic?: PushTopic;
    translations?: {
        [locale: string]: string;
    };
    titleTranslations?: {
        [locale: string]: string;
    };
    translatedMessage?: string;
    icon?: {
        value: string,
        color: string
    };
    alertLevel?: FacilityAlertLevel;
}

export enum PushTopic {
    Alert = 'alert',
    Channel = 'channel',
    FacilityAlerts = 'facility-alert',
    FacilityAlertsCall = 'facility-alert-call',
    HoleWatch = 'hole-watch',
    HoleWatchSwap = 'hole-watch-swap',
    Meeting = 'meeting',
    MissedCall = 'missed-call',
    MissedPage = 'missed-page',
    NotificationCenterEntry = 'notification-center-entry',

    Voip = 'voip',
    ChannelPage = 'channel-page',
    Ptt = 'ptt',
    Reconnect = 'reconnect',
}
