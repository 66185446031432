import { MessageAttachment } from './channels.model';
import { Person } from './person.model';

export type MediaLocalDescription = RTCSessionDescriptionInit | any;

export interface CreateMediaRequest {
    meetingId?: string;
    server?: string;
    id?: string;
    bandwidth?: number;
    localDescription?: MediaLocalDescription;
    replay?: boolean;
    audio?: boolean;
    video?: boolean;
    mediasoup?: boolean;
}

export interface CreateMediaResponse {
    id: string;
    server: string;
    localDescription: MediaLocalDescription;
    turn: string;
}

export type BroadcastType = 'nut' | 'aac' | 'mp4' | 'ac3';
export type AttendeeStatusType = 'accepted' | 'declined' | 'missed';

// Extends MediaConnection interface from backend
export interface MediaStreamData {
    id?: string;
    server?: string;
    channelId?: string;
    meetingId?: string;
    personId?: string;
    broadcastId?: string;
    started?: Date;
    ended?: Date;
    name?: string;
    streamUrl?: string;
    localDescription?: any;
    turn?: string;
}

export interface AccountPersonStreamTopicPayload {
    id: string; // mediaConnections.id
    audio: boolean;
    video: boolean;
    personId: string;
    server: string;
    meetingId: string;
}

export interface AudioConnection {
    id?: string;
    server?: string;
    broadcastType?: BroadcastType;
    channelId?: string;
    personId?: string;
    started?: Date;
    ended?: Date;
}

export interface Meeting {
    id: string;
    link?: string;
    channelId?: string;
    people?: MeetingPerson[];
    creator?: string;
    name?: string;
    started?: Date;
    ended?: Date;
    attachments?: MessageAttachment[];
    scheduledStart?: Date;
    scheduledEnd?: Date;
    comments?: string;
    locationName?: string;
    timezone?: string;
    recurring?: MeetingRecurrence;
}

export interface MeetingConnection {
    connectionId: string;
    event: string;
    data: any;
}

export enum MeetingRecurrenceType {
    Day = 'day',
    Week = 'week',
    Month = 'month',
    Year = 'year'
}

export interface MeetingRecurrence {
    type: MeetingRecurrenceType;
    from?: Date;
    duration?: number;
    until?: Date;
    days: {
        sunday: boolean;
        monday: boolean;
        tuesday: boolean;
        wednesday: boolean;
        thursday: boolean;
        friday: boolean;
        saturday: boolean;
    };
}

export interface MeetingPerson {
    personId?: string;
    email?: string;
}

export interface JoinMeetingRequest {
    avatarFile?: string;
    firstName?: string;
    lastName?: string;
    company?: string;
    email?: string;
}

export interface MeetingUpdateRequest {
    people?: MeetingPerson[];
    name?: string;
    scheduledStart?: Date;
    scheduledEnd?: Date;
    comments?: string;
    locationName?: string;
    timezone?: string;
    recurring?: MeetingRecurrence;
}

export interface AddMeetingAttendeesRequest {
    people?: {
        personId?: string;
        email?: string;
    }[];
}

export interface MeetingAttendee {
    id?: { meetingId?: string; personId?: string; };
    person?: Person;
    connectionId: string;
    connectionServer?: string;
    invited?: Date;
    joined?: Date;
    accepted?: Date;
    declined?: Date;
    missed?: Date;
    waited?: Date;
    admitted?: Date;
    rejected?: Date;
    audioEnabled?: boolean;
    videoEnabled?: boolean;
    videoMirrored?: boolean;
    screenShareEnabled?: boolean;
    color?: string;
    speaking?: boolean;
    bandwidth?: number;
    disconnected?: boolean;
}

export interface AttendeeUpdateRequest {
    audioEnabled?: boolean;
    videoEnabled?: boolean;
    videoMirrored?: boolean;
    screenShareEnabled?: boolean;
    videoOrientation?: 'landscape' | 'portrait';
    accepted?: boolean;
    declined?: boolean;
    missed?: boolean;
}

export interface InviteeUpdateRequest {
    personId?: string;
    admitted?: boolean;
    rejected?: boolean;
}

export interface CreateMeetingRequest {
    channelId?: string;
    people?: MeetingPerson[];
    name?: string;
    id?: string;
    scheduledStart?: Date;
    scheduledEnd?: Date;
    comments?: string;
    locationName?: string;
    timezone?: string;
    recurring?: MeetingRecurrence;
}

export interface MeetingToken {
    token: string;
    accountId: string;
    meetingId: string;
    personId: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    company?: string;
}

export interface AudioData {
    id?: string;
    channelId?: string;
    wilma?: {
        id: string;
        cameraId?: string;
    };
    accountId?: string;
    personId?: string;
    allowPtt?: boolean;
    frequency?: number;
    channels?: number;
    pcm?: boolean;
    mimeType?: string;
    date?: string;
    fromTeams?: boolean;
}

export const ANONYMOUS_STORAGE_KEY = 'anonymous-user';

export enum AnnotateType {
    Line = 'line',
    Undo = 'undo',
    Redo = 'redo',
    Clear = 'clear'
}
export interface AnnotateDraw {
    type: AnnotateType;
    drawId?: string;
}

export interface AnnotateLine extends AnnotateDraw {
    type: AnnotateType.Line;
    start: boolean;
    stop: boolean;
    color: string;
    x: number;
    y: number;
}

export interface MeetingAnnotateUpdate {
    meetingId: string;
    attendeeId: string;
    draws: AnnotateDraw[];
    replay?: boolean;
}

export enum MediaEndedReason {
    Closed = 'closed',
    Disconnected = 'disconnected'
}
