import { DatePipe } from '@angular/common';
import { ErrorHandler, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';

import { DEFAULT_COMPONENT_ICONS } from '@azure/communication-react';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { registerIcons } from '@fluentui/react';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { AppComponent } from './app.component';
import { AppCoreModule } from './core/core.module';
import { ServiceLocator } from './service-locator';
import { SharedModule } from './shared/shared.module';

export function errorHandlerFactory() {
    return new BugsnagErrorHandler();
}

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        AppCoreModule,
        SharedModule,
        ServiceWorkerModule.register('custom-service-worker.js', { enabled: true }),
    ],
    providers: [{ provide: ErrorHandler, useFactory: errorHandlerFactory }, DatePipe],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(injector: Injector) {
        ServiceLocator.injector = injector;
        initializeIcons();
        registerIcons({ icons: DEFAULT_COMPONENT_ICONS });
    }
}
