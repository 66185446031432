import { Injector } from '@angular/core';

import { AccountServiceStub } from '@weavix/services/src/account.service';
import { AlertMaydayServiceStub } from '@weavix/services/src/alert-mayday.service';
import { CompanyServiceStub } from '@weavix/services/src/company.service';
import { ConfinedSpaceServiceStub } from '@weavix/services/src/confined-space.service';
import { FacilityServiceStub } from '@weavix/services/src/facility.service';
import { GeofenceServiceStub } from '@weavix/services/src/geofence.service';
import { ItemServiceStub } from '@weavix/services/src/item.service';
import { PersonServiceStub } from '@weavix/services/src/person.service';
import { ProfileServiceStub } from '@weavix/services/src/profile.service';

import { TeamsServiceStub } from '@weavix/services/teams.service';
import { AccountPersonStateService } from './account-person-state.service';
import { AlertStateService } from './alert-state.service';
import { CompanyStateService } from './company-state.service';
import { GeofenceStateService } from './geofence-state.service';
import { ItemStateService } from './item-state.service';
import { PersonStateService } from './person-state.service';
import { StateServiceBase } from './state-base.service';

import { Subject } from 'rxjs';

export enum MapStateType {
    Geofence = 'geofence',
    Item = 'item',
    Person = 'person',
    Company = 'company',
    Alert = 'alert',
}

export type MapStateStartConfig = {
    [key in MapStateType]?: MapStateActions
};

export type MapStates = {
    [key in MapStateType]?: {
        type?: new(...args) => any;
        data?: any[];
        updateSub$?: Subject<any[]>;
    }
};

export interface MapStateActions {
    load: boolean;
    update: boolean;
}

export abstract class MapStateServiceStub {
    private allStateServices: Map<MapStateType, StateServiceBase> = new Map();
    private servicesToLoad: StateServiceBase[];
    private servicesToUpdate: StateServiceBase[];

    public geofence: GeofenceStateService;
    public item: ItemStateService;
    public person: PersonStateService;
    public company: CompanyStateService;
    public alert: AlertStateService;

    private accountService: AccountServiceStub;
    private facilityService: FacilityServiceStub;
    private profileService: ProfileServiceStub;
    private geofenceService: GeofenceServiceStub;
    private itemService: ItemServiceStub;
    private confinedSpaceService: ConfinedSpaceServiceStub;
    private personService: PersonServiceStub;
    private companyService: CompanyServiceStub;
    private alertMaydayService: AlertMaydayServiceStub;
    private teamsService: TeamsServiceStub;

    constructor(
        injector: Injector
    ) {
        this.accountService = injector.get(AccountServiceStub);
        this.facilityService = injector.get(FacilityServiceStub);
        this.profileService = injector.get(ProfileServiceStub);
        this.geofenceService = injector.get(GeofenceServiceStub);
        this.itemService = injector.get(ItemServiceStub);
        this.confinedSpaceService = injector.get(ConfinedSpaceServiceStub);
        this.personService = injector.get(PersonServiceStub);
        this.companyService = injector.get(CompanyServiceStub);
        this.alertMaydayService = injector.get(AlertMaydayServiceStub);
        this.teamsService = injector.get(TeamsServiceStub);
    }

    /**
     * Starts state services. Loads data and subscribes to mqtt events
     */
    async start(component: any, config?: MapStateStartConfig, tags?: string[], services?: MapStates, setFacilityId?: string) {
        this.initializeServices(services);
        const accountId = await this.accountService.getAccountId();
        const facilityId: string = setFacilityId !== undefined ? setFacilityId : (await this.facilityService.getCurrentFacility())?.id;

        this.servicesToUpdate = this.getServicesFromConfig(config, a => a.update);
        await Promise.all(this.servicesToUpdate.map(s => s.startSubscriptions(component, accountId, facilityId, tags)));

        this.servicesToLoad = this.getServicesFromConfig(config, a => a.load);
        await Promise.all(this.servicesToLoad.map(s => s.loadData(component, accountId, facilityId, tags)));
    }

    /**
     * Initialize state services.
     */
    initializeServices(services?: MapStates) {
        this.geofence = new GeofenceStateService(this.geofenceService, this.profileService, this.teamsService);
        this.item = new ItemStateService(this.itemService, this.confinedSpaceService, this.profileService, this.teamsService);
        this.person = services?.person?.type
            ? new services.person.type(this.personService, this.facilityService, this.profileService)
            : new AccountPersonStateService(this.personService, this.facilityService, this.profileService);
        this.company = new CompanyStateService(this.companyService);
        this.alert = new AlertStateService(this.alertMaydayService, this.profileService, this.teamsService);

        this.allStateServices.set(MapStateType.Geofence, this.geofence);
        this.allStateServices.set(MapStateType.Item, this.item);
        this.allStateServices.set(MapStateType.Person, this.person);
        this.allStateServices.set(MapStateType.Company, this.company);
        this.allStateServices.set(MapStateType.Alert, this.alert);
    }

    private getServicesFromConfig(config?: MapStateStartConfig, filter?: (a: MapStateActions) => boolean) {
        const services = config
            ? Object.keys(config).filter((k: MapStateType) => !filter || filter(config[k])).map((k: MapStateType) => this.allStateServices.get(k))
            : Array.from(this.allStateServices.values());

        return services;
    }

    async restart(component, config?: MapStateStartConfig, tags?: string[]) {
        await this.stop();
        await this.start(component, config, tags);
    }

    /**
     * Subscribes to mqtt events
     */
    async startSubscriptions(component: any, tags?: string[]) {
        const accountId = await this.accountService.getAccountId();
        const facility = await this.facilityService.getCurrentFacility();
        await Promise.all(this.servicesToUpdate.map(x => x.startSubscriptions(component, accountId, facility?.id, tags)));
    }

    /**
     * Unsubscribes from mqtt events
     */
    async stopSubscriptions() {
        await Promise.all(this.servicesToUpdate.map(x => x.stopSubscriptions()));
    }

    /**
     * Stops state services. Unsubscribes from mqtt events and clears data
     */
    async stop() {
        await Promise.all(Array.from(this.allStateServices.values()).map(x => x.stop()));
    }
}
