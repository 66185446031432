<ng-container [ngSwitch]="notification.type">
    <ng-container *ngSwitchDefault [ngTemplateOutlet]="responded"></ng-container>
</ng-container>

<ng-template #responded>
    <div class="notification-card-container" [ngClass]="{selected}" [class.teams]="teamsApp" (click)="openNotificationModal($event)">
        <ng-container [ngSwitch]="teamsApp">
            <ng-container *ngSwitchCase="true">
                <div class="flexColumn w100p">
                    <div class="notification-details w100p flexRow">
                        <div class="left-icon-container" [style]="iconBackgroundColor">
                            <app-icon [icon]="icon" *ngIf="notification.type === NotificationCenterType.FacilityAlert"></app-icon>
                            <app-avatar-person
                                [personId]="notification.senderId"
                                *ngIf="notification.type !== NotificationCenterType.FacilityAlert"
                                size="38"
                            ></app-avatar-person>
                        </div>
                        <div class="middle-container flexColumn fg1 gap2">
                            <div class="title-bar">
                                <div class="title">{{ notification.titleTranslations?.[language] ?? notification.title }}</div>
                                <div class="subtitle">{{ notification.created | timeAgo }}</div>
                            </div>
                            <div class="subtitle-bar">
                                <div class="message">{{ notification.translations?.[language] ?? notification.translatedMessage }}</div>
                            </div>
                        </div>
                        <div *ngIf="!notification.read" class="right-indicator"></div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="false">
                <div class="left-icon-container" [style]="iconBackgroundColor">
                    <app-icon [icon]="icon" [color]="notification.icon?.color" *ngIf="notification.type === NotificationCenterType.FacilityAlert"></app-icon>
                    <app-avatar-person
                        [personId]="notification.senderId"
                        *ngIf="notification.type !== NotificationCenterType.FacilityAlert"
                        size="35"
                    ></app-avatar-person>
                </div>
                <div class="middle-container">
                    <app-icon faIcon="fas fa-times" fontSize="18px" (click)="removeNotificationClick($event, notification.id)" class="delete-icon"></app-icon>
                    <div class="title-bar">
                        <div class="title">{{ notification.titleTranslations?.[language] ?? notification.title }}</div>
                    </div>
                    <div class="subtitle-bar">
                        <div class="subtitle">{{ notification.created | timeAgo }}</div>
                    </div>
                    <div class="body" *ngIf="notification.translations?.[language] ?? notification.translatedMessage">
                        <div class="message">{{ notification.translations?.[language] ?? notification.translatedMessage }}</div>
                    </div>
                </div>
                <div class="right-btn-container acknowledge" *ngIf="!notification.read">
                    <button class="acknowledge-btn" (click)="acknowledgeClick($event)">
                        <app-icon faIcon="fas fa-check" fontSize="18px"></app-icon>
                        <span style="font-size: 8px;">{{ 'notifications.acknowledge' | translate }}</span>
                    </button>
                </div>
                <div class="right-icon-container" *ngIf="notification.read" [style]="iconBackgroundColor">
                    <app-icon faIcon="fas fa-check" fontSize="18px"></app-icon>
                    <span style="font-size: 8px;">{{ 'notifications.acknowledged' | translate }}</span>
                </div>
            </ng-container>
        </ng-container>
        
    </div>
</ng-template>
