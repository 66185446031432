<app-modal [modalInput]="notificationModalInput" *ngIf="notificationModalInput?.isOpen" [alwaysOpen]="fromActivity" (modalCloseOutput)="onModalOutput($event)">
    <div class="notification-container"  [class.teams]="teamsApp">
        <span class="notification-level" *ngIf="currentNotification.alertLevel && teamsApp">{{'notifications.levels.' + currentNotification.alertLevel | translate}}</span>
        <span class="notification-level" *ngIf="teamsApp && type === NotificationCenterType.MissedPage">{{'page.page-missed' | translate}}</span>
        <span class="notification-level" *ngIf="teamsApp && type === NotificationCenterType.MissedCall">{{'calling.missed-call' | translate}}</span>
        <span class="notification-title" *ngIf="type === NotificationCenterType.FacilityAlert">{{currentNotification.titleTranslations?.[language] ?? currentNotification.title}}</span>
        <div *ngIf="type !== NotificationCenterType.FacilityAlert" class="page-person-container">
            <app-avatar-person [personId]="sender.id" [size]="70" style="padding-left: 5px"></app-avatar-person>
            <div class="page-person-name">
                <div class="person">{{ sender.fullName ? (sender.fullName | titlecase) : null }}</div>
                <div class="company">{{ sender.companyName ? (sender.companyName | titlecase) : null }}</div>
                <div class="attribute-container" style="font-size: 12px;">
                    <span class="attribute-title">{{'notifications.modal-sent' | translate}}</span>
                    <span class="attribute-value">{{formatDate(currentNotification.created)}}</span>
                </div>
            </div>
        </div>
        <div class="attributes-container">
            <div class="attribute-container" *ngIf="sender && (type === NotificationCenterType.FacilityAlert)">
                <span class="attribute-title">{{'notifications.modal-sender' | translate}}</span>
                <span class="attribute-value">{{sender.fullName}}</span>
            </div>
            <div class="attribute-container" *ngIf="type === NotificationCenterType.FacilityAlert">
                <span class="attribute-title">{{'notifications.modal-sent' | translate}}</span>
                <span class="attribute-value">{{formatDate(currentNotification.created)}}</span>
            </div>
        </div>
        <span class="facility-name" *ngIf="facility?.name">
            <div class="map-icon">
                <app-icon class="fas fa-map-marker-alt"></app-icon>
            </div>
            {{facility.name}}
        </span>
        <div class="message" *ngIf="type === NotificationCenterType.FacilityAlert">{{currentNotification.translations?.[language] ?? currentNotification.translatedMessage ?? currentNotification.message}}</div>
        <div class="map-container" *ngIf="type === NotificationCenterType.FacilityAlert">
            <app-simple-map *ngIf="currentNotification.location" [location]="currentNotification.location" [icon]="currentNotification.icon" height="154px"></app-simple-map>
        </div>
    </div>
</app-modal>
