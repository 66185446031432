import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { AutoUnsubscribe, Utils } from '@weavix/components/src/utils/utils';
import { Facility } from '@weavix/models/src/facility/facility';
import { Modal, ModalActionType } from '@weavix/models/src/modal/modal';
import { TranslationServiceStub } from '@weavix/services/src/translation.service';
import { channelsContext } from 'crews/app/models-mobx/channels-store/channels-store';
import { User, usersContext } from 'crews/app/models-mobx/users-store/users-store';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Notification, NotificationCenterAcknowledgement, NotificationCenterType } from 'weavix-shared/models/notification.model';
import { ChannelService } from 'weavix-shared/services/channel.service';
import { FacilityService } from 'weavix-shared/services/facility.service';
import { closeNotificationDropdown$, updateNotificationIcon$ } from '../notifications.component';
import { NotificationCenterService } from '../services/notification-center.service';

export const openNotification$: BehaviorSubject<string | Notification> = new BehaviorSubject(null);
export const closeNotification$: BehaviorSubject<number> = new BehaviorSubject(null);

@AutoUnsubscribe()
@Component({
    selector: 'app-notification-modal',
    templateUrl: './notification-modal.component.html',
    styleUrls: ['./notification-modal.component.scss', './notification-modal-teams.component.scss'],
})
export class NotificationModalComponent implements OnInit {
    @Input() fromActivity: boolean = false;

    public readonly NotificationCenterType = NotificationCenterType;

    currentNotification: Notification;
    notificationModalInput: Modal;
    facility: Facility;
    sender: User;
    type: NotificationCenterType;
    language: string;
    teamsApp: boolean = environment.teamsApp;

    constructor(
        private datePipe: DatePipe,
        private facilityService: FacilityService,
        private notificationCenterService: NotificationCenterService,
        private translationService: TranslationServiceStub,
        private channelService: ChannelService,
    ) { }

    ngOnInit(): void {
        Utils.safeSubscribe(this, openNotification$).subscribe(async next => {
            if (typeof next === 'string') {
                next = await this.notificationCenterService.getById(this, next);
            }
            if (next) {
                this.type = next.type;
                this.language = this.translationService.getLocale();
                this.currentNotification = next;
                await this.getSender();
                await this.getFacility();
                this.openModal();
            }
        });
    }

    async getSender() {
        try {
            this.sender = usersContext.getDefault().get(this.currentNotification.senderId);
        } catch (e) {
            console.warn('Could not get notification sender', e);
        }
    }

    async getFacility() {
        try {
            this.facility = await this.facilityService.getWithAccountId(this, this.currentNotification.facilityId, this.currentNotification.accountId);
        } catch (e) {
            console.warn('Could not get notification facility', e);
        }
    }

    async onModalOutput(event) {
        if (event === ModalActionType.submit) {
            this.acknowledge();
        } else if (event === ModalActionType.delete) {
            if (!this.currentNotification.read) this.acknowledge();
            const channel = await channelsContext.getDefault().createTemporary([this.currentNotification.senderId]);
            this.channelService.selectChannel.next(channel);
            closeNotificationDropdown$.next(Date.now());
        }
        if (!this.fromActivity) this.closeModal();
    }

    async acknowledge() {
        this.currentNotification.acknowledgement = NotificationCenterAcknowledgement.Acknowledged;
        this.currentNotification.read = true;
        this.notificationCenterService.updateFacilityAlert(this, this.currentNotification.dataId, this.currentNotification.accountId, { read: true });
        await this.notificationCenterService.update(this, this.currentNotification.id, { read: true, acknowledgement: NotificationCenterAcknowledgement.Acknowledged });
        updateNotificationIcon$.next(Date.now());
    }

    openModal() {
        this.notificationModalInput = {
            isOpen: true,
            content: true,
            fitContent: true,
            maxWidth: '50%',
            minWidth: this.type === NotificationCenterType.FacilityAlert ? '40%' : '25%',
            maxHeight: '90%',
            actions: {
                submit: {
                    show: !this.currentNotification?.read,
                    textKey: 'notifications.modal-acknowledge',
                    buttonClass: 'blue-lt',
                    hidden: () => this.currentNotification.read,
                },
                delete: {
                    show: this.type !== NotificationCenterType.FacilityAlert && !this.fromActivity,
                    textKey: 'crews.channels.channel-view',
                    buttonClass: !this.currentNotification?.read ? 'primary' : 'blue-lt',
                },
                cancel: {
                    show: !this.fromActivity && this.teamsApp,
                    textKey: 'notifications.modal-close',
                },
            },
            header: this.teamsApp ?
                null :
                {
                    textKey: this.type === NotificationCenterType.FacilityAlert ?
                        `notifications.levels.${this.currentNotification.alertLevel}` :
                        this.type === NotificationCenterType.MissedPage ?
                            'page.page-missed' :
                            'calling.missed-call',
                    textAlignment: 'left',
                    showSeparator: true,
                },
            actionsAlignment: this.teamsApp ?
                null :
                'right',
        };
    }

    closeModal() {
        this.notificationModalInput = null;
        this.currentNotification = null;
        this.sender = null;
        this.facility = null;
        closeNotification$.next(Date.now());
    }

    formatDate() {
        const current = new Date();
        const dateTime = new Date(this.currentNotification.created);
        const sameDay = current.getDate() === dateTime.getDate() &&
            current.getMonth() === dateTime.getMonth() &&
            current.getFullYear() === current.getFullYear();
        if (sameDay) return this.datePipe.transform(dateTime, 'h:mm a');
        else return this.datePipe.transform(dateTime, 'MM/dd/yyy h:mm a');
    }
}
