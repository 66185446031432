import { Avatar } from '@weavix/models/src/avatar/avatar';
import { css } from '../utils/css';

export function buildAvatar(height: number, width?: number, editable: boolean = false, img: string = null, altText?: string, outlineColor?: string): Avatar {
    return {
        height,
        width,
        img,
        altText,
        editable,
        outlineColor
    };
}

export function defaultAvatar(): Avatar {
    return {
        height: 110,
        width: 110,
        hasBorders: true,
        borderWidth: 2,
        padding: 4,
        placeholder: {
            icon: 'fas fa-user',
            iconColor: css.colors.AVATAR_ICON,
            backgroundColor: css.colors.AVATAR_BACKGROUND
        }
    };
}

export function getAdcAvatar(account, avatarSize): Avatar {
    return {
        height: avatarSize,
        width: avatarSize,
        name: account?.name,
        img: account?.image ?? null,
        outlineColor: account?.color ?? css.colors.WHITE_65P,
    };
}
