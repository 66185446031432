import { BadgeEvent } from '@weavix/models/src/badges/event';
import { Person } from '@weavix/models/src/person/person';
import { ObservableSubscribe, TopicPayload } from '@weavix/mqtt/src/mqtt-service';
import * as moment from 'moment';

export abstract class PersonServiceStub {
    static readonly ACTIVE_DEVICE_TIME_MINUTES = 30;
    static readonly INACTIVE_DEVICE_TIME_DAYS = 1;
    static baseUrl = '/core/people';
    static userUrl = '/core/users';
    static facilityPeopleUrl = '/core/facility-people';
    static cacheCollection = 'people';
    
    abstract getUserFromStore(id: string);
    abstract getPeople(component, tags?: string[], cache?: boolean): Promise<Person[]>;
    abstract getFacilityPeople(component, facilityId: string, tags?: string[]): Promise<Person[]>;
    abstract subscribePersonUpdates(c: any): ObservableSubscribe<TopicPayload<any>>;
    abstract subscribeBadgeUpdates(c: any, accountId?: string, id?: string): ObservableSubscribe<TopicPayload<any>>;
    abstract subscribeFacilityBadgeUpdates(c: any, facilityId: string): ObservableSubscribe<TopicPayload<any>>;

    static hasActiveDevice(p: Person) {
        return !!p.badge?.location // has a location
            && !p.badge?.offline
            && !!p.badge.facilities.length;
    }

    static hasActivatedInactiveDevice(p: Person, atTime?: number) {
        return p.badge?.location // has a location
            && !p.badge?.offline && (!p.badge?.date
                || new Date(p.badge?.date).getTime() < moment(atTime).subtract(this.INACTIVE_DEVICE_TIME_DAYS, 'days').valueOf()); // outside inactive time
    }

    static isActiveEvent(e: BadgeEvent, atTime?: number) {
        return new Date(e?.date ?? 1).getTime() > moment(atTime).subtract(this.ACTIVE_DEVICE_TIME_MINUTES, 'minutes').valueOf(); // within active time
    }

    static personIsInFacility(person: Person, facilityId: string): boolean {
        return (person?.badge?.facilities || []).some(f => f.id === facilityId) && PersonServiceStub.hasActiveDevice(person);
    }
}
