import { css } from '@weavix/domain/src/utils/css';
import { maybeDecimal } from '../pipes/maybe-decimal.pipe';


export enum GasUnit {
    ppm = 'ppm',
    percent = 'percent'
}

export enum GasType {
    o2 = 'o2',
    co = 'co',
    h2s = 'h2s',
    nh3 = 'nh3',
    lel = 'lel'
}

export interface GasSetting {
    gas: GasType;
    unit: GasUnit;
    thresholds: GasThresholds;
}

export interface GasThresholds {
    lowerPanic?: number;
    lowerWarn?: number;
    upperWarn: number;
    upperPanic: number;
}

export interface SensorGasItem {
    name: string;
    id: SensorGasCode;
}

export interface GasMonitorReading {
    id?: string;
    serialNumber?: string;
    status?: InstrumentStatus;
    readings?: GasSensorReading[];
    updated?: Date;
    account?: string;
}

export interface GasSensorReading {
    status: SensorStatus;
    code: SensorGasCode;
    value?: number;
    unit?: SensorUnit;
    time?: number;
}

export enum SensorUnit {
    Ppm = 'ppm',
    PercentVolume = '%vol',
    PercentLel = '%LEL'
}

export interface GasSensorStatus extends GasSensorReading {
    date: Date;
}

export enum InstrumentStatus {
    Normal = 'normal',
    Panic = 'panic',
    Shutdown = 'shutdown',
    ManDown = 'man-down',
    PumpFault = 'pump-fault',
    LowBattery = 'low-battery',
    Charging = 'charging',
    Disconnected = 'disconnected',
    Connecting = 'connecting'
}

export enum SensorStatus {
    Normal = 'normal',
    LowAlarm = 'low-alarm',
    HighAlarm = 'high-alarm',
    Fault = 'fault',
    TwaAlarm = 'twa-alarm',
    StelAlarm = 'stel-alarm'
}

export enum SensorGasCode {
    Ammonia = 'NH3',
    Ammonia2 = 'CH3?',
    CarbonDioxide = 'CO2',
    CarbonMonoxide = 'CO',
    Chlorine = 'Cl',
    ChlorineDioxide = 'ClO2',
    Hexane = 'C6H14',
    Hydrogen = 'H',
    HydrogenChloride = 'HCl',
    HydrogenCyanide = 'HCN',
    HydrogenFluoride = 'HF',
    HydrogenSulfide = 'H2S',
    Methane = 'CH4',
    NitricOxide = 'NO',
    NitrogenDioxide = 'NO2',
    Oxygen = 'O2',
    Ozone = 'O3',
    Pentane = 'C5H12',
    Phosgene = 'COCl2',
    Phosphine = 'PH3',
    PhotoIonizationDetector = 'PID',
    Propane = 'C3H8',
    SulfurDioxide = 'SO2',
    UnidentifiedCombustibleGas = 'Comb'
}

export const SENSOR_STATUS_COLORS = {
    [SensorStatus.Normal]: css.colors.GREEN,
    [SensorStatus.LowAlarm]: css.colors.RED,
    [SensorStatus.StelAlarm]: css.colors.RED,
    [SensorStatus.TwaAlarm]: css.colors.RED,
    [SensorStatus.HighAlarm]: css.colors.RED,
    [SensorStatus.Fault]: css.colors.BLACK,
};

export const InstrumentStatusTranslationKey: {[key in InstrumentStatus]?: string} = {
    [InstrumentStatus.ManDown]: 'shared.gas.man-down',
    [InstrumentStatus.Panic]: 'shared.gas.panic',
    [InstrumentStatus.PumpFault]: 'shared.gas.fault',
};

export const getSensorUnit = (unit: SensorUnit) => [SensorUnit.PercentLel, SensorUnit.PercentVolume].includes(unit) ? '%' : unit;
export const getSensorValue = (val: number) => val != null ? maybeDecimal(val) : null;
