import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';

import { WeavixComponentsModule } from '@weavix/components/weavix-components.module';

import { ClusterListComponent } from './components/cluster-list/cluster-list.component';
import { DvrControlsComponent } from './components/dvr-controls/dvr-controls.component';
import { EventLogComponent } from './components/event-log/event-log.component';
import { MapControlComponent } from './components/map-control/map-control.component';
import { EntitySearchComponent } from './components/map/map-filter/entity-search/entity-search.component';
import { FilterRowDisplayComponent } from './components/map/map-filter/filter-row-display/filter-row-display.component';
import { ItemDetailComponent } from './components/map/map-filter/item-detail/item-detail.component';
import { ItemRowDisplayComponent } from './components/map/map-filter/item-row-display/item-row-display.component';
import { MapFilterComponent } from './components/map/map-filter/map-filter.component';
import { PersonDetailComponent } from './components/map/map-filter/person-detail/person-detail.component';
import { PersonRowDisplayComponent } from './components/map/map-filter/person-row-display/person-row-display.component';
import { MapComponent } from './components/map/map.component';
import { SimpleMapComponent } from './components/simple-map/simple-map.component';

@NgModule({
    declarations: [
        ClusterListComponent,
        DvrControlsComponent,
        EntitySearchComponent,
        EventLogComponent,
        FilterRowDisplayComponent,
        ItemDetailComponent,
        ItemRowDisplayComponent,
        MapComponent,
        MapFilterComponent,
        MapControlComponent,
        PersonDetailComponent,
        PersonRowDisplayComponent,
        SimpleMapComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatDatepickerModule,
        MatSliderModule,
        MatTooltipModule,
        WeavixComponentsModule
    ],
    exports: [
        ClusterListComponent,
        DvrControlsComponent,
        EntitySearchComponent,
        EventLogComponent,
        FilterRowDisplayComponent,
        ItemDetailComponent,
        ItemRowDisplayComponent,
        MapComponent,
        MapFilterComponent,
        MapControlComponent,
        PersonDetailComponent,
        PersonRowDisplayComponent,
        SimpleMapComponent
    ]
})
export class WeavixMapsModule { }
