import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Currency, Language } from '@weavix/models/src/translation/translation';
import { TranslationServiceStub } from '@weavix/services/src/translation.service';

import { get } from 'lodash';
import { Observable, ReplaySubject } from 'rxjs';

declare const currencyMap: any; // /assets/currencies.js included in angular.json

@Injectable()
export class TranslationService extends TranslationServiceStub {
    activeLanguage: Language;
    language$ = new ReplaySubject<Language>(1);

    constructor(
        private translate: TranslateService,
    ) {
        super();
    }

    setInitialLanguage(): void {
        const userLang = localStorage.getItem('lang') as Language || Language[this.translate.getBrowserLang()] || Language.en;
        localStorage.setItem('lang', userLang);
        this.translate.setDefaultLang(userLang);
        this.language$.next(userLang);
    }

    setLanguage(lang: string): void {
        localStorage.setItem('lang', lang);
        this.translate.use(lang);
        this.language$.next(lang as Language);
    }

    getLanguage(): string {
        if (!localStorage.getItem('lang')) this.setInitialLanguage();

        return localStorage.getItem('lang');
    }

    getLanguageString(): string {
        return Language[this.getLanguage()];
    }

    getImmediate(key: string, params?, recursive?: boolean): string {
        if (recursive) {
            params = Object.keys(params).reduce((obj, k) => {
                obj[k] = this.getImmediate(params[k]);
                return obj;
            }, {});
        }
        return this.translate.instant(key, params);
    }


    getTranslation(key: string, params?): Promise<string> {
        return this.translate.get(key, params).toPromise();
    }

    getTranslations(keys: string[], params?): Observable<{ [key: string]: string }> {
        return this.translate.get(keys, params);
    }

    getCurrencies(): Currency[] {
        return Object.keys(currencyMap).map((c) => this.getCurrency(c));
    }

    getCurrency(code: string) {
        if (!currencyMap[code]) return null;
        return {
            code: code,
            name: currencyMap[code].name,
            fractionSize: currencyMap[code].fractionSize,
            symbol: get(currencyMap[code], 'symbol.grapheme'),
        };
    }

    numberToCurrency(num: number, code: string): string {
        return new Intl.NumberFormat(this.getLocale(), { style: 'currency', currency: code }).format(num);
    }

    getLocale() {
        return navigator.language || navigator.languages[0] || navigator['browserLanguage'] || navigator['systemLanguage'] || navigator['userLanguage'];
    }
}
