import { BadgeUpdate } from '@weavix/models/src/badges/badge-update';
import { AnyBadgeEvent, EventNameLookUpOverrides, EventUpdateResponse } from '@weavix/models/src/badges/event';
import { MapPerson } from '@weavix/models/src/map/map';
import { Person } from '@weavix/models/src/person/person';
import { merge } from 'lodash';

export abstract class BadgeServiceStub {
    abstract getEvents(component: any, personId: string, from?: Date, to?: Date): Promise<AnyBadgeEvent[]>;
    abstract getPeopleEvents(component: any, facilityId: string, from?: Date, to?: Date): Promise<EventUpdateResponse[]>;
    abstract getAllItemEvents(component: any, facilityId: string, from?: Date, to?: Date): Promise<EventUpdateResponse[]>;
    abstract getItemEvents(component: any, itemId: string, facilityId: string, from?: Date, to?: Date): Promise<AnyBadgeEvent[]>;
    abstract eventNameLookUp(lookUpOverrides?: EventNameLookUpOverrides);

    static updatePersonBadgeDate<T extends Person>(person: T, badgeEvent: BadgeUpdate): MapPerson {
        return merge(person, { badge: badgeEvent });
    }

}
