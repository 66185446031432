import { Injectable } from '@angular/core';
import { IndexedStorage, SyncMqtt, SyncService } from '@weavix/sync';
import { SyncHttp } from '@weavix/sync/src/sync.model';
import { RootStoreService } from 'crews/app/radio/root-store-service';
import { HttpService } from '../http.service';
import { PubSubService } from '../pub-sub.service';
import { WebNativeStorage } from './web-native-storage';

@Injectable({
    providedIn: 'root',
})
export class SyncNgService {
    private constructor(
        httpService: HttpService,
        pubSubService: PubSubService,
        private rootStoreService: RootStoreService,
    ) {
        const syncMqtt: SyncMqtt = {
            subscribe: topic => pubSubService.subscribePassThrough(topic),
        };
        const syncHttp: SyncHttp = {
            get: (path, params) => httpService.get(null, path, params),
            post: (path, body) => httpService.post(null, path, body),
        };
        const storage = new IndexedStorage<any>(new WebNativeStorage(`sync-${SyncService.version}`));
        SyncService.instance = new SyncService(syncHttp, syncMqtt, storage);
    }

    public async onLoggedIn(): Promise<void> {
        const wasLoggedIn = await SyncService.instance.loggedIn();
        await this.rootStoreService.getRootStore().wait();
        if (!wasLoggedIn) {
            await SyncService.instance.waitForModules();
            await this.rootStoreService.getRootStore().wait();
        }
    }

    public async onLoggedOut(): Promise<void> {
        await SyncService.instance.loggedOut(true);
    }
}
