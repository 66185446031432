import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';

import { AnyBadgeEvent, EventType } from '@weavix/models/src/badges/event';
import { GeofenceServiceStub } from '@weavix/services/src/geofence.service';
import { TranslationServiceStub } from '@weavix/services/src/translation.service';

import { GeofenceType } from '../models/geofence-type.model';
import { Topic } from '@weavix/models/src/topic/topic';
import { Geofence } from '../models/weavix-map.model';
import { BoxTree, Fence } from '../utils/polygon';
import { AccountService } from './account.service';
import { CacheContext, HttpService } from './http.service';
import { PubSubService } from './pub-sub.service';

@Injectable()
export class GeofenceService extends GeofenceServiceStub {
    constructor(
        private httpService: HttpService,
        private pubSubService: PubSubService,
        private accountService: AccountService,
        private translationService: TranslationServiceStub,
    ) {
        super();
    }

    private static readonly cacheContext: CacheContext = { collection: 'Geofences', maxAge: 1800000 };

    public static clearCache = () => HttpService.clearCache(GeofenceService.cacheContext.collection);

    async getAll(component: any, facilityId?: string, tags?: string[]) {
        return await this.httpService.get<Geofence[]>(component, '/track/geofences', { tags, facilityId }, GeofenceService.cacheContext);
    }

    async getTypes(component: any) {
        return await this.httpService.get<GeofenceType[]>(component, '/core/geofence-types', null, GeofenceService.cacheContext);
    }

    async add(component: any, geofence: Geofence) {
        return this.httpService.post<Geofence>(component, `/track/geofences`, geofence, GeofenceService.cacheContext);
    }

    async update(component: any, id: string, geofence: Geofence) {
        return this.httpService.put<Geofence>(component, `/track/geofences/${id}`, geofence, GeofenceService.cacheContext);
    }

    async delete(component: any, id: string) {
        return this.httpService.delete<void>(component, `/track/geofences/${id}`, null, GeofenceService.cacheContext);
    }

    subscribeGeofenceUpdates(c: any) {
        return this.pubSubService.subscribe<{[key: string]: Geofence}>(c, Topic.AccountMapGeofences, [this.accountService.getAccountId(), '+']);
    }

    subscribeGeofenceEvents(c: any) {
        return this.pubSubService.subscribe<AnyBadgeEvent>(c, Topic.AccountPersonBadgeEvent, [this.accountService.getAccountId(), '+'])
            .pipe(filter(x => [EventType.GeofenceEnter, EventType.GeofenceExit].includes(x.payload.type)));
    }

    subscribeFacilityEvents(c: any) {
        return this.pubSubService.subscribe<AnyBadgeEvent>(c, Topic.AccountPersonBadgeEvent, [this.accountService.getAccountId(), '+'])
            .pipe(filter(x => [EventType.FacilityEnter, EventType.FacilityExit].includes(x.payload.type)));
    }

    // returns split line geofence event string for tables
    public getGeofenceEventString(eventType: EventType.GeofenceEnter | EventType.GeofenceExit | EventType.GeofenceDisconnect, name: string): string {
        const prefix = () => {
            switch (eventType) {
                case EventType.GeofenceEnter : return `${this.translationService.getImmediate('shared.geofence.enter')} <br />`;
                case EventType.GeofenceExit : return `${this.translationService.getImmediate('shared.geofence.exit')} <br />`;
                case EventType.GeofenceDisconnect : return `${this.translationService.getImmediate('shared.geofence.disconnect')} <br />`;
            }
        };
        return `${prefix()} ${name}`;
    }

    transformToBoxTrees(geofences: Geofence[]) {
        const trees: BoxTree<Fence, any> = new BoxTree<Fence, any>((obj) => obj.points, 0);
        geofences.forEach(l => {
            const obj: Fence = {
                id: l.id,
                points: l.vertices,
            };
            trees.add(obj);
        });
        return trees;
    }
}
