import { Communication } from '@weavix/models/src/channel/channel-message';
import { Subscription } from 'rxjs';

export abstract class CommunicationStateServiceStub {
    communications: {[id: string]: Communication[]} = {};

    weekFrom: Date;
    from: Date;
    to: Date;
    facilityId: string;
    accountId: string;

    subscription: Subscription;
    promise: Promise<Communication[]>;
    
    abstract subscribe(component, facilityId: string);
    abstract initDvrRange(component: any, from: Date, to: Date, timestamp: number, facilityId?: string);
}
