// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="../../../../node_modules/@types/segment-analytics/index.d.ts" />
import { Injectable } from '@angular/core';

import { AnalyticsServiceBase, StProperties } from 'weavix-shared/services/analytics.service';


export enum StObject {
    AccountSwitcher = 'AccountSwitcher',
    AnonymousUser = 'AnonymousUser',
    Channel = 'Channel',
    ChannelMessage = 'ChannelMessage',
    ChannelNotificationSettings = 'ChannelNotificationSettings',
    ChannelTagFilter = 'ChannelTagFilter',
    Meeting = 'Meeting',
    MapPlaybackTimeSelection = 'MapPlaybackTimeSelection',
    MessageAttachment = 'MessageAttachment',
    Tag = 'Tag',
    UserProfile = 'UserProfile',
    WilmaDetailPtt = 'WilmaDetailPtt',
    WilmaDetailSiren = 'WilmaDetailSiren',
    WilmaDetailBan = 'WilmaDetailBan',
    WilmaWidgetExpand = 'WilmaWidgetExpand',
    WilmaWidgetMap = 'WilmaWidgetMap',
    WilmaWidgetSiren = 'WilmaWidgetSiren',
    UserAvatar = 'UserAvatar',
}

export enum StAction {
    Admitted = 'Admitted',
    Canceled = 'Canceled',
    Cleared = 'Cleared',
    Clicked = 'Clicked',
    Copied = 'Copied',
    Created = 'Created',
    Deleted = 'Deleted',
    Disabled = 'Disabled',
    Enabled = 'Enabled',
    Joined = 'Joined',
    Left = 'Left',
    Messaged = 'Messaged',
    Moved = 'Moved',
    Rejected = 'Rejected',
    Removed = 'Removed',
    Resized = 'Resized',
    Set = 'Set',
    Subscribed = 'Subscribed',
    Toggled = 'Toggled',
    Unsubscribed = 'Unsubscribed',
    Updated = 'Updated',
    Uploaded = 'Uploaded',
}

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService extends AnalyticsServiceBase {
    static track(object: StObject, action: StAction, component: string, data: StProperties = {}): void {
        super.track(object, action, component, data);
    }
}
