import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AutoUnsubscribe, Utils } from '@weavix/components/src/utils/utils';
import { buildAvatar } from '@weavix/domain/src/avatar/avatar';
import { css } from '@weavix/domain/src/utils/css';
import { Avatar } from '@weavix/models/src/avatar/avatar';
import { TranslationServiceStub } from '@weavix/services/src/translation.service';
import { environment } from 'environments/environment';
import { AppIcon, FontelloIcon } from 'weavix-shared/models/icon.model';
import { Notification, NotificationCenterAcknowledgement, NotificationCenterType } from 'weavix-shared/models/notification.model';
import { ProfileService } from 'weavix-shared/services/profile.service';
import { closeNotification$, openNotification$ } from '../notification-modal/notification-modal.component';
import { NotificationCenterService } from '../services/notification-center.service';

@AutoUnsubscribe()
@Component({
    selector: 'app-notification-card',
    templateUrl: './notification-card.component.html',
    styleUrls: ['./notification-card.component.scss', './notification-card-teams.component.scss'],
})
export class NotificationCardComponent implements OnInit, OnChanges {

    constructor(
        private notificationCenterService: NotificationCenterService,
        private profileService: ProfileService,
        private translationService: TranslationServiceStub,
    ) {
    }

    public readonly NotificationCenterType = NotificationCenterType;

    @Input() notification: Notification;

    @Output() updateUnreadCount = new EventEmitter<void>();
    @Output() removeNotification = new EventEmitter<string>();
    @Output() reloadChannels = new EventEmitter<string>();

    teamsApp = environment.teamsApp;

    avatar: Avatar;
    requestedById: string;
    selected: boolean = false;
    language: string;

    ngOnChanges(changes: SimpleChanges): void {
    }

    async ngOnInit() {
        this.language = this.translationService.getLocale();
        const myPersonId = (await this.profileService.getUserProfile(this)).id;
        this.avatar = buildAvatar(60, 60);
        this.requestedById = this.notification.dataId.people?.find(p => p !== myPersonId);
        Utils.safeSubscribe(this, closeNotification$).subscribe(() => {
            this.selected = false;
        });
    }

    get icon(): AppIcon {
        const faIcon = this.notification?.icon?.value ? `fas fa-${this.notification?.icon?.value}` : null;
        return { faIcon, fntlIcon: FontelloIcon.PersonConnectionAdd, fontSize: this.teamsApp ? '18px' : '30px' };
    }

    get iconBackgroundColor() {
        if (this.notification.type !== NotificationCenterType.FacilityAlert) return null;
        return this.notification.read ? { 'background-color': css.colors.GRAY_LT } : { 'background-color': this.notification.icon?.color };
    }

    public async removeNotificationClick(e: Event, id: string) {
        e.stopPropagation();
        this.removeNotification.emit(id);
    }

    public async openNotificationModal(e: Event) {
        e.stopPropagation();
        this.selected = true;
        openNotification$.next(this.notification);
    }

    public async acknowledgeClick(e: Event) {
        e.stopPropagation();
        this.notification.acknowledgement = NotificationCenterAcknowledgement.Acknowledged;
        this.notification.read = true;
        if (this.notification.type === NotificationCenterType.FacilityAlert) this.notificationCenterService.updateFacilityAlert(this, this.notification.dataId, this.notification.accountId, { read: true });
        await this.notificationCenterService.update(this, this.notification.id, { read: true, acknowledgement: NotificationCenterAcknowledgement.Acknowledged });
        this.updateUnreadCount.emit();
    }
}
