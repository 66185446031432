import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { AutoUnsubscribe, Utils } from '@weavix/components/src/utils/utils';
import { TranslationServiceStub } from '@weavix/services/src/translation.service';
import { CardType } from 'crews/app/shared/card-select/card-select.model';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Account } from 'weavix-shared/models/account.model';
import { Notification } from 'weavix-shared/models/notification.model';
import { UserProfile } from 'weavix-shared/models/user.model';
import { AccountService } from 'weavix-shared/services/account.service';
import { ProfileService } from 'weavix-shared/services/profile.service';
import { closeNotification$, openNotification$ } from './notification-modal/notification-modal.component';
import { NotificationCenterService } from './services/notification-center.service';
import { NotificationOverlayService } from './services/notification-overlay-service';

export const updateNotificationIcon$: BehaviorSubject<any> = new BehaviorSubject(null);
export const closeNotificationDropdown$: BehaviorSubject<any> = new BehaviorSubject(null);

@AutoUnsubscribe()
@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss', './notifications-teams.component.scss'],
})
export class NotificationsComponent implements OnInit, OnChanges {
    @Input() sidebarOpen: boolean = false;
    @Output() reloadChannels = new EventEmitter<string>();
    public CardType = CardType;

    account: Account;
    userProfile: UserProfile;
    notifications: Notification[] = [];
    hasNotifications: boolean = false;
    notificationCount: number = 0;
    showNotifications: boolean = false;
    ignoreToggle: boolean = false;
    teamsApp = environment.teamsApp;

    constructor(
        private notificationCenterService: NotificationCenterService,
        private notificationOverlayService: NotificationOverlayService,
        private accountService: AccountService,
        private profileService: ProfileService,
        private translationService: TranslationServiceStub,
        private swPush: SwPush,
    ) { }

    ngOnInit() {
        this.swPush.notificationClicks.subscribe(
            async ({ action, notification }) => {
                const data = notification.data;
                if (data.topic !== 'person-connection') return;
                this.toggleOverlay();
            });
        this.getData();
        Utils.safeSubscribe(this, openNotification$).subscribe(() => {
            this.ignoreToggle = true;
        });
        Utils.safeSubscribe(this, closeNotification$).subscribe(() => {
            this.ignoreToggle = false;
        });
        Utils.safeSubscribe(this, closeNotificationDropdown$).subscribe(() => {
            this.showNotifications = false;
        });
        Utils.safeSubscribe(this, updateNotificationIcon$).subscribe(async _date => {
            this.updateNotificationIcon();
        });
    }

    getVisibility(): boolean {
        return this.notificationOverlayService.overlayIsVisible;
    }

    async getData(): Promise<void> {
        this.account = await this.accountService.getAccount();
        this.userProfile = await this.profileService.getUserProfile(this);
        // right now all we care about are Person Connections for 5.0 release
        // the filter is unnecessary when we want all notifications
        this.notifications = await this.notificationCenterService.getAll(this);
        const locale = this.translationService.getLanguage();
        this.notifications.forEach(x => {
            if (x.translations) {
                const found = Object.keys(x.translations).find(txLocale => {
                    return txLocale.substring(0, 2) === locale.substring(0, 2);
                });
                x.translatedMessage = found ? x.translations[found] : x.message;
            } else x.translatedMessage = x.message;
        });
        this.notifications = Utils.sortByDate(this.notifications, 'created', false);
        this.subscribeToNewNotifications();
        this.updateNotificationIcon();
    }

    ngOnChanges(changes: SimpleChanges): void {}

    public updateNotificationIcon(): void {
        this.hasNotifications = this.getUnreadCount() > 0;
        this.notificationCount = this.getUnreadCount();
    }

    public removeNotification(id: string): void {
        this.notificationCenterService.update(this, id, { read: true, removed: new Date() });
    }

    public notificationButtonAction(e: Event) {
        e.stopPropagation();
        this.showNotifications = !this.showNotifications;
    }

    public toggleNotificationsDisplay(): void {
        if (!this.ignoreToggle) {
            this.showNotifications = !this.showNotifications;
        }
    }

    private toggleOverlay(): void {
        this.notificationOverlayService.toggleOverlay();
    }

    public onOverlayCloseClick(e?: Event): void {
        if (e) e.stopPropagation();
        this.notificationOverlayService.hideOverlay();
    }

    public onOverlayBackClick(): void {
        this.onOverlayCloseClick();
    }

    private getUnreadCount(): number {
        return this.notifications.filter(n => !n.read).length;
    }

    private subscribeToNewNotifications() {
        Utils.safeSubscribe(this, this.notificationCenterService.subscribeNotificationCreated(this, this.userProfile.id)).subscribe( async n => {
            const notification = n.payload;
            const existing = this.notifications.find(e => e.id === notification.id);
            const index = this.notifications.findIndex(e => e.id === notification.id);
            if (existing) {
                existing.read = notification.read;
                existing.acknowledgement = notification.acknowledgement;
                existing.removed = notification.removed;
                this.notifications.splice(index, 1);
                if (!existing.removed) this.notifications.push(existing);
            } else {
                if (notification.translations) {
                    const locale = this.translationService.getLanguage();
                    const found = Object.keys(notification.translations).find(txLocale => {
                        return txLocale.substring(0, 2) === locale.substring(0, 2);
                    });
                    notification.translatedMessage = found ? notification.translations[found] : notification.message;
                } else notification.translatedMessage = notification.message;
                this.notifications.push(notification);
            }
            this.notifications = Utils.sortByDate(this.notifications, 'created', false);
            this.updateNotificationIcon();
        });
    }
}
