import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-open-page-template',
    templateUrl: './open-page-template.component.html',
    styleUrls: ['./open-page-template.component.scss'],
})
export class OpenPageTemplateComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {}

}
