import { SyncStorage } from '../sync.model';
import { Subject } from 'rxjs';
import { LazyStorage } from './lazy-storage';
import { removeBy } from '@weavix/utils/src/array';
import { NativeStorage } from './native-storage';

export type ChangeRecord<T> = { partitions?: string[]; value: T; index: string; keys?: string[]; delete?: boolean };

export class IndexedStorage<T extends { id: string }> implements SyncStorage<T> {
    changed$ = new Subject<ChangeRecord<T>>();
    partitionChanged$ = new Subject<boolean>();

    get silent() {
        return this.ignoreChanges;
    }

    set silent(value: boolean) {
        this.ignoreChanges = value;
    }

    private ignoreChanges = false;
    private addedPartitions: { [key: string]: boolean } = {};

    // Stores all objects by their indexed value
    private indexIdObject: { [index: string]: LazyStorage<T> } = {};

    // Keeps track of where a given ID is stored and what partitions it is on
    private idIndex: LazyStorage<string>;
    private idPartitions: LazyStorage<string[]>;

    constructor(
        private storage: NativeStorage,
        private indexFn: (value: T) => string = () => 'root',
        private indexPartition: (index: string) => string = val => val,
    ) {
        this.idIndex = new LazyStorage(this.storage, 'id-index');
        this.idPartitions = new LazyStorage(this.storage, 'id-partitions');
    }

    private getObjects(index: string) {
        const partialIndex = this.indexPartition(index);
        if (!this.indexIdObject[partialIndex]) this.indexIdObject[partialIndex] = new LazyStorage<T>(this.storage, partialIndex);
        return this.indexIdObject[partialIndex];
    }

    async dump(partition: string) {
        console.log(`${partition} has in storage ${Object.keys(await this.idIndex.getMap()).length}`);
    }

    async removePartition(partition: string) {
        delete this.addedPartitions[partition];

        this.idPartitions.hold();
        this.idIndex.hold();

        const partitions = await this.idPartitions.getMap();
        const indexes = await this.idIndex.getMap();
        const indexMap: { [key: string]: { [id: string]: T } } = {};

        for (const id in partitions) {
            if (!partitions[id].includes(partition)) continue;

            const index = indexes[id];
            if (!index) continue;

            const partialIndex = this.indexPartition(index);

            if (!indexMap[partialIndex]) {
                this.getObjects(index).hold();
                indexMap[partialIndex] = await this.getObjects(index).getMap();
            }

            const partitionList = partitions[id];
            const partitionIndex = partitionList?.indexOf(partition);
            if (partitionIndex >= 0) {
                partitionList.splice(partitionIndex, 1);
            }
            if (!partitionList?.length) {
                delete partitions[id];
                delete indexes[id];
                delete indexMap[partialIndex][id];
            }
        }

        this.idIndex.unhold();
        this.idPartitions.unhold();
        Object.keys(indexMap).forEach(index => this.getObjects(index).unhold());

        this.partitionChanged$.next(true);
    }

    async addPartition(partition: string) {
        this.addedPartitions[partition] = true;
        this.partitionChanged$.next(true);
    }

    async clear(partition: string, data: T[]) {
        const listening = this.addedPartitions[partition];
        if (listening) delete this.addedPartitions[partition];

        this.idPartitions.hold();
        this.idIndex.hold();

        const partitions = await this.idPartitions.getMap();
        const indexes = await this.idIndex.getMap();
        const indexMap: { [key: string]: { [id: string]: T } } = {};
        const added: { [key: string]: T } = {};

        for (let i = 0; i < data.length; i++) {
            const value = data[i];
            (value as any).partition = partition;
            added[value.id] = value;

            const index = this.indexFn(value);
            const partialIndex = this.indexPartition(index);
            indexes[value.id] = index;
            if (!indexMap[partialIndex]) {
                this.getObjects(index).hold();
                indexMap[partialIndex] = await this.getObjects(index).getMap();
            }
            indexMap[partialIndex][value.id] = value;

            const partitionList = partitions[value.id];
            if (!partitionList) partitions[value.id] = [partition];
            else if (!partitionList.includes(partition)) partitionList.push(partition);
        }

        for (const id in partitions) {
            if (added[id] || !partitions[id].includes(partition)) continue;

            const index = indexes[id];
            if (!index) continue;

            delete indexes[id];
            const partialIndex = this.indexPartition(index);

            if (!indexMap[partialIndex]) {
                this.getObjects(index).hold();
                indexMap[partialIndex] = await this.getObjects(index).getMap();
            }
            delete indexMap[partialIndex][id];

            const partitionList = partitions[id];
            const partitionIndex = partitionList?.indexOf(partition);
            if (partitionIndex >= 0) {
                if (partitionList.length > 1) partitionList.splice(partitionIndex, 1);
                else delete partitions[id];
            }
        }

        this.idIndex.unhold();
        this.idPartitions.unhold();
        Object.keys(indexMap).forEach(index => this.getObjects(index).unhold());

        if (listening) this.addPartition(partition);
    }

    async get(id: string) {
        const index = await this.idIndex.get(id);
        return index == null ? null : await this.getObjects(index).get(id);
    }

    async getAll(ids: string[]) {
        const indexes = await this.idIndex.getMap();
        const indexMap: { [key: string]: { [id: string]: T } } = {};

        const values: T[] = [];
        for (let i = 0; i < ids.length; i++) {
            const index = indexes[ids[i]];
            if (!index) continue;

            const partialIndex = this.indexPartition(index);
            if (!indexMap[partialIndex]) {
                indexMap[partialIndex] = await this.getObjects(index).getMap();
            }
            const value = indexMap[partialIndex][ids[i]];
            if (value) values.push(value);
        }
        return values;
    }

    getIndex() {
        return this.idIndex.getMap();
    }

    getPartitionsByIds() {
        return this.idPartitions.getMap();
    }

    update(id: string, updateFn: (val: T) => T, getKeys: () => string[], partition?: string) {
        return this.withStorage(this.idIndex, id, index => {
            if (index == null) return;
            const map = this.getObjects(index);
            return this.withStorage(map, id, record => {
                const updated = updateFn(record);
                this.getObjects(index).set(id, updated);
                
                if (!this.ignoreChanges && (!partition || this.addedPartitions[partition])) {
                    return this.withStorage(this.idPartitions, id, partitions => {
                        if (!partitions) partitions = [];
                        const keys = getKeys();
                        this.changed$.next({ partitions, value: updated, index, keys });
                    });
                }
            });
        })
    }

    add(record: T, partition?: string) {
        if (partition) (record as any).partition = partition;
        const index = this.indexFn(record);
        this.getObjects(index).set(record.id, record);
        this.idIndex.set(record.id, index);

        return this.withStorage(this.idPartitions, record.id, partitions => {
            if (!partitions) partitions = [];
            if (partition && !partitions.includes(partition)) {
                partitions.push(partition);
                this.idPartitions.set(record.id, partitions);
            }
            if (!this.ignoreChanges && (!partition || this.addedPartitions[partition])) this.changed$.next({ partitions, value: record, index });
        });
    }

    remove(id: string, partition?: string) {
        return this.withStorage(this.idIndex, id, index => {
            if (index == null) return;
            const map = this.getObjects(index);
            return this.withStorage(map, id, record => {
                return this.withStorage(this.idPartitions, id, partitions => {
                    if (!partitions) partitions = [];
                    if (partition) removeBy(partitions, x => x === partition);
                    if (partitions.length) {
                        this.idPartitions.set(id, partitions);
                    } else {
                        map.remove(id);
                        this.idIndex.remove(id);
                        this.idPartitions.remove(id);
                        if (!this.ignoreChanges && (!partition || this.addedPartitions[partition])) this.changed$.next({ partitions, value: record, delete: true, index });
                    }
                });
            });
        });
    }

    private withStorage<T>(storage: LazyStorage<T>, id: string, fn: (value: T) => any) {
        if (storage.cached) return fn(storage.get(id) as T);
        return (storage.get(id) as Promise<T>).then(fn);
    }

    removeMany(fn: (id: string, index: string) => boolean) {
        const index = this.idIndex.getMap();
        const partitions = this.idPartitions.getMap();
        Object.keys(index).forEach(key => {
            if (fn(key, index[key])) {
                partitions[key]?.forEach(partition => this.remove(key, partition));
            }
        });
    }
}
