<div class="table-container" *ngIf="options && optionsLoaded" [ngStyle]="{'minHeight.px': options?.minHeight ? options?.minHeight : ''}" [class.teams]="teamsService.isTeamsApp()">
    <!-- Table Intro Stuff -->
    <div class="table-intro" [ngClass]="{'hide': options.hideTableIntro}">
        <!-- Title -->
        <div class="title-row">
            <div class="title" [ngClass]="{'light': lightTheme}" *ngIf="options.title">
                <a *ngIf="!!options.back" (click)="onBack()"><i class="fas fa-chevron-left"></i></a>
                {{ options.translate === false ? options.title : translationService.getImmediate(options.title) }}
            </div>

            <div class="title-row-right">
                <div class="row-count mR10" *ngIf="options.rowCount">
                    {{ translationService.getImmediate('dataSource.rows', {count:(slicedRows ? slicedRows.length : 0)}) }}
                </div>
                <div class="row-count mR10" *ngIf="options.duration != null">
                    {{ translationService.getImmediate('dataSource.duration', {seconds:options.duration}) }}
                </div>
                <div class="time-ago-toggle cPointer mR10" *ngIf="options.timeAgoColumnToggle">
                    <mat-slide-toggle
                        color="accent"
                        (change)="handleTimeAgoDateToggle()"
                    ></mat-slide-toggle>
                    <span class="label">{{ translationService.getImmediate('table.relativeTimeLabel') }}</span>
                </div>
    
                <!-- Search -->
                <div class="pL5" *ngIf="showSearch">
                    <input class="search-box bg-white" [(ngModel)]="searchQuery" [placeholder]="translationService.getImmediate('search.search')" (keyup.enter)="submitSearchInput()" (input)="options.search.callback ? onSearchInput() : submitSearchInput()" autocomplete="off">
                </div>
            </div>
        </div>
    </div>

    <!-- Table start -->
    <div class="folder-tree-table-container">
        <div class="main-table-container">
            <div class="header-body-container table">
                <div class="no-data-simple" *ngIf="!hasCurRows() && !options.noData">
                    {{ translationService.getImmediate('noData') }}
                </div>

                <div class="no-data-simple" *ngIf="noSearchResults">
                    {{ translationService.getImmediate('noResults', {query: searchQuery}) }}
                </div>

                <div class="no-data" *ngIf="!hasCurRows() && options.noData && !noSearchResults">
                    <div class="no-data-wrapper">
                        <ng-container *ngIf="options.noData?.isContentTemplate">
                            <ng-content select="[noData]"></ng-content>
                        </ng-container>
                        <ng-container *ngIf="!options.noData?.isContentTemplate">
                            <app-icon *ngIf="options.icon" class="no-data-icon" [icon]="options.icon"></app-icon>

                            <div matTooltip="{{ getPermissionTooltip('table.addItem', folderId) }}" class="icon">
                                <button type="button" *ngIf="options.canAdd !== false" [disabled]="!hasEditPermission(folderId)" class="primary" (click)="addRow()" id="add-table-item-button">
                                    {{ getNoDataMessage() }}
                                </button>
                            </div>
    
                            <div *ngIf="options.canAdd === false" class="icon">
                                {{ translationService.getImmediate('noData') }}
                            </div>
                        </ng-container>
                    </div>
                </div>

                <div *ngIf="teamsService.isTeamsApp() && hasCurRows()" class="table-actions flexRow jcS aiC hide-empty">
                    <div class="table-actions-container static flexRow jcS aiC w100p h100p gap5" *ngIf="staticTableEdits?.length && getSelectedKeyCount() === 0">
                        <div *ngFor="let e of staticTableEdits"
                            class="table-actions-item flexRow jcS aiC gap10"
                            [class.hidden]="e.show && e.show() === false"
                            [ngClass]="{disabled: e.disabled}"
                            (click)="tableEditClick(e)"
                        >
                            <i class="icon" [ngClass]="e.icon"></i><div>{{ translationService.getImmediate(e.title) }}</div>
                        </div>
                    </div>
                    <div class="table-actions-container flexRow jcS aiC w100p h100p gap5" *ngIf="dynamicTableEdits?.length && getSelectedKeyCount()">
                        <div *ngFor="let e of dynamicTableEdits"
                            class="table-actions-item flexRow jcS aiC gap10"
                            [class.hidden]="e.show && e.show() === false"
                            [ngClass]="{disabled: e.disabled}"
                            (click)="tableEditClick(e)"
                        >
                            <i class="icon" [ngClass]="e.icon"></i><div>{{ translationService.getImmediate(e.title) }}</div>
                        </div>
                        <div class="table-actions-item close flexRow jcS aiC gap10" (click)="unselectAll()">
                            <app-icon faIcon="far fa-times"></app-icon>
                        </div>
                    </div>
                </div>

                <div class="table-header-container" *ngIf="hasCurRows() && !options.hideHeader">
                    <div #tableHeader class="table-header-row" [ngStyle]="{'padding-left.px': options?.columnAlignmentOffset ? options.columnAlignmentOffset : 0}"> 

                        <div *ngIf="options.icon" class="table-col checkbox">
                            <button class="invisible"></button>
                        </div>

                        <div *ngIf="options?.select?.showCheckboxes" class="table-col checkbox" >
                            <mat-checkbox (click)="selectAll(); false" [checked]="areAllSelected()"></mat-checkbox>
                        </div>

                        <!-- Headers -->
                        <div *ngFor="let head of options.columns"
                            (click)="(head.sort && head.sort.sortable === true) && onSortClick(head)"
                            class="table-col"
                            [ngClass]="{
                                'pen': (head.sort && head.sort.sortable === false) && !head?.columnHeadTooltip,
                                'cDefault': (head.sort && head.sort.sortable === false) && head?.columnHeadTooltip,
                                'cPointer': head.sort && head.sort.sortable === true
                            }"
                            [ngStyle]="{'min-width.px': head.minWidth, 'max-width.px': head.maxWidth}"                            
                            [matTooltip]="head.columnHeadTooltip"
                        >
                            <div class="col-val">
                                <span *ngIf="teamsService.isTeamsApp()">{{ (head.translate === false ? head.title : translationService.getImmediate(head.title)) }}</span>
                                <span *ngIf="!teamsService.isTeamsApp()">{{ (head.translate === false ? head.title : translationService.getImmediate(head.title)) | uppercase }}</span>
                                <span><i class="sort" [ngClass]="head.sort.sortAsc ? 'fas fa-arrow-up' : 'fas fa-arrow-down'" *ngIf="head.sort && head.sort.selected"></i></span>
                            </div>
                        </div>

                        <div class="table-col edit-col flexRow jcC aiC" *ngIf="maxEdits.length">
                            <button class="invisible"></button>
                        </div>
                    </div>
                </div>

                <ng-container *ngIf="options.paginate">
                    <cdk-virtual-scroll-viewport
                        #tableBody
                        *ngIf="hasCurRows()"
                        itemSize="50"
                        minBufferPx="1000"
                        maxBufferPx="2000"
                        class="table-body-scroll"
                        (scroll)="options.paginate.isLazyLoaded ? lazyloadItems$.next($event) : ''"
                        [ngStyle]="{'height.px': (options.isSubTable && subTableHeight) ? subTableHeight : ''}" 
                    >
                        <div class="row-container pB30 h100p" [ngClass]="{'list-container': true}">
                            <div [ngClass]="{'selected': row.selected, 'cPointer': rowsClickable && row.clickable}"
                                *cdkVirtualFor="let row of slicedRows"
                                (click)="rowsClickable && row.clickable && rowClick(row, false)"
                                (dblclick)="rowsDblClickable && rowClick(row, true)"
                            >
                                <div id="table-row" [ngClass]="{'cDefault': !rowsClickable || !row.clickable}" [routerLink]="getRowLink(row)" [queryParams]="getRouteParams(row)" (click)="rowsClickable && row.clickable && handleAnchorClick($event, row)"
                                    draggable="false" (dragstart)="$event.preventDefault()">
                                    <div class="table-row" [ngStyle]="row.style">
                                        <app-table-row
                                            [row]="row"
                                            [colKey]="colKey"
                                            [moveTooltip]="getPermissionTooltip('table.move', row.isFolder ? row.key : row.original.folderId)"
                                            [dummyEdits]="getDummyEdits(row.edits.length)"
                                            [icon]="row.icon"
                                            (checkAction)="selectRow(row)"
                                            (columnClick)="columnClick($event, row)"
                                            (colPrefixClick)="colPrefixClick($event, row)"
                                            (colPostfixClick)="colPostfixClick($event, row)"
                                            (editClick)="colEditClick($event, row)"
                                            (overrideSelection)="handleOverrideSelection($event, row)"
                                        ></app-table-row>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="options.paginate.isLazyLoaded && isLazyLoading" class="lazy-loader flexRow jcC aiC">
                                <app-logo-spinner [size]="40" [diameter]="20" [slow]="false" [showMessage]="false"></app-logo-spinner>
                            </div>
                        </div>
                    </cdk-virtual-scroll-viewport>
                    <app-paginator *ngIf="!options.paginate.isLazyLoaded" [items]="rows" (changePage)="onChangePage($event)" [pageSize]="options.paginate.pageSize" [totalItems]="totalItems"></app-paginator>
                </ng-container>

                <ng-container *ngIf="!options.rowsExpandContainer && !options.paginate">
                    <cdk-virtual-scroll-viewport
                        #tableBody
                        *ngIf="hasCurRows()"
                        itemSize="50"
                        minBufferPx="1000"
                        maxBufferPx="2000"
                        class="table-body-scroll"
                        (scroll)="checkShowBack($event)"
                        [ngStyle]="{'height.px': (options.isSubTable && subTableHeight) ? subTableHeight : ''}" 
                    >
                        <div class="row-container pB30" [ngClass]="{'list-container': true}">
                            <div [ngClass]="{'selected': row.selected, 'cPointer': rowsClickable && row.clickable}"
                                *cdkVirtualFor="let row of slicedRows"
                                (click)="rowsClickable && row.clickable && rowClick(row, false)"
                                (dblclick)="rowsDblClickable && rowClick(row, true)">
                                <div id="table-row" [ngClass]="{'cDefault': !rowsClickable || !row.clickable}" [routerLink]="getRowLink(row)" [queryParams]="getRouteParams(row)" (click)="rowsClickable && row.clickable && handleAnchorClick($event, row)">
                                    <div class="table-row" [ngStyle]="row.style">
                                        <app-table-row
                                            [row]="row"
                                            [colKey]="colKey"
                                            [moveTooltip]="getPermissionTooltip('table.move', row.isFolder ? row.key : row.original.folderId)"
                                            [dummyEdits]="getDummyEdits(row.edits.length)"
                                            [icon]="row.icon"
                                            (checkAction)="selectRow(row)"
                                            (columnClick)="columnClick($event, row)"
                                            (colPrefixClick)="colPrefixClick($event, row)"
                                            (colPostfixClick)="colPostfixClick($event, row)"
                                            (editClick)="colEditClick($event, row)"
                                            (overrideSelection)="handleOverrideSelection($event, row)"
                                        ></app-table-row>
                                    </div>
                                </div>
                            </div>

                            <!-- Show more button -->
                            <div class="show-more" *ngIf="pageEnd !== curRows.length - 1 && curRows.length > 0">
                                <div class="show-more-left"></div>
                                <div class="show-more-middle">
                                    <button class="text-link show-more-button" (click)="showMore(false)">
                                        {{ translationService.getImmediate('table.show-more') }}
                                    </button>
                                </div>
                                <div class="show-more-right"></div>
                            </div>
                        </div>
                    </cdk-virtual-scroll-viewport>
                </ng-container>

                <!-- Table rows will expand the container they live in rather than scroll themselves -->
                <ng-container *ngIf="options.rowsExpandContainer">
                    <div *ngIf="hasCurRows()">
                        <div class="row-container list-container">
                            <div *ngFor="let row of slicedRows; trackBy: trackByKey"
                                [ngClass]="{'selected': row.selected, 'cPointer': rowsClickable && row.clickable}"
                                (click)="rowsClickable && row.clickable && rowClick(row, false)"
                                (dblclick)="rowsDblClickable && rowClick(row, true)"
                            >
                                <div id="table-row" [ngClass]="{'cDefault': !rowsClickable || !row.clickable}" [routerLink]="getRowLink(row)" [queryParams]="getRouteParams(row)" (click)="rowsClickable && row.clickable && handleAnchorClick($event, row)"
                                    draggable="false" (dragstart)="$event.preventDefault()">
                                    <div class="table-row">
                                        <app-table-row
                                            [row]="row"
                                            [colKey]="colKey"
                                            [moveTooltip]="getPermissionTooltip('table.move', row.isFolder ? row.key : row.original.folderId)"
                                            [dummyEdits]="getDummyEdits(row.edits.length)"
                                            (checkAction)="selectRow(row)"
                                            (columnClick)="columnClick($event, row)"
                                            (colPrefixClick)="colPrefixClick($event, row)"
                                            (colPostfixClick)="colPostfixClick($event, row)"
                                            (editClick)="colEditClick($event, row)"
                                            (overrideSelection)="handleOverrideSelection($event, row)"
                                        ></app-table-row>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <!-- Show more button -->
                        <div class="show-more" *ngIf="pageEnd !== curRows.length - 1 && curRows.length > 0">
                            <div class="show-more-left"></div>
                            <div class="show-more-middle">
                                <button class="text-link show-more-button" (click)="showMore(false)">
                                    {{ translationService.getImmediate('table.show-more') }}
                                </button>
                            </div>
                            <div class="show-more-right"></div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>

        <ng-content></ng-content>

        <!-- Sticky overlay area -->
        <div class="sticky-overlay" *ngIf="options.showStickyButtons !== false">
            <!-- Sticky to top button -->
            <button *ngIf="showBackToTop" class="primary to-top-button" (click)="backToTop()" matTooltip="{{ translationService.getImmediate('table.to-top') }}">
                <i class="far fa-arrow-up"></i>
            </button>
        </div>

    </div>
</div>
