import { Pipe, PipeTransform } from '@angular/core';
import TimeAgo from 'javascript-time-ago';

import { DatePipe } from '@angular/common';
import { TranslationServiceStub } from '@weavix/services/src/translation.service';
import { environment } from 'environments/environment';
import de from 'javascript-time-ago/locale/de';
import en from 'javascript-time-ago/locale/en';
import es from 'javascript-time-ago/locale/es';
import fr from 'javascript-time-ago/locale/fr';
import it from 'javascript-time-ago/locale/it';
import pt from 'javascript-time-ago/locale/pt';
import { HttpService } from '../services/http.service';

TimeAgo.addLocale(en);
TimeAgo.addLocale(es);
TimeAgo.addLocale(pt);
TimeAgo.addLocale(fr);
TimeAgo.addLocale(de);
TimeAgo.addLocale(it);

export function fromNow(date: string | Date): number {
    return new Date().getTime() - new Date(date).getTime() - HttpService.serverTimeDifference;
}

export function timeAgo(date: string | Date, language: string) {
    return new TimeAgo(language).format(new Date(new Date(date).getTime() + HttpService.serverTimeDifference - 1000));
}

@Pipe({ name: 'timeAgo', pure: false })
export class TimeAgoPipe implements PipeTransform {

    private timeAgo: TimeAgo;
    private cache;
    private cacheTime;
    private currentValue: Date | string;

    constructor(private translationService: TranslationServiceStub) {
        this.timeAgo = new TimeAgo(this.translationService.getLanguage());
    }

    transform(inputValue: Date | string, relativeToTimestamp: Date | string | number) {
        const now = Date.now();
        const current = new Date();
        if (!this.cache || now > this.cacheTime || inputValue !== this.currentValue) {
            this.currentValue = inputValue;
            const value = new Date(inputValue); // Don't mutate input

            let offset = HttpService.serverTimeDifference;
            if (relativeToTimestamp) {
                offset = now - (new Date(relativeToTimestamp).getTime() || now);
                if (offset < 0) console.warn('timeago offset less than 0', offset);
            }

            // if relative time is passed in, we add difference between now and the relative time to the value
            // and pass that into TimeAgo, so TimeAgo reads as if it is respecting the relative time
            const valueWithOffset = new Date(value.getTime() + offset);

            const formatForTeamsApp = () => {
                const sameDay = current.getDate() === valueWithOffset.getDate() &&
                    current.getMonth() === valueWithOffset.getMonth() &&
                    current.getFullYear() === current.getFullYear();
                const dp = new DatePipe(this.translationService.getLocale());
                return dp.transform(valueWithOffset, !sameDay ? 'M/d' : 'h:mm aa');
            };

            this.cache = value.getTime()
                ? environment.teamsApp ? formatForTeamsApp() : this.timeAgo.format(valueWithOffset)
                : this.translationService.getImmediate('generics.never');
            if (value.getTime()) this.cacheTime = now + Math.abs(now - value.getTime()) / 60;
        }
        return this.cache;
    }
}
