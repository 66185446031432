import { AirReadingSubmissionEvent, EntryEvent } from '@weavix/models/src/badges/event';
import { CheckedInMapPerson, ConfinedSpaceEvent, ConfinedSpaceItem, EntrantEvent, Entry, ItemPerson } from '@weavix/models/src/item/confined-space';
import { EntryType } from '@weavix/models/src/item/item';
import { ObservableSubscribe, TopicPayload } from '@weavix/mqtt/src/mqtt-service';
import { uniqBy } from 'lodash';
import { ReplaySubject } from 'rxjs';

export abstract class ConfinedSpaceServiceStub {

    abstract subscribeConfinedSpace(c: any, itemId?: string): ObservableSubscribe<TopicPayload<ConfinedSpaceEvent>>;
    public static peopleCheckedInConfinedSpace: {[key: string]: CheckedInMapPerson} = {};
    public static confinedSpaceEvent$: ReplaySubject<ConfinedSpaceItem> = new ReplaySubject(1);
    public static confinedSpaceWilmaEvent$: ReplaySubject<Entry> = new ReplaySubject(1);
    public static confinedSpaceReadingEvent$: ReplaySubject<AirReadingSubmissionEvent> = new ReplaySubject(1);

    public static updateItemWithEvent(event: EntryEvent | EntrantEvent, item: ConfinedSpaceItem) {
        const entry = item.entries.find(x => x.id === event.entryId);
        if (entry) {
            const updatedPermit = event.confinedSpacePermit;
            const permitIndex = entry.activePermits.findIndex(x => x.id.itemId === updatedPermit.id.itemId && x.id.permitNumber === updatedPermit.id.permitNumber);
            if (permitIndex > -1) {
                if (updatedPermit.closed) {
                    entry.activePermits.splice(permitIndex, 1);
                } else {
                    entry.activePermits[permitIndex] = updatedPermit;
                }
            } else {
                entry.activePermits.push(updatedPermit);
            }

            this.confinedSpaceEvent$.next(item);
        }
    }

    public static checkedIn(personId: string) {
        return ConfinedSpaceServiceStub.peopleCheckedInConfinedSpace[personId];
    }

    /**
     * Get total count of all people of entryType across all entries' active permits
     */
    public static getPeopleCount(item: ConfinedSpaceItem, entryType: EntryType) {
        const attendants = ConfinedSpaceServiceStub.getAttendants(item, EntryType.AttendantIn);
        if (attendants.length > 0) {
            return ConfinedSpaceServiceStub.getPeople(item, entryType).length;
        } else {
            return null;
        }
    }

    /**
     * Get flattened list of all people across all entries' active permits
     */
    public static getPeople(item: ConfinedSpaceItem, type: EntryType): ItemPerson[] {
        return (item.entries || []).reduce((a, b) =>
            uniqBy(a.concat(ConfinedSpaceServiceStub.getEntryPeople(b, type)), 'id.personId')
        ,                                  []);
    }

    public static getAttendants(item: ConfinedSpaceItem, type: EntryType): ItemPerson[] {
        return (item.entries || []).reduce((a, b) =>
            a.concat(ConfinedSpaceServiceStub.getEntryAttendants(b, type))
        ,                                  []);
    }

    /**
     * Get list of all attendants for given entry
     */
    public static getEntryAttendants(entry: Entry, type: EntryType): ItemPerson[] {
        return ([entry] || []).reduce((a, b) =>
            a.concat((b.activePermits || []).reduce((c, d) =>
                c.concat((d.attendants || []).reduce((e, f) => {
                    if (f.type === type) e = e.concat(f);
                    return e;
                },                                   []))
            ,                                       []))
        ,                             []);
    }

    /**
     * Get list of all people for given entry
     */
    public static getEntryPeople(entry: Entry, type?: EntryType): ItemPerson[] {
        return ([entry] || []).reduce((a, b) =>
            a.concat((b.activePermits || []).reduce((c, d) =>
                c.concat((d.people || []).reduce((e, f) => {
                    if (type) {
                        if (f.type === type) e = e.concat(f);
                    } else {
                        e = e.concat(f);
                    }
                    return e;
                },                               []))
            ,                                       []))
        ,                             []);
    }
}
