import { computed, createContext, customRef, getAccountStorage, mapProp, model, Model, modelAction, prop, Ref } from '@weavix/mobx';
import { AnalyticsService, StAction, StObject } from 'crews/app/core/services/analytics.service';
import { isEqual, pick, sortBy } from 'lodash';
import { observable, when } from 'mobx';
import { Subject } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { ChannelType } from 'weavix-shared/models/channels.model';
import { ChannelService } from 'weavix-shared/services/channel.service';
import { myUserId } from '../my-profile-store/my-profile-store';
import { Channel } from './channel';
import { ChannelMessage } from './channel-message';

export const channelRef = customRef<Channel>((id: string) => channelsContext.get()?.getChannelById(id));

export const channelsContext = createContext<ChannelsStore>();

@model('ChannelsStore')
export class ChannelsStore extends Model({
    focusedChannel: prop<Ref<Channel> | undefined>(),
    selectedChannel: prop<Ref<Channel> | undefined>(),
    channelsMap: mapProp<string, Channel>(),
}) {
    loaded: Promise<any>;
    notebookChannelId: string;
    newMessage$: Subject<ChannelMessage> = new Subject();
    private temporaryChannelsMap = observable.map<string, Channel>();

    @modelAction
    setFocusedChannel(channelId?: string) {
        this.focusedChannel = channelId ? channelRef(channelId) : undefined;
    }

    @modelAction
    setSelectedChannel(channelId?: string) {
        this.selectedChannel = channelId ? channelRef(channelId) : undefined;
    }

    get selectedChannelId() {
        return this.selectedChannel?.id;
    }

    get focusedChannelId() {
        return this.focusedChannel?.id;
    }

    get getNotebookChannelId() {
        return this.notebookChannelId;
    }

    constructor() {
        super();
        channelsContext.setDefault(this);
        const messages = new Map<string, any>();
        const preferences = new Map<string, any>();
        const channels = new Map<string, any>();

        const getConversation = (channelId: string) => {
            const channel = channels.get(channelId);
            if (!channel || channel.pendingPeople?.includes(channel.partition)) return null;

            const message = messages.get(channelId);
            const preference = preferences.get(channelId);

            const messageCount = this.selectedChannel?.id === channelId ?
                channel.totalMessageCount :
                Math.max(channel?.messageCounts?.[myUserId()] ?? 0, preference?.messageCount ?? 0);

            return {
                ...channel,
                lastMessage: message ? new ChannelMessage(message) : null,
                messageCount,
                notification: preference?.notification,
                favoriteIndex: preference?.favoriteIndex,
                isSnoozed: preference?.isSnoozed,
                updated: preference?.updated ?? channel.updated,
            } as any as Channel;
        };

        const refreshAll = () => {
            const sorted = [...channels.values()].sort((a, b) => {
                return (a.updated ?? '') < (b.updated ?? '') ? -1 : 1;
            });
            const records = sorted
                .map(x => x.id)
                .map(getConversation)
                .filter(x => x) as Channel[];
            const notebookChannel = records.find(x => x.isNotebook);
            if (notebookChannel) {
                this.notebookChannelId = notebookChannel.id;
            } else this.notebookChannelId = null;
            this.setConversations(records);
        };

        const refresh = (channelId: string, keys?: string[]) => {
            const record = getConversation(channelId);
            if (record) this.updatedConversation(record, keys);
            else this.deleteConversation(channelId);
        };

        let lastChannelId = '';
        const emitters = [
            getAccountStorage<any>('channel-messages').Instance()
                .query({
                    index: (key: string) => {
                        const channelId = key.split('|')[0];
                        if (!channelId || channelId === lastChannelId) return false;
                        lastChannelId = channelId;
                        return true;
                    },
                })
                .on('load', values => {
                    values.forEach(message => {
                        messages.set(message.channelId, message);
                    });
                    refreshAll();
                    lastChannelId = '';
                })
                .on('update', ({ value }) => {
                    this.newMessage$.next(value);
                    const existing = messages.get(value.channelId);
                    if (!existing || existing.date <= value.date) {
                        messages.set(value.channelId, value);
                        refresh(value.channelId, ['lastMessage']);
                        lastChannelId = '';
                    }
                }),
            getAccountStorage<any>('channel-preferences').Instance()
                .query()
                .on('load', values => {
                    values.forEach(preference => {
                        preferences.set(preference.id, preference);
                    });
                    refreshAll();
                })
                .on('update', ({ value, keys }) => {
                    preferences.set(value.id, value);
                    refresh(value.id, keys);
                }),
            getAccountStorage<any>('channels').Instance()
                .query()
                .on('load', values => {
                    const start = new Date().getTime();
                    channels.clear();
                    values.forEach(value => channels.set(value.id, value));
                    refreshAll();
                    // eslint-disable-next-line no-console
                    console.log(`Loaded channels ${this.channels.length} in ${new Date().getTime() - start} ms`);
                })
                .on('delete', value => {
                    channels.delete(value.id);
                    refresh(value.id);
                    this.deleteChannelMessages(value.id);
                })
                .on('update', ({ value, keys }) => {
                    channels.set(value.id, value);
                    refresh(value.id, keys);
                }),
        ];
        Object.defineProperty(this, 'loaded', { get: () => Promise.all(emitters.map(x => x.wait())) });
    }

    async deleteChannelMessages(channelId: string) {
        const store = getAccountStorage<any>('channel-messages').Instance();
        store.index.removeMany((id, index) => index.startsWith(channelId));
    }

    @computed
    get channels() {
        return [...this.channelsMap.values()];
    }

    @computed
    get channelsSortedFirstRecent() {
        return sortBy([...this.channels, ...this.temporaryChannelsMap.values()],
            x => x.lastMessage ? x.lastMessage.date.toISOString() : x.created).reverse();
    }

    get unreadCount() {
        return this.channels.reduce((o, v) => o + v.unreadCount, 0);
    }

    @modelAction
    deleteConversation(id: string) {
        this.channelsMap.delete(id);
    }

    @modelAction
    updatedConversation(conversation: Channel, keys?: string[]) {
        if (this.channelsMap.has(conversation.id)) this.channelsMap.get(conversation.id)?.update(keys ? pick(conversation, keys) : conversation);
        else this.channelsMap.set(conversation.id, new Channel(conversation));
    }

    @modelAction
    setConversations(conversations: Channel[]) {
        this.channelsMap.clear();
        conversations.forEach(v => {
            this.channelsMap.set(v.id, new Channel(v));
        });
    }

    getChannelById(channelId: string) {
        return this.channelsMap.get(channelId) ?? this.temporaryChannelsMap.get(channelId);
    }

    getChannelByPersonIds(personIds: string[]): Channel {
        if (!personIds || personIds.length === 0) return;
        personIds = [...new Set(personIds)].sort();
        const channel = this.channels.find(x => isEqual(x.people?.sort(), personIds));
        return channel;
    }

    async createGroup(channelService: ChannelService, component, people: string[], id?: string ) {
        const channel = await channelService.addGroup(component, { people, id });
        AnalyticsService.track(StObject.Channel, StAction.Created, 'channels-store');
        await when(() => this.channelsMap.has(channel.id), { timeout: 40000 });
        return this.channelsMap.get(channel.id);
    }


    async createTemporary(people: string[]) {
        this.clearTemporary();
        people = [...new Set(people.concat([myUserId()]))].sort();

        if (people.length !== 1) {
            const existing = this.channels.find(x => x.type === ChannelType.People && isEqual(x.people.sort(), people));
            if (existing) return existing;
        }

        const channel = new Channel({ people, type: ChannelType.People, id: uuid(), created: new Date().toISOString() });
        channel.temporary = true;
        this.temporaryChannelsMap.set(channel.id, channel);
        return channel;
    }

    async ensureCreated(temporary: Channel, component, channelService: ChannelService) {
        if (!temporary.temporary) return temporary;
        temporary.temporary = false;

        const channel = await this.createGroup(channelService, component, temporary.people, temporary.id);
        // We don't have notification preferences in Web Radio yet. Removing for now.
        // await channel.updateNotification(temporary.notification);
        this.temporaryChannelsMap.delete(temporary.id);
        temporary.update(channel); // Temporary channel assumes new ID and everything (which means new channel gets pulled also)
        return channel;
    }

    clearTemporary() {
        this.temporaryChannelsMap.clear();
    }
}
