import { Injectable } from '@angular/core';
import { ThemeServiceStub } from '@weavix/services/src/theme.service';

@Injectable()
export class ThemeService extends ThemeServiceStub {

    static lightTheme: boolean;

    constructor() {
        super();
    }

    static setLightTheme(lightTheme: boolean) {
        this.lightTheme = lightTheme;
    }

    static getLightTheme(): boolean {
        return this.lightTheme;
    }
}
