import { ItemUpdate } from '@weavix/models/src/badges/event';
import { AnyItem, ItemBaseType, ItemLocationUpdate, ItemType } from '@weavix/models/src/item/item';
import { ObservableSubscribe, TopicPayload } from '@weavix/mqtt/src/mqtt-service';

export abstract class ItemServiceStub {
    abstract updateItem(component: any, id: string, item: AnyItem): Promise<AnyItem>;
    abstract getItems(component: any, facilityId?: string, mapItem?: boolean, baseType?: ItemBaseType, tags?: string[], getCached?): Promise<AnyItem[]>;
    abstract getAllTypes(component: any, tags?: string[], getCached?: boolean): Promise<ItemType[]>;
    abstract subscribeUpdates(c: any): ObservableSubscribe<TopicPayload<ItemUpdate>>;
    abstract subscribeFacilityLocationUpdates(component: any, facilityId: string): ObservableSubscribe<TopicPayload<ItemLocationUpdate>>;
}
