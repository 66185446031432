import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { defaultAvatar } from '@weavix/domain/src/avatar/avatar';
import { LocalDeviceUtility } from '@weavix/domain/src/utils/local-device-utility';
import { Avatar } from '@weavix/models/src/avatar/avatar';
import { DropdownItem } from '@weavix/models/src/dropdown/dropdown';
import { AlertServiceStub } from '@weavix/services/src/alert.service';
import { FaceapiServiceStub } from '@weavix/services/src/faceapi.service';
import { ComponentCanDeactivate } from '../../guards/deactivate.guard';
import { TranslationServiceStub } from '@weavix/services/src/translation.service';

@Component({
    selector: 'app-avatar-editor',
    templateUrl: './avatar-editor.component.html',
    styleUrls: ['./avatar-editor.component.scss']
})
export class AvatarEditorComponent implements OnInit, AfterViewInit, OnDestroy, ComponentCanDeactivate {

    constructor(
        public translateService: TranslationServiceStub,
        private alertService: AlertServiceStub,
        private cdr: ChangeDetectorRef,
        private faceApiService: FaceapiServiceStub
    ) { }

    @Output() editOutput: EventEmitter<File> = new EventEmitter<File>();
    @ViewChild('video') videoRef: ElementRef;
    get video(): HTMLVideoElement {
        return this.videoRef?.nativeElement;
    }
    @ViewChild('canvas') canvas: ElementRef;

    stream: MediaStream;
    videoWidth: number = 320;
    videoHeight: number = 320;
    avatarInput: Avatar;
    isLoading = true;
    get hasImageInput(): boolean {
        return !!this.avatarInput;
    }
    get captureDisabled(): boolean {
        return (!this.stream && !this.avatarInput) || (!!this.stream && !this.faceDetected);
    }

    private faceInterval: NodeJS.Timeout;
    private recheckIsFace: NodeJS.Timeout;
    private IS_FACE_TIMEOUT = 500;
    faceDetected: boolean = false;

    videoDeviceItems: DropdownItem[] = [];
    selectedDeviceId: string;

    async ngOnInit() {
        this.selectedDeviceId = (await LocalDeviceUtility.getDefaultVideoDevice()).deviceId;
        this.videoDeviceItems = (await LocalDeviceUtility.getVideoDevices()).map(d => ({
            label: d.label,
            key: d.deviceId
        }));
    }

    async ngAfterViewInit() {
        await this.initializeVideoStream();
    }

    ngOnDestroy() {
        this.stopFaceDetection();
        this.stopVideoStream();
    }

    @HostListener('window:beforeunload')
    canDeactivate = async () => {
        this.stopFaceDetection();
        this.stopVideoStream();
        return true;
    }

    async handleCaptureClick() {
        if (this.hasImageInput) return this.clearImageAndReinitializeVideo();

        this.captureImage();
    }

    handleFileSelect(event: Event) {
        this.stopFaceDetection();
        this.stopVideoStream();

        const target = event.target as HTMLInputElement;
        if (target.files instanceof FileList && !!target.files.length) {
            const newAvatarFile: File = target.files[0];
            this.updateAvatarPreviewFile(newAvatarFile);
        }
    }

    handleDeviceChange(device: DropdownItem) {
        LocalDeviceUtility.setDefaultVideoInput(device.key);
        this.stopFaceDetection();
        this.stopVideoStream();
        this.initializeVideoStream();
    }

    private async initializeVideoStream() {
        this.isLoading = true;

        try {
            const videoDevice: MediaDeviceInfo = await LocalDeviceUtility.getDefaultVideoDevice();
            this.stream = await LocalDeviceUtility.getDeviceStream(false, videoDevice.deviceId, { video: { width: this.videoWidth, height: this.videoHeight } });
            this.video.srcObject = this.stream;
            this.initializeFaceDetection();
            this.video.play();
            this.cdr.markForCheck();
        } catch (e) {
            this.alertService.sendError(e, 'configuration.user.failed-camera-init');
        }

        this.isLoading = false;
    }

    private initializeFaceDetection() {
        this.video.addEventListener('playing', () => {
            if (this.faceInterval) this.stopFaceDetection();
            this.faceInterval = setInterval(async () => await this.detectFace(), 100);
        });
    }

    private async detectFace() {
        if (!this.video) {
            this.faceDetected = false;
            this.cdr.markForCheck();
            return;
        }
        const faces = await this.faceApiService.detectFaces(this.video as any);
        const hasFace = faces.length > 0;
        if (hasFace) {
            if (this.recheckIsFace) {
                clearTimeout(this.recheckIsFace);
                this.recheckIsFace = setTimeout(() => {
                    clearTimeout(this.recheckIsFace);
                    this.recheckIsFace = null;
                }, this.IS_FACE_TIMEOUT);
                this.faceDetected = true;
                this.cdr.markForCheck();
            } else {
                this.recheckIsFace = setTimeout(() => {
                    clearTimeout(this.recheckIsFace);
                    this.recheckIsFace = null;
                }, this.IS_FACE_TIMEOUT);
            }
        } else {
            if (this.recheckIsFace) return;
            this.faceDetected = false;
            this.cdr.markForCheck();
        }
    }

    private clearImageAndReinitializeVideo() {
        this.avatarInput = null;
        this.editOutput.emit(null);
        this.initializeVideoStream();
    }

    private async captureImage() {
        this.stopFaceDetection();
        const newAvatarFile: File = await this.getImageFile();
        this.stopVideoStream();
        this.editOutput.emit(newAvatarFile);
    }

    private stopFaceDetection() {
        clearInterval(this.faceInterval);
        this.faceInterval = null;
        clearTimeout(this.recheckIsFace);
        this.recheckIsFace = null;
    }

    private stopVideoStream() {
        this.stream?.getVideoTracks()?.[0]?.stop();
        this.stream = null;
        this.cdr.markForCheck();
    }

    private async getImageFile(): Promise<File> {
        const canvasElement = this.canvas.nativeElement;
        canvasElement.getContext('2d').drawImage(this.video, 0, 0);
        const dataUrl: string = canvasElement.toDataURL('image/png');
        this.updateAvatarPreviewUrl(dataUrl);
        const imageResponse: Response = await fetch(dataUrl);
        const blob: Blob = await imageResponse.blob();
        return new File([blob], 'avatar.png', { type: 'image/png' });
    }

    private updateAvatarPreviewFile(file: File) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async (evt) => {
            const image = evt.target.result as string;
            this.faceDetected = await this.checkImageForFace(image);

            this.avatarInput = {
                ...defaultAvatar(),
                height: 300,
                width: 300,
                img: image
            };
            this.editOutput.emit(this.faceDetected ? file : null);
            this.cdr.markForCheck();
        };
    }

    private async checkImageForFace(image: string) {
        const imageElement = document.createElement('img') as HTMLImageElement;
        imageElement.src = image;
        const faces = await this.faceApiService.detectFaces(imageElement);
        return !!faces?.length;
    }

    private updateAvatarPreviewUrl(dataUrl: string) {
        this.avatarInput = {
            ...defaultAvatar(),
            height: 300,
            width: 300,
            img: dataUrl
        };
        this.cdr.markForCheck();
    }

}
