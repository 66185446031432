
import { ClipboardModule } from '@angular/cdk/clipboard';
import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

/* Third Party */
import { TranslateModule } from '@ngx-translate/core';
import { CanvasWhiteboardModule } from '@pk-solutions/ng2-canvas-whiteboard';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

/* Components */
import { WeavixMapsModule } from '@weavix/maps/src/weavix-maps.module';
import { MeetingSetupTemplateComponent } from '../calendar/meeting-setup-template/meeting-setup-template.component';
import { ChannelListComponent } from '../radio/channel-list/channel-list.component';
import { ButtonListComponent } from './button-list/button-list.component';
import { AccountCardComponent } from './card-select/account-card/account-card.component';
import { AddParticipantCardComponent } from './card-select/add-participant-card/add-participant-card.component';
import { CardSelectComponent } from './card-select/card-select.component';
import { ChannelInfoPersonCardComponent } from './card-select/channel-info-person-card/channel-info-person-card.component';
import { ChannelPersonCardComponent } from './card-select/channel-person-card/channel-person-card.component';
import { CompanyCardComponent } from './card-select/company-card/company-card.component';
import { EmailInviteCardComponent } from './card-select/email-invite-card/email-invite-card.component';
import { FacilityCardComponent } from './card-select/facility-card/facility-card.component';
import { ItemCardComponent } from './card-select/item-card/item-card.component';
import { ParticipantCardComponent } from './card-select/participant-card/participant-card.component';
import { PeopleCardComponent } from './card-select/people-card/people-card.component';
import { ClockComponent } from './clock/clock.component';
import { ContainerWithLabelComponent } from './container-with-label/container-with-label.component';
import { CopyrightComponent } from './copyright/copyright.component';
import { DataFilterComponent } from './data-filter/data-filter.component';
import { FilterDropdownComponent } from './data-filter/filter-dropdown/filter-dropdown.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { DeviceSettingsComponent } from './device-settings/device-settings.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { EmailInputComponent } from './email-input/email-input.component';
import { FacilitySelectComponent } from './facility-select/facility-select.component';
import { FormButtonsComponent } from './form-buttons/form-buttons.component';
import { GasReadingIconDisplayComponent } from './gas-reading-icon-display/gas-reading-icon-display.component';
import { GasSensorReadingsDisplayComponent } from './gas-sensor-readings-display/gas-sensor-readings-display.component';
import { HeaderComponent } from './header/header.component';
import { HideWindowControlComponent } from './hide-window-control/hide-window-control.component';
import { IconWithTextComponent } from './icon-with-text/icon-with-text.component';
import { ImageFeedComponent } from './image-feed/image-feed.component';
import { ImagePreviewComponent } from './image-preview/image-preview.component';
import { InputInlineEditComponent } from './input-inline-edit/input-inline-edit.component';
import { InputComponent } from './input/input.component';
import { MeetingSetupHeaderComponent } from './meeting-setup-header/meeting-setup-header.component';
import { NoAccessComponent } from './no-access/no-access.component';
import { NotificationCardComponent } from './notifications/notification-card/notification-card.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { PassValidatorComponent } from './password/password.component';
import { PersonListComponent } from './person-select/person-list/person-list.component';
import { PersonSelectComponent } from './person-select/person-select.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { RightHeaderComponent } from './right-header/right-header.component';
import { SettingsMenuComponent } from './settings-menu/settings-menu.component';
import { TabBarComponent } from './tab-bar/tab-bar.component';
import { TabComponent } from './tab/tab.component';
import { VolumeBarComponent } from './volume-bar/volume-bar.component';

/* Directives */
import { ClickOutsideDirective } from 'weavix-shared/directives/click-outside.directive';
import { PermissionDirective } from 'weavix-shared/directives/permission.directive';
import { DragAndDropDirective } from './dnd.directive';

/* Pipes */
import { WeavixComponentsModule } from '@weavix/components/src/weavix-components.module';
import { TimeAgoPipe } from 'weavix-shared/pipes/time-ago.pipe';
import { ChannelUserInfoComponent } from '../radio/channel-user-info/channel-user-info.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { NotificationModalComponent } from './notifications/notification-modal/notification-modal.component';
import { PersonSelectSearchChipComponent } from './person-select/person-select-search-chip/person-select-search-chip.component';
import { PopoverComponent } from './popover/popover.component';
import { UserAddMenuComponent } from './user-add-remove/user-add-menu/user-add-menu.component';
import { UserAddRemoveComponent } from './user-add-remove/user-add-remove.component';
import { WeavixBugSvgComponent } from './weavix-bug-svg/weavix-bug-svg.component';


@NgModule({
    declarations: [
        AccountCardComponent,
        AddParticipantCardComponent,
        ButtonListComponent,
        CardSelectComponent,
        ChannelListComponent,
        ChannelPersonCardComponent,
        ChannelInfoPersonCardComponent,
        ChannelUserInfoComponent,
        ClickOutsideDirective,
        ClockComponent,
        CompanyCardComponent,
        ContainerWithLabelComponent,
        CopyrightComponent,
        DataFilterComponent,
        DatePickerComponent,
        DeviceSettingsComponent,
        DragAndDropDirective,
        DropdownComponent,
        EmailInputComponent,
        EmailInviteCardComponent,
        FacilityCardComponent,
        FacilitySelectComponent,
        FilterDropdownComponent,
        FormButtonsComponent,
        GasReadingIconDisplayComponent,
        GasSensorReadingsDisplayComponent,
        HeaderComponent,
        HideWindowControlComponent,
        IconWithTextComponent,
        ImageFeedComponent,
        ImagePreviewComponent,
        ImageUploadComponent,
        InputComponent,
        InputInlineEditComponent,
        ItemCardComponent,
        MeetingSetupHeaderComponent,
        MeetingSetupTemplateComponent,
        NoAccessComponent,
        NotificationsComponent,
        NotificationCardComponent,
        NotificationModalComponent,
        ParticipantCardComponent,
        PassValidatorComponent,
        PeopleCardComponent,
        PermissionDirective,
        PersonListComponent,
        PersonSelectComponent,
        PersonSelectSearchChipComponent,
        PopoverComponent,
        ProgressBarComponent,
        RightHeaderComponent,
        SettingsMenuComponent,
        TabComponent,
        TabBarComponent,
        TimeAgoPipe,
        UserAddMenuComponent,
        UserAddRemoveComponent,
        VolumeBarComponent,
        WeavixBugSvgComponent,
    ],
    imports: [
        CommonModule,
        ClipboardModule,
        FormsModule,
        HttpClientModule,
        CanvasWhiteboardModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatExpansionModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatTooltipModule,
        Ng2TelInputModule,
        NgxSkeletonLoaderModule,
        ReactiveFormsModule,
        RouterModule,
        ScrollingModule,
        TranslateModule,
        WeavixComponentsModule,
        WeavixMapsModule,
    ],
    exports: [
        AccountCardComponent,
        ButtonListComponent,
        CanvasWhiteboardModule,
        CardSelectComponent,
        ChannelListComponent,
        ChannelPersonCardComponent,
        ChannelInfoPersonCardComponent,
        ChannelUserInfoComponent,
        ClickOutsideDirective,
        ClipboardModule,
        ClockComponent,
        CompanyCardComponent,
        ContainerWithLabelComponent,
        CopyrightComponent,
        DataFilterComponent,
        DatePickerComponent,
        MatExpansionModule,
        DeviceSettingsComponent,
        DragAndDropDirective,
        DropdownComponent,
        EmailInputComponent,
        FacilitySelectComponent,
        FilterDropdownComponent,
        FormButtonsComponent,
        FormsModule,
        GasReadingIconDisplayComponent,
        GasSensorReadingsDisplayComponent,
        HeaderComponent,
        HideWindowControlComponent,
        IconWithTextComponent,
        ImageFeedComponent,
        ImagePreviewComponent,
        InputComponent,
        InputInlineEditComponent,
        ItemCardComponent,
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatTooltipModule,
        MeetingSetupHeaderComponent,
        MeetingSetupTemplateComponent,
        Ng2TelInputModule,
        NgxSkeletonLoaderModule,
        NoAccessComponent,
        NotificationsComponent,
        NotificationModalComponent,
        OverlayModule,
        PassValidatorComponent,
        PermissionDirective,
        PersonSelectComponent,
        PersonSelectSearchChipComponent,
        PopoverComponent,
        ProgressBarComponent,
        ReactiveFormsModule,
        RightHeaderComponent,
        ScrollingModule,
        SettingsMenuComponent,
        TabComponent,
        TabBarComponent,
        TimeAgoPipe,
        UserAddMenuComponent,
        UserAddRemoveComponent,
        TranslateModule,
        VolumeBarComponent,
        WeavixBugSvgComponent,
        WeavixComponentsModule,
        WeavixMapsModule,
    ],
})
export class SharedModule { }
