import { Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Utils } from '@weavix/components/src/utils/utils';
import { CSS_BREAKPOINTS } from '@weavix/domain/src/utils/css';
import { BadgeUpdate } from '@weavix/models/src/badges/badge-update';
import { BadgeEvent } from '@weavix/models/src/badges/event';
import { Item } from '@weavix/models/src/item/item';
import { DvrItem, DvrItems, DvrPeople, DvrPerson, DvrState, Geofence, MapView, MapViewState } from '@weavix/models/src/map/map';
import { Topic } from '@weavix/models/src/topic/topic';
import { AccountServiceStub } from '@weavix/services/src/account.service';
import { AlertServiceStub } from '@weavix/services/src/alert.service';
import { BadgeServiceStub } from '@weavix/services/src/badge.service';
import { FacilityServiceStub } from '@weavix/services/src/facility.service';
import { ItemServiceStub } from '@weavix/services/src/item.service';
import { MapSearchServiceStub } from '@weavix/services/src/map-search.service';
import { PersonServiceStub } from '@weavix/services/src/person.service';
import { PubSubServiceStub } from '@weavix/services/src/pub-sub.service';
import { TranslationServiceStub } from '@weavix/services/src/translation.service';

import * as _ from 'lodash';
import { fromEvent, Observable, ReplaySubject, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export interface MapItemEvent {
    [id: string]: Item;
}

export interface MapGeofenceEvent {
    [id: string]: Geofence;
}

export interface BadgeRegistrationEvent {
    badgeName?: string;
}

export interface BadgeRegistrationPayload extends BadgeRegistrationEvent {
    badgeName: string;
    deregistered: boolean;
}

export interface BadgePayload {
    personId?: string;
    event?: BadgeUpdate;
    registration?: BadgeRegistrationPayload;
}

export interface DvrPlaybackState {
    timestamp: number;
    inPlaybackMode: boolean;
}

export interface DvrSliderDropEmitEvent {
    toggleDvrState: boolean;
    range: { from: Date; to: Date; };
    firstLoad: boolean;
}

export enum DvrEntities {
    People = 'people',
    Items = 'items'
}

const MAP_UPDATE_INTERVAL: number = 15000;

export abstract class MapServiceStub {
    private readonly mapTranslationKeys: string[] = [
        'generics.edit',
        'generics.moreInfo',
        'name',
        'area',
        'company.company',
        'people',
        'items.items'
    ];
    private queryParamFilters: { [key: string]: string } = {};
    public dvrPlaybackState: DvrPlaybackState = {
        inPlaybackMode: false,
        timestamp: null
    };
    public dvrDataState: DvrState;
    private dvrDataStatePromise: Promise<any>;
    public dvrSliderDrop: ReplaySubject<DvrSliderDropEmitEvent> = new ReplaySubject(1); // fires on slider release, used to update person detail view

    public dashboard: boolean = true;
    public dashboard$: ReplaySubject<boolean> = new ReplaySubject(1);
    private mapUpdateInterval: number;
    public mapUpdateIntervalTrigger: Subject<void> = new Subject();

    private subscribedToBadges;
    private prevWindowSize: number;
    public routeParamChange$: Subject<{ [key: string]: string }> = new Subject();

    private route: ActivatedRoute;
    private translationService: TranslationServiceStub;
    private facilityService: FacilityServiceStub;
    private peopleService: PersonServiceStub;
    private badgeService: BadgeServiceStub;
    private pubSubService: PubSubServiceStub;
    private accountService: AccountServiceStub;
    private mapSearchService: MapSearchServiceStub;
    private alertService: AlertServiceStub;
    private itemService: ItemServiceStub

    constructor(
        injector: Injector
    ) {
        this.route = injector.get(ActivatedRoute);
        this.translationService = injector.get(TranslationServiceStub);
        this.facilityService = injector.get(FacilityServiceStub);
        this.peopleService = injector.get(PersonServiceStub);
        this.badgeService = injector.get(BadgeServiceStub);
        this.pubSubService = injector.get(PubSubServiceStub);
        this.accountService = injector.get(AccountServiceStub);
        this.mapSearchService = injector.get(MapSearchServiceStub);
        this.alertService = injector.get(AlertServiceStub);
        this.itemService = injector.get(ItemServiceStub);

        if (this.route.queryParams) {
            this.route.queryParams.subscribe(params => this.setRouteFilters(params));
        }
    }

    public static convertToMapView(mapViewState: MapViewState): MapView {
        let bounds: google.maps.LatLngBounds;
        if (mapViewState?.bounds) {
            const sw = { lat: mapViewState.bounds[0][1], lng: mapViewState.bounds[0][0] };
            const ne = { lat: mapViewState.bounds[1][1], lng: mapViewState.bounds[1][0] };
            bounds = new google.maps.LatLngBounds(sw, ne);
        }
        return {
            lng: mapViewState?.location?.[0],
            lat: mapViewState?.location?.[1],
            bounds,
            zoom: mapViewState?.zoom
        };
    }

    startMapUpdateInterval(): void {
        if (!this.mapUpdateInterval) {
            this.mapUpdateInterval = window.setInterval(() => {
                this.mapUpdateIntervalTrigger.next();
            }, MAP_UPDATE_INTERVAL);
        }
    }

    stopMapUpdateInterval(): void {
        if (this.mapUpdateInterval && !this.mapUpdateIntervalTrigger.observers.length) clearInterval(this.mapUpdateInterval);
    }

    async subscribeBadgeUpdates(component: any) {
        const accountId = this.accountService.getAccountId();
        const facility = await this.facilityService.getCurrentFacility();
        if (this.subscribedToBadges === (facility?.id ?? accountId)) return;
        this.subscribedToBadges = facility?.id ?? accountId;
        this.pubSubService.subscribe<BadgeEvent>(component, facility ? Topic.AccountFacilityPersonBadgeUpdated
            : Topic.AccountPersonBadgeUpdated, facility ? [accountId, facility.id, '+'] : [accountId, '+'])
            .subscribe((message) => {
                if (this.dvrDataState && message.payload.location) {
                    const time = new Date(message.payload.date).getTime();
                    if (time >= this.dvrDataState.range.from.getTime() && time < this.dvrDataState.range.to.getTime()) {
                        this.dvrDataState.events[message.replacements[1]] = this.dvrDataState.events[message.replacements[1]] || [];
                        this.dvrDataState.events[message.replacements[1]].push(message.payload as any);
                    }

                    const person = this.dvrDataState.people[message.replacements[1]];
                    if (person) {
                        person.latestEvent = message.payload;
                        if (!this.dvrPlaybackMode) person.badge = message.payload as any;
                    }
                }
            });
        this.pubSubService.subscribe<BadgeRegistrationEvent>(component, Topic.AccountPersonBadgeDeregistered, [accountId, '+'])
            .subscribe((message) => {
                if (this.dvrDataState) {
                    const person = this.dvrDataState.people[message.replacements[1]];
                    if (person && person.latestEvent) delete person.latestEvent.name;
                }
            });
    }

    set dashboardMode(value: boolean) {
        this.dashboard = value;
        this.dashboard$.next(value);
    }

    get dashboardMode() {
        return this.dashboard;
    }

    get dvrTimestamp() {
        // dvr timestamp is used to compare with the current date. Default to current time if none set
        return (this.dvrPlaybackState && this.dvrPlaybackState.timestamp) || Date.now();
    }

    set dvrTimestamp(timestamp: number) {
        this.dvrPlaybackState.timestamp = timestamp;
    }

    get dvrPlaybackMode() {
        return this.dvrPlaybackState && this.dvrPlaybackState.inPlaybackMode;
    }

    set dvrPlaybackMode(inPlaybackMode: boolean) {
        this.dvrPlaybackState.inPlaybackMode = inPlaybackMode;
    }

    async initDvrRange(component: any, from: Date, to: Date, timestamp: number, entities: DvrEntities[], facilityId?: string) {
        from = new Date(timestamp - 30 * 60000);
        to = new Date(timestamp + 30 * 60000);
        const accountId = await this.accountService.getAccountId();

        if (this.dvrDataState?.range?.from?.getTime() === from.getTime()
            && this.dvrDataState?.range?.to?.getTime() === to.getTime()
            && this.dvrDataState?.facility?.id === facilityId
            && this.dvrDataState?.accountId === accountId) {
            await this.dvrDataStatePromise;
            return this;
        }

        let resolve;
        this.dvrDataStatePromise = new Promise(r => resolve = r);
        this.alertService.withLoading(this.dvrDataStatePromise);
        this.dvrDataState = { people: {}, events: {}, facility: null, range: { from, to }, accountId };
        if (facilityId) this.dvrDataState.facility = await this.facilityService.get(component, facilityId, true);

        const getDvrPeople = async () => {
            this.dvrDataState.people = (
                facilityId ?
                    await this.peopleService.getFacilityPeople(component, facilityId) :
                    await this.peopleService.getPeople(component)
            ).reduce((obj: DvrPeople, p: DvrPerson) => (obj[p.id] = p, obj), {});
            const peopleEvents = await this.badgeService.getPeopleEvents(component, facilityId, from, to);
            const dvrEventsMap = peopleEvents.reduce(
                (acc, curr) => {
                    if (curr.personId && curr.location) {
                        if (curr.personId in acc) acc[curr.personId].push(curr);
                        else acc[curr.personId] = [curr];
                    }
                    return acc;
                },
                {});
            this.dvrDataState.events = _.merge(dvrEventsMap, this.dvrDataState.events);
        };

        const getDvrItems = async () => {
            this.dvrDataState.items = (await this.itemService.getItems(component, facilityId)).reduce((obj: DvrItems, item: DvrItem) => (obj[item.id] = item, obj), {});
            const itemEvents = await this.badgeService.getAllItemEvents(component, facilityId, from, to);
            const dvrEventsMap = itemEvents.reduce(
                (acc, curr) => {
                    if (curr.itemId && curr.location) {
                        if (curr.itemId in acc) acc[curr.itemId].push(curr);
                        else acc[curr.itemId] = [curr];
                    }
                    return acc;
                },
                {});
            this.dvrDataState.events = _.merge(dvrEventsMap, this.dvrDataState.events);
        };

        for (const e of entities) {
            switch (e) {
                case DvrEntities.Items :
                    await getDvrItems();
                    break;
                case DvrEntities.People :
                    await getDvrPeople();
                    break;
            }
        }

        resolve();
        return this;
    }

    getDvrMinuteDataPeople(timestamp: number): { [key: string]: DvrPerson } {
        if (!timestamp) {
            if (this.dvrDataState) {
                Object.values(this.dvrDataState.people).forEach(p => {
                    p.badge = p.latestEvent || p.badge;
                    this.mapSearchService.mapPersonBadgeUpdate.next(p);
                });
                const data = this.dvrDataState.people;
                return data;
            }
            return null;
        }

        Object.values(this.dvrDataState.people).forEach(p => {
            const events = this.dvrDataState.events[p.id] || [];
            let min = 0;
            let max = events.length;
            const date = timestamp - 30 * 60000;
            while (min < max - 1) {
                const mid = Math.floor((min + max) / 2);
                if (new Date(events[mid].date).getTime() < date) {
                    min = mid;
                } else {
                    max = mid;
                }
            }
            let latestEvent = null;
            for (let i = min + 1; i < events.length; i++) {
                const event = events[i];
                if (new Date(event.date).getTime() > timestamp) break;

                if (!latestEvent) latestEvent = {};
                Object.assign(latestEvent, events[i]);
            }

            p.latestEvent = p.latestEvent || p.badge;
            if (latestEvent) latestEvent.name = p.badge && p.badge.name || 'yes';
            p.badge = latestEvent;
            this.mapSearchService.mapPersonBadgeUpdate.next(p);
        });

        return this.dvrDataState.people;
    }

    getDvrMinuteDataItems(timestamp: number): { [key: string]: DvrItem } {
        if (!timestamp) {
            if (this.dvrDataState) {
                return this.dvrDataState.items;
            }
            return null;
        }

        Object.values(this.dvrDataState.items).forEach(item => {
            const events = this.dvrDataState.events[item.id] || [];
            let min = 0;
            let max = events.length;
            const date = timestamp;
            while (min < max - 1) {
                const mid = Math.floor((min + max) / 2);
                if (new Date(events[mid].date).getTime() < date) {
                    min = mid;
                } else {
                    max = mid;
                }
            }
            let latestEvent = null;

            if (!latestEvent) latestEvent = {};
            Object.assign(latestEvent, events[min]);

            item.latestEvent = latestEvent;
        });

        return this.dvrDataState.items;
    }

    public setDvrPlaybackState(playbackState: DvrPlaybackState): void {
        this.dvrPlaybackState = playbackState;
    }

    getMapTranslations(): Observable<{ [key: string]: string }> & PromiseLike<{ [key: string]: string }> {
        return Utils.awaitable(this.translationService.getTranslations(this.mapTranslationKeys));
    }

    public setRouteFilters(queryParams: any): void {
        this.queryParamFilters = {};
        for (const param in queryParams) {
            if (queryParams[param]) {
                this.queryParamFilters[param] = queryParams[param].toString();
            }
        }

        this.routeParamChange$.next(this.queryParamFilters);
    }

    public getRouteFilters(): { [key: string]: string } {
        return this.queryParamFilters;
    }

    public getRouteFilter(param: string): string {
        return this.queryParamFilters?.[param];
    }

    public setupDashboardDisplay(component): void {
        if (window.innerWidth < CSS_BREAKPOINTS.full) this.dashboardMode = false;
        this.prevWindowSize = window.innerWidth;

        Utils.safeSubscribe(component, fromEvent(window, 'resize')).pipe(debounceTime(250)).subscribe(x => {
            const resizingDown: boolean = this.prevWindowSize > CSS_BREAKPOINTS.full && window.innerWidth < CSS_BREAKPOINTS.full;
            const resizingUp: boolean = this.prevWindowSize < CSS_BREAKPOINTS.full && window.innerWidth > CSS_BREAKPOINTS.full;

            if (resizingDown) {
                this.dashboardMode = false;
            } else if (resizingUp) {
                this.dashboardMode = true;
            }
            this.prevWindowSize = window.innerWidth;
        });
    }
}
