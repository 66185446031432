import { BehaviorSubject, Subject } from 'rxjs';
import { sleep } from 'weavix-shared/utils/sleep';
import { MediaService } from './media.service';

export const WEBRTC_CONNECTIONS = {};

export interface WebrtcOptions {
    broadcastId?: string;
    broadcastServer?: string;
    audioTrack?: MediaStreamTrack;
    videoTrack?: MediaStreamTrack;
    screenshare?: boolean;
}

export abstract class WebrtcInterface {
    private static connections: WebrtcInterface[] = [];

    reconnect$ = new Subject<boolean>();
    poor$ = new Subject<boolean>();
    closed: boolean;

    stream$ = new BehaviorSubject<MediaStream>(null);
    mediaStream: MediaStream = new MediaStream([]);
    videoStream: MediaStream = new MediaStream([]);

    broadcastId?: string;
    broadcastServer?: string;
    protected audioTrack?: MediaStreamTrack;
    protected videoTrack?: MediaStreamTrack;
    protected screenshare?: boolean;

    static async getPeople() {
        await sleep(10);
        const people = Object.keys(WebrtcInterface.connections.filter(x => x.personId !== 'audio' && !!x.broadcastId).reduce((o, v) => (o[v.personId] = v, o), {})).length;
        return Math.max(people, 1);
    }

    private static async updateBandwidth() {
        const people = await this.getPeople();
        await sleep(1000);
        const newPeople = await this.getPeople();
        if (newPeople !== people) {
            WebrtcInterface.connections.forEach(async viewer => {
                try {
                    await viewer.updatePeople(newPeople);
                } catch (e) {
                    console.error(e);
                }
            });
        }
    }

    constructor(
        protected mediaService: MediaService,
        protected meetingId: string,
        public personId: string,
        protected options: WebrtcOptions,
    ) {
        this.broadcastId = options.broadcastId;
        this.broadcastServer = options.broadcastServer;
        this.audioTrack = options.audioTrack;
        this.videoTrack = options.videoTrack;
        this.screenshare = options.screenshare;

        WebrtcInterface.connections.push(this);
        WebrtcInterface.updateBandwidth();
        const interval = setInterval(async () => {
            if (this.closed) {
                clearInterval(interval);
                return;
            }
            this.checkStats();
        }, 10000);
    }

    abstract get audioEnabled();
    abstract get videoEnabled();
    abstract get connected();

    close(reason?) {
        if (this.closed) return;
        this.closed = true;

        const found = WebrtcInterface.connections.indexOf(this);
        if (found >= 0) WebrtcInterface.connections.splice(found, 1);

        WebrtcInterface.updateBandwidth();
    }

    abstract start();
    abstract setAudio(track: MediaStreamTrack);
    abstract setVideo(track: MediaStreamTrack, screenshare?: boolean);
    setScreenShareEnabled(enabled: boolean) {}

    protected setStreams() {
        if (this.mediaStream.getVideoTracks()[0] !== this.videoTrack) {
            this.mediaStream.getVideoTracks().forEach(track => this.mediaStream.removeTrack(track));
            if (this.videoTrack) this.mediaStream.addTrack(this.videoTrack);
            this.videoStream.getVideoTracks().forEach(track => this.videoStream.removeTrack(track));
            if (this.videoTrack) this.videoStream.addTrack(this.videoTrack);
        }
        if (this.mediaStream.getAudioTracks()[0] !== this.audioTrack) {
            this.mediaStream.getAudioTracks().forEach(track => this.mediaStream.removeTrack(track));
            if (this.audioTrack) this.mediaStream.addTrack(this.audioTrack);
        }
        this.stream$.next(this.mediaStream);
    }

    abstract checkStats();
    abstract updatePeople(people: number);
}
