import {
    ApplicationRef,
    ComponentFactoryResolver,
    EmbeddedViewRef,
    Injectable,
    Injector,
} from '@angular/core';

export interface ChildConfig {
    inputs: object;
    outputs: object;
}

@Injectable({
    providedIn: 'root',
})
/**
 * Dynamically inject a component into the DOM
 * Adapted from https://itnext.io/angular-create-your-own-modal-boxes-20bb663084a1
 */
export class DomService {
    private childComponentRef: any;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector,
    ) {}

    static htmlToDocumentFragment(htmlString): DocumentFragment {
        const tempDiv = document.createElement('DIV');
        tempDiv.innerHTML = htmlString;
        if (tempDiv.childNodes.length === 1) {
            return tempDiv.removeChild(tempDiv.firstChild) as any;
        } else {
            const fragment = document.createDocumentFragment();
            while (tempDiv.firstChild) {
                fragment.appendChild(tempDiv.firstChild);
            }

            return fragment;
        }
    }

    public appendComponentTo<T>(child: any, manageComponent: (c: T) => void, updateDom?: (element: HTMLElement) => void) {
        // Create a component reference from the component
        const childComponentRef = this.componentFactoryResolver
            .resolveComponentFactory(child)
            .create(this.injector);

        // Allow input/output managemnet
        manageComponent(<T>childComponentRef.instance);

        this.childComponentRef = childComponentRef;
        // Attach component to the appRef so that it's inside the ng component tree
        this.appRef.attachView(childComponentRef.hostView);

        // Get DOM element from component
        const childDomElem = (childComponentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

        // Append DOM element to the body
        updateDom ? updateDom(childDomElem) : document.body.appendChild(childDomElem);
    }

    public removeComponent() {
        if (this.childComponentRef) {
            this.appRef.detachView(this.childComponentRef.hostView);
            this.childComponentRef.destroy();
        }
    }
}
