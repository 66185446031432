import { Channel } from '@weavix/models/src/channel/channel';
import { ChannelAlertsType,
        ChannelNotifications,
        ConditionalKeys, 
        ConditionalProperty } from '@weavix/models/src/channel/channel-notifications';

// simplified interface copied over for now, can be centralized to common location later
enum SystemGroup {
    GlobalAdmin = 'global-admin',
    None = 'none'
}

// simplified interface copied over for now, can be centralized to common location later
interface Person {
    id?: string;
    userId?: string;
    group?: string;
    crafts?: string[];
    tags?: string[];
    companyId?: string;
}


export type PersonFacilityPredicate = (facilityId: string, person: Person) => boolean;

export function isPersonIncludedInAdminDefinedChannel(person: Person, channel: Channel, isPersonOnFacility: PersonFacilityPredicate): boolean {
    if (!person.userId) return false; // person still exists but userId removed when Person is removed from account

    if (!empty(channel.people)) {
        if (channel.people.includes(person.id) && !matchesAtLeastOne(channel.excludedPeople, [person.id])) return true;
        if (empty(channel.facilityIds) && empty(channel.peopleCrafts) && empty(channel.peopleTags) && empty(channel.companies)) return false;
    }

    if (person.group !== SystemGroup.GlobalAdmin) {
        if (!empty(channel.facilityIds) && !channel.facilityIds.some(facilityId => isPersonOnFacility(facilityId, person))) {
            return false;
        }
        if (!empty(channel.excludedFacilityIds) && channel.excludedFacilityIds.some(facilityId => isPersonOnFacility(facilityId, person))) {
            return false;
        }
    }

    if (matchesAtLeastOne(channel.excludedPeople, [person.id]) ||
        matchesAtLeastOne(channel.excludedTags, person.tags) ||
        matchesAtLeastOne(channel.excludedCrafts, person.crafts) ||
        matchesAtLeastOne(channel.excludedCompanies, [person.companyId]) ||
        (!empty(channel.peopleCrafts) && !matchesAtLeastOne(channel.peopleCrafts, person.crafts)) ||
        (!empty(channel.peopleTags) && !matchesAtLeastOne(channel.peopleTags, person.tags)) ||
        (!empty(channel.companies) && !matchesAtLeastOne(channel.companies, [person.companyId]))
        ) {
        return false;
     }

    return true;
}

function empty(list: any[]): boolean {
    return (list?.length ?? 0) === 0;
}

function matchesAtLeastOne(list: string[] | undefined, values: string[] | undefined): boolean {
    if (!list) return false;
    if (!values) return false;
    return list.some(listValue => values.includes(listValue));
}

export function evaluateConditions(conditions: ConditionalProperty[], person: Person, facilityLookup: (facilityId: string) => any) {
    const doStringEvaluation = (values: string[], includedValue: string, negate: boolean) => {
        if (values.includes(includedValue)) {
            if (!negate) return true;
            else return false;
        } else {
            if (negate) return true;
            else return false;
        }
    }

    const doArrayEvaluation = (values: string[], includedArray: string[], negate: boolean) => {
        if (values.some(x => includedArray.includes(x))) {
            if (!negate) return true;
            else return false;
        } else {
            if (negate) return true;
            else return false;
        }
    }

    return conditions.every(condition => {
        if (condition.key === ConditionalKeys.People) return doStringEvaluation(condition.value, person.id, condition.negate);
        if (condition.key === ConditionalKeys.PersonCompany) return doStringEvaluation(condition.value, person.companyId, condition.negate);
        if (condition.key === ConditionalKeys.PersonCraft) return doArrayEvaluation(condition.value, person.crafts, condition.negate);
        if (condition.key === ConditionalKeys.PersonSites) {
            if (person.group === SystemGroup.GlobalAdmin) {
                if (!condition.negate) return true;
                else return false;
            } else {
                const match = condition.value.some(x => facilityLookup(x));
                return condition.negate ? !match : match;
            }
        }
        if (condition.key === ConditionalKeys.PersonTags) return doArrayEvaluation(condition.value, person.tags, condition.negate);
    })
}

function mergeDefaultPreferences(notification: ChannelNotifications, defaults: ChannelNotifications) {
    if (!defaults) return notification;

    // if a user is not allowed to Mute via console's ADC config, always allow PTT (channel not muted)
    // else if the user has no preferences for allowPtt, take default from console's ADC config 'soundMuted'
    if (defaults.allowMuting === false) notification.allowPtt = true;
    else if (notification?.allowPtt === undefined) notification.allowPtt = !defaults.soundMuted;

    // if a user is not allowed to disable notifications via console's ADC config, always allow Notifications (channel notification enabled)
    // else if the user has no preferences for allowNotification, take default from console's ADC config 'notificationsOff'
    if (defaults.allowDisablingNotifications === false) notification.allowNotifications = true;
    else if (notification?.allowNotifications === undefined) notification.allowNotifications = !defaults.notificationsOff;

    Object.values(ChannelAlertsType).forEach(x => {
        notification[x] = defaults[x];
    });

    return notification;
}

export function getUserAdcNotifictionPreferences(
    channel: Channel, 
    notification: ChannelNotifications, 
    person: Person, 
    facilityLookup: (facilityId: string) => any
): ChannelNotifications {
    // copy notifications to avoid re-writing original notifications
    notification = { ...(notification ?? {}) };
    const applicableOverrides = channel.conditionalNotificationOverrides?.filter(conditional => {
        return !person ? false : evaluateConditions(conditional.conditions, person, facilityLookup);
    });

    notification = mergeDefaultPreferences(notification, applicableOverrides?.[0] ?? channel?.defaultNotification);

    return notification;
}
