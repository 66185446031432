import { Injectable } from '@angular/core';
import { ModalComponent } from '@weavix/components/modal/modal.component';
import { Modal, ModalActions, ModalActionType } from '@weavix/models/src/modal/modal';
import * as _ from 'lodash';

import { environment } from 'environments/environment';
import { DomService } from 'weavix-shared/services/dom.service';


export interface ModalResult {
    action: ModalActionType;
    data?: any;
}

@Injectable({
    providedIn: 'root',
})
export class ModalService {

    constructor(private domService: DomService) { }

    async confirmLeave(): Promise<ModalResult> {
        return await this.confirm('generics.leaveConfirm', 'generics.unsaved-changes', {
            [ModalActionType.submit]: { textKey: 'generics.leave', buttonClass: 'blue-lt' },
         }, true);
    }

    async confirmDelete(message?: string): Promise<ModalResult> {
        message = message || 'generics.confirmPrompt';
        return await this.confirm(message, 'generics.delete', {
            [ModalActionType.submit]: { textKey: 'generics.delete', buttonClass: 'red' },
         }, true);
    }

    async confirm(textKey: string | undefined, headerTextKey: string, actions?: ModalActions, fullScreen?: boolean, params: any = {}): Promise<ModalResult> {
        return new Promise((resolve) => {
            const modalInput: Modal = this.basicModalConfig(textKey, headerTextKey, fullScreen, params);

            modalInput.actions = _.merge(modalInput.actions, actions);

            this.injectModal<ModalComponent>(ModalComponent, (c) => {
                c.modalInput = modalInput;
                c.modalCloseOutput.subscribe(action => {
                    this.destroyModal();
                    resolve({ action });
                });
            });
        });
    }

    private basicModalConfig(textKey?: string, headerTextKey?: string, fullScreen?: boolean, params?: any) {
        const modalInput: Modal = {
            textKey,
            header: {
                textKey: headerTextKey,
                textAlignment: environment.teamsApp ? 'left' : 'center',
            },
            isOpen: true,
            width: 600,
            actions: {
                [ModalActionType.submit]:  { show: true },
                [ModalActionType.cancel]:  { show: true },
            },
            fullScreen,
            params,
            zIndex: 1100,
        };
        return modalInput;
    }

    private injectModal<T>(component: any, manageComponent: (c: T) => void) {
        this.domService.appendComponentTo(component, manageComponent);
    }

    private destroyModal() {
        this.domService.removeComponent();
    }
}
