import { DataFilterSelection } from './data-filter-selection.model';

export interface WorkForm {
    id?: string;
    facilityId?: string;
    folderId?: string;
    name: string;
    status: FormStatus;
    questions: WorkFormQuestion[];
    tags: string[];
    currentVersion?: number;
    responseCount?: number;
    versions?: WorkFormVersion[];
}

export enum FormStatus {
    Draft = 'draft',
    Published = 'published',
    Archived = 'archived'
}

export enum QuestionType {
    Dropdown = 'dropdown',
    Text = 'text',
    Number = 'number',
    PersonPicker = 'person-picker',
    CompanyPicker = 'company-picker',
    ItemPicker = 'item-picker',
    Date = 'date'
}

export interface QuestionOption {
    display: string;
    value: any;
}

export interface AbstractDetails {
    required: boolean;
    type: QuestionType;
}

export interface DropdownDetails extends AbstractDetails {
    type: QuestionType.Dropdown;
    options: QuestionOption[];
}

export interface TextDetails extends AbstractDetails {
    type: QuestionType.Text;
}

export interface NumberDetails extends AbstractDetails {
    type: QuestionType.Number;
}

export interface DateDetails extends AbstractDetails {
    type: QuestionType.Date;
}

export interface SelectionDetails extends AbstractDetails {
    selections: DataFilterSelection;
    resources?: any[];
}

export interface WorkFormVersion {
    version: string;
    status: FormStatus;
}

export interface WorkFormQuestion {
    qId: string; // generated on frontend
    question: string;
    type: QuestionType;
    details: AbstractDetails;
}

export interface QuestionTransfer {
    qId: string;
    question: string;
    details: AbstractDetails;
    number: number;
    editing: boolean;
    required: boolean;
}

export interface WorkFormSubmission {
    id?: string;
    facilityId?: string;
    form: {id: string; version: string; };
    completed: CompletedDetails;
    event?: {id?: string; };
}

export interface WorkFormSubmissions {
    totalSubmissions: number;
    submissions: WorkFormSubmission[];
}

export interface SubmissionTableRows {
    totalSubmissions: number;
    rows: SubmissionTableRow[];
}

export interface SubmissionTableRow {
    id: string;
    person: string;
    date: Date;
}

export interface CompletedDetails {
    personId?: string;
    date?: Date;
    answers: WorkFormAnswer[];
}

export interface WorkFormAnswer {
    qId: string;
    answer: any;
}

export interface SubmissionQuery {
    toId?: string;
    toDate?: string;
    limit?: number;
    skip?: number;
    sortCol?: string;
    sortDir?: number;
    reload?: boolean;
}
