import { memoize } from 'lodash';
import { IndexedStorage, NativeStorage, QueryableStorage, SyncModule } from '@weavix/sync';
import { PartitionEventStorage } from '@weavix/sync/src/storage/partition-event-storage';

function indexMessage(cm: any) {
    return `${cm.channelId}|${cm.date instanceof Date ? cm.date.toISOString() : cm.date}`; // <channel id>|<date>
}

function indexMessagePartition(index: string) {
    const pipe = index.indexOf('|');
    return index.substring(0, pipe + 11); // Just by day
}

function indexId(value: any) {
    return value.id;
}

function indexIdPartition(index: string) {
    return index.substring(index.length - 1);
}

type CreateStorage = new (name: string) => NativeStorage;
let storage: CreateStorage = function() { throw new Error('Call configureStorage first'); } as any;
export function configureStorage(creator: CreateStorage) {
    storage = creator;
}

export const getAccountStorage = memoize(<T extends { id: string; }>(name: string) => {
    let userTopic = false;
    let accountTopic = false;
    let staticTopic = false;
    let sync = false;
    let priority = false;
    switch (name) {
        case 'statuses':
        case 'notifications':
        case 'settings':
        case 'meetings':
        case 'channels':
        case 'channel-preferences':
        case 'channel-messages':
            userTopic = true;
            break;
        case 'companies':
            accountTopic = true;
            staticTopic = true;
            break;
        case 'users':
            userTopic = true;
            accountTopic = true;
            break;
        case 'connections':
            userTopic = true;
            break;
        default:
            sync = true;
            accountTopic = true;
            break;
    }
    let indexFn = undefined;
    let indexPart = undefined;
    switch (name) {
        case 'channel-messages':
            indexFn = indexMessage;
            indexPart = indexMessagePartition;
            break;
        case 'people':
        case 'users':
            priority = true;
        case 'facility-people':
            indexFn = indexId;
            indexPart = indexIdPartition;
            break;
    }

    const lazy = !priority && accountTopic;
    const newStorage = new storage(name);
    const indexedStorage = new IndexedStorage<T>(newStorage, indexFn, indexPart);
    class Module extends SyncModule<T> {
        storage = indexedStorage;
        eventStorage = new PartitionEventStorage(newStorage, indexedStorage);
        userId: string;

        constructor() {
            super(lazy);
        }
    
        watchPartitions() {
            return this.watchUser(user => {
                if (!user) return null;
                this.userId = user.id;
                return (staticTopic ? ['root'] : [])
                    .concat(userTopic ? [user.id] : [])
                    .concat(accountTopic ? user.accounts.map(x => x.id) : []);
            });
        }
    
        getTopic(topic: string) {
            return topic === 'root' ? `account/${name}`
                : topic === this.userId ? `user/${topic}/${name}`
                : `account/${topic}${sync ? '/sync' : ''}/${name}`;
        }
    }
    class Storage extends QueryableStorage<T> {
        static Instance = memoize(() => new Storage(new Module().storage));
    }
    return Storage;
});
