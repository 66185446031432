import { Currency, Language } from '@weavix/models/src/translation/translation';
import { Observable, ReplaySubject } from 'rxjs';

export abstract class TranslationServiceStub {
    abstract activeLanguage: Language;
    abstract language$: ReplaySubject<Language>;

    abstract setInitialLanguage(): void;
    abstract setLanguage(lang: string): void;
    abstract getLanguage(): string;
    abstract getLanguageString(): string;
    abstract getImmediate(key: string, params?, recursive?: boolean): string;
    abstract getTranslation(key: string, params?): Promise<string>;
    abstract getTranslations(keys: string[], params?): Observable<{ [key: string]: string }>;
    abstract getCurrencies(): Currency[];
    abstract getCurrency(code: string);
    abstract numberToCurrency(num: number, code: string): string;
    abstract getLocale();
}
