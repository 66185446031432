import { NativeStorage, SyncService } from '@weavix/sync';
import { environment } from 'environments/environment';
import * as localForage from 'localforage';

export class WebNativeStorage implements NativeStorage {
    private db: LocalForage;

    constructor(public name: string) {
        this.db = localForage.createInstance({ name: `${environment.teamsApp ? 'teams-' : ''}${name}-${SyncService.version}` });
    }

    async clear() {
        await this.db.clear();
    }

    clearMemory() {
        // noop
    }

    public async getMap(key: string): Promise<{ [key: string]: unknown; }> {
        return await this.db.getItem(key);
    }

    public async setMap(key: string, map: { [key: string]: unknown; }): Promise<void> {
        await this.db.setItem(key, map);
    }
}
