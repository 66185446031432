import { Subject } from 'rxjs';

import { Utils } from '@weavix/components/src/utils/utils';
import { MapGeofence } from '@weavix/models/src/map/map';
import { PermissionAction } from '@weavix/permissions/src/permissions.model';
import { GeofenceServiceStub } from '@weavix/services/geofence.service';
import { ProfileServiceStub } from '@weavix/services/profile.service';
import { TeamsServiceStub } from '@weavix/services/teams.service';
import { StateServiceBase, StateUpdate } from './state-base.service';


export class GeofenceStateService extends StateServiceBase {
    geofences: Map<string, MapGeofence> = new Map();
    geofences$: Subject<StateUpdate<MapGeofence>> = new Subject();

    constructor(
        private geofenceService: GeofenceServiceStub,
        private profileService: ProfileServiceStub,
        private teamsService: TeamsServiceStub
    ) {
        super();
    }

    async loadData(c: any, accountId: string, facilityId?: string, tags?: string[]) {
        if (!this.profileService.hasPermission(PermissionAction.ViewSites, facilityId, null, accountId) && !this.teamsService.isTeamsOnlyUser()) return;
        this.geofences = Utils.toMap(await this.geofenceService.getAll(c, facilityId, tags));
    }

    async stop() {
        super.stop();
        this.geofences.clear();
    }

    protected async setupSubscriptions(c: any,  accountId: string, facilityId?: string) {
        if (!this.profileService.hasPermission(PermissionAction.ViewSites, facilityId, null, accountId) && !this.teamsService.isTeamsOnlyUser()) return;

        return this.geofenceService.subscribeGeofenceUpdates(c).subscribe(result => {
            const geofenceMap = result.payload;
            this.updateFromMap(this.geofences, geofenceMap, this.geofences$);
        });
    }
}
