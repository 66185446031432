import { Injectable } from '@angular/core';
import { EnvironmentServiceStub } from '@weavix/services/src/environment.service';
import { environment } from 'environments/environment';

@Injectable()
export class EnvironmentService extends EnvironmentServiceStub {
    constructor() {
        super();
    }

    getRadioUrl(): string {
        return environment.radioUrl;
    }
}
