import { Component, Input, OnInit } from '@angular/core';
import { TeamsServiceStub } from '@weavix/services/src/teams.service';

@Component({
    selector: 'app-logo-spinner',
    templateUrl: './logo-spinner.component.html',
    styleUrls: ['./logo-spinner.component.scss']
})
export class LogoSpinnerComponent implements OnInit {

    @Input() size: number;
    @Input() diameter = 30;
    @Input() slow: boolean;
    @Input() showMessage = true;

    constructor(public teamsService: TeamsServiceStub) { }

    ngOnInit(): void {
    }

}
