import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Icon } from '@weavix/models/src/icon/icon';
import { RowEdit, RowEditAction, RowItem, RowItemType, TableColumn, TableRow } from '@weavix/models/src/table/table';
import { TeamsServiceStub } from '@weavix/services/src/teams.service';
import { ThemeServiceStub } from '@weavix/services/src/theme.service';
import { TranslationServiceStub } from '@weavix/services/src/translation.service';

import { AutoUnsubscribe } from '../../utils/utils';

@AutoUnsubscribe()
@Component({
    selector: 'app-table-row',
    templateUrl: './table-row.component.html',
    styleUrls: ['./table-row.component.scss', './table-row-teams.component.scss']
})
export class TableRowComponent implements OnInit {
    @Input() row: TableRow;
    @Input() colKey: {[key: string]: TableColumn};
    @Input() moveTooltip: string;
    @Input() dummyEdits = [];
    @Input() icon: Icon;

    @Output() checkAction: EventEmitter<void> = new EventEmitter();
    @Output() columnClick: EventEmitter<RowItem> = new EventEmitter();
    @Output() colPrefixClick: EventEmitter<RowItem> = new EventEmitter();
    @Output() colPostfixClick: EventEmitter<RowItem> = new EventEmitter();
    @Output() editClick: EventEmitter<RowEdit> = new EventEmitter();
    @Output() overrideSelection: EventEmitter<RowItem> = new EventEmitter();

    rowItemType = RowItemType;
    subTableLeftOffset: string;
    lightTheme: boolean;


    constructor(
        public teamsService: TeamsServiceStub,
        public translateService: TranslationServiceStub
    ) { }

    ngOnInit() {
        this.lightTheme = ThemeServiceStub.getLightTheme();

        if (this.row.subTable) this.setSubTableRightAlignOffset();
    }

    // offset to the right by the difference in columns. Currently assuming columns are equal widths
    setSubTableRightAlignOffset(): void {
        const parentColCount: number = this.row.items.length;
        const subColCount: number = this.row.subTable?.options?.columns?.length;
        const colDiff: number = parentColCount - subColCount;
        if (colDiff > 1) this.subTableLeftOffset = `${(colDiff / parentColCount) * 100}%`;
    }

    select(event: Event) {
        event.preventDefault();
        event.stopImmediatePropagation();
        this.checkAction.emit();
    }

    column(event: Event, item: RowItem, override = false) {
        if (event) {
            event.preventDefault();
            event.stopImmediatePropagation();
        }
        if (override) this.overrideSelection.emit(item);
        this.columnClick.emit(item);
    }

    prefix(event: Event, item: RowItem) {
        event.preventDefault();
        event.stopImmediatePropagation();
        this.colPrefixClick.emit(item);
    }

    postfix(event: Event, item: RowItem) {
        event.preventDefault();
        event.stopImmediatePropagation();
        this.colPostfixClick.emit(item);
    }

    edit(event: Event, edit: RowEdit) {
        event.preventDefault();
        event.stopImmediatePropagation();
        this.editClick.emit(edit);
    }

    chooseFolder(event: Event) {
        event.preventDefault();
        event.stopImmediatePropagation();
        this.editClick.emit({ title: null, icon: null, action: RowEditAction.move });
    }

    handleShowSubRowItems(event: Event): void {
        event.preventDefault();
        event.stopImmediatePropagation();
        this.row.subTable.show = !this.row.subTable.show;
    }
}
