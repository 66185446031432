import { Injectable } from '@angular/core';
import { TeamsServiceStub } from '@weavix/services/src/teams.service';
import { myUser } from 'crews/app/models-mobx/users-store/users-store';
import { environment } from 'environments/environment';

@Injectable()
export class TeamsService extends TeamsServiceStub {
    constructor() {
        super();
    }

    public isTeamsApp(): boolean {
        return environment.teamsApp;
    }

    public isTeamsOnlyUser(): boolean {
        return myUser().teamsOnlyUser;
    }
}
