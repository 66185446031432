import { Injectable } from '@angular/core';
import { FaceapiServiceStub } from '@weavix/services/src/faceapi.service';
declare let faceapi: any;

@Injectable()
export class FaceapiService extends FaceapiServiceStub {

    private options: any = { inputSize: 256, scoreThreshold: 0.5 };

    constructor() {
        super();
        (async () => { await this.init(); })();
    }

    private async init() {
        await faceapi.nets.tinyFaceDetector.loadFromUri('/assets/js/models');
    }

    public async detectFaces(element: HTMLVideoElement | HTMLImageElement): Promise<any[]> {
        if (!faceapi.nets.tinyFaceDetector.isLoaded) {
            console.warn('tinyFaceDector is still loading');
            return [];
        }
        if (element == null) {
            console.error('element is empty');
            return [];
        }
        return await faceapi.tinyFaceDetector(element, this.options);
    }
}
