import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';

export interface ComponentCanDeactivate {
    canDeactivate: () => boolean | Promise<boolean> | Observable<boolean>;
}

@Injectable()
export class DeactivateGuard implements CanDeactivate<ComponentCanDeactivate> {

    constructor(
    ) {}

    async canDeactivate(component: ComponentCanDeactivate) {
        if (!component.canDeactivate || await component.canDeactivate()) {
            return true;
        } else {
            return false;
        }
    }
}
