import { Injectable } from '@angular/core';
import { Geofence, MapFilterResult, MapFilterView } from '@weavix/models/src/map/map';
import { MapSearchServiceStub } from '@weavix/services/src/map-search.service';
import { BoxTree } from '../utils/polygon';

@Injectable()
export class MapSearchService extends MapSearchServiceStub {
    constructor() {
        super();
        this.setActiveView();
    }

    setActiveSearchFilter(cat?: MapFilterResult) {
        this.selectedFilter = cat;
        this.activeSearchFilter$.next(cat);
    }

    setSelection(selection?: MapFilterResult): void {
        this.selectedResult = selection;
    }

    updateSearch(query: string = this.activeSearchQuery): void {
        this.activeSearchQuery = query;
        this.updateSearch$.next(query);
    }

    getActiveView(): MapFilterView {
        return this.activeViewStack[this.activeViewStack.length - 1];
    }

    getFilter(geofenceMap: Map<string, Geofence>) {
        const ids = this.getActiveView() === MapFilterView.PersonDetail ? { people: { [this.selectedResult?.key]: true } }
            : { people: this.selectedFilter?.children ?? {}, geofences: this.selectedFilter?.filters?.geofences ?? {} };

        const hasFilter = Object.keys(ids.people).length;
        const hasGeofence = ids.geofences && Object.keys(ids.geofences).length;

        const tree = new BoxTree<Geofence, any>((obj) => obj.vertices, 0);
        if (hasGeofence) {
            Object.keys(ids.geofences).forEach(id => {
                const geofence = geofenceMap?.get(id);
                if (geofence) tree.add(geofence);
            });
        }
        return (personId, location) => {
            if (hasFilter && !ids.people[personId]) return false;
            if (!hasGeofence) return true;
            if (typeof location === 'string') return ids.geofences[location];
            return tree.findQuick(location);
        };
    }

    setActiveView(view?: MapFilterView): void {
        if (view && view === MapFilterView.EntitySearch && this.getActiveView() === MapFilterView.PersonDetail) {
            this.activeViewStack.pop();
        }
        if (view && view !== this.getActiveView()) this.activeViewStack.push(view);

        this.activeViewChange$.next(this.getActiveView());
    }

    // clear filter data without emitting
    silentClearViewStack() {
        this.selectedResult = null;
        this.selectedFilter = null;
        this.activeViewStack = [MapFilterView.Filter];
    }

    clearViewStack() {
        this.setSelection();
        this.setActiveSearchFilter();
        this.activeViewStack = [MapFilterView.Filter];
        this.activeViewChange$.next(this.getActiveView());
    }

    clearFilterSelection() {
        this.setActiveSearchFilter();
    }

    clearResultSelection(): void {
        this.setSelection();
        this.handleResultClick$.next(null);
    }

    goBack(): void {
        this.activeViewStack.pop();
        this.activeViewChange$.next(this.getActiveView());
        this.clearResultSelection();
    }

}
