<div class="table-row-wrapper" [class.teams]="teamsService.isTeamsApp()">
    <div class="table-row" [ngClass]="{'table-row-clickable': row.clickable, 'table-row-highlighted': row.highlighted, 'light': lightTheme}">
        <div *ngIf="row.subTable" class="table-col checkbox" (click)="handleShowSubRowItems($event); false">
            <app-icon *ngIf="row.subTable.show" faIcon="fas fa-chevron-up"></app-icon>
            <app-icon *ngIf="!row.subTable.show"  faIcon="fas fa-chevron-down"></app-icon>
        </div>
    
        <div *ngIf="row.checkbox" class="table-col checkbox">
            <mat-checkbox (click)="select($event); false" [checked]="row.selected"></mat-checkbox>
        </div>
    
        <div *ngIf="row.locked" class="table-col checkbox">
            <app-icon faIcon="fas fa-lock-alt"></app-icon>
        </div>
    
        <div *ngIf="icon" class="table-col checkbox">
            <app-icon [icon]="icon"></app-icon>
        </div>
        
        <div class="table-col" *ngFor="let item of row.items" [ngClass]="[item.clickable ? 'clickable' : '', item.class ? item.class : '']" (click)="item.clickable && column($event, item, item.overridesSelection)"
            [ngStyle]="{'min-width.px': item.minWidth, 'max-width.px': item.maxWidth}">
            <app-loading *ngIf="item.pending" class="transparent mR5" [loading]="item.pending" [diameter]="20" [inline]="true"></app-loading>
            <span matPrefix [matTooltip]="translateService.getImmediate(item.prefix.tooltip)" *ngIf="item.prefix" [innerHtml]="item.prefix.value | safeHtml" class="prefix" (click)="item.prefix.clickable && prefix($event, item)"></span>

            <ng-container [ngSwitch]="colKey[item.colKey].type">
                <div class="col-val" *ngSwitchCase="rowItemType.text" [matTooltip]="item.tooltip">
                    <span *ngIf="colKey[item.colKey]?.translate" [innerHTML]="translateService.getImmediate(item.value)"></span>
                    <span *ngIf="!colKey[item.colKey]?.translate" [innerHTML]="item.value"></span>
                </div>
        
                <div class="col-val" *ngSwitchCase="rowItemType.number">
                    {{item.value}}
                </div>
        
                <div class="col-val" *ngSwitchCase="rowItemType.date">
                    {{item.value ? (item.value | date:'short') : '-'}}
                </div>
        
                <div class="col-val" *ngSwitchCase="rowItemType.time">
                    {{item.value ? (item.value | date:'shortTime') : '-'}}
                </div>
        
                <div class="col-val" *ngSwitchCase="rowItemType.toggle">
                    <mat-slide-toggle [color]="'accent'" [checked]="item.value" (change)="column(null, item)"></mat-slide-toggle>
                </div>
        
                <div class="col-val" matTooltip="{{item.value | date:'short'}}" matTooltipPosition="after" *ngSwitchCase="rowItemType.timeAgo">
                    {{item.value | timeAgo}}
                </div>
        
                <div class="col-val" *ngSwitchCase="rowItemType.checkbox">
                    {{item.value}}
                </div>

                <div class="col-val person-col" *ngSwitchCase="rowItemType.person">
                    <ng-container *ngTemplateOutlet="personColType; context: {value: item.value}"></ng-container>
                </div>
        
                <div class="icon" *ngSwitchCase="rowItemType.icon">
                    <app-icon *ngIf="item.value" [icon]="item.value"></app-icon>
                </div>
        
                <div class="col-val" *ngSwitchCase="rowItemType.color">
                    <div class="color" [ngStyle]="{'background-color': item.value}"></div>
                </div>
            </ng-container>
    
            <span matPrefix *ngIf="item.postfix"
                [matTooltip]="translateService.getImmediate(item.postfix?.tooltip || '')"
                [innerHtml]="item.postfix.value | safeHtml"
                class="postfix"
                (click)="item.postfix.clickable && postfix($event, row)"
            ></span>
        </div>
        
        <ng-container *ngIf="dummyEdits.length || row.edits.length">
            <div *ngIf="!teamsService.isTeamsApp()" class="table-col edit-col flexRow jcC aiC">
                <div class="table-col-icons">
                    <button class="invisible" *ngFor="let d of dummyEdits"></button>
                    <div *ngFor="let e of row.edits" [matTooltip]="translateService.getImmediate(e.title)">
                        <button (click)="edit($event, e)" [ngClass]="{disabled: e.disabled}">
                            <i [ngClass]="e.icon"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div *ngIf="teamsService.isTeamsApp()" class="table-col edit-col flexRow jcC aiC" [matMenuTriggerFor]="tableEdits" (click)="column($event, null, true)">
                <button class="p0"><app-icon faIcon="fas fa-ellipsis-h"></app-icon></button>
                <mat-menu #tableEdits="matMenu">
                    <div *ngFor="let e of row.edits" mat-menu-item class="dropdown-item" [class.hidden]="e.show && e.show(row) === false" (click)="edit($event, e)" [ngClass]="{disabled: e.disabled}">
                        <i class="icon" [ngClass]="e.icon"></i><div>{{ translateService.getImmediate(e.title) }}</div>
                    </div>
                </mat-menu>
            </div>
        </ng-container>
    </div>

    <div [ngStyle]="{'paddingLeft': subTableLeftOffset ? subTableLeftOffset : 0}"
        class="table-row-wrapper sub-table"
        *ngIf="row?.subTable?.show && row?.subTable?.rows && row?.subTable?.options"
    >
        <app-table [rows]="row?.subTable?.rows" [options]="row?.subTable?.options"></app-table>
    </div>
</div>

<ng-template #personColType let-value="value">
    <div class="avatar">
        <app-avatar
            [avatarInput]="{
                'height': 30,
                'width': 30,
                'img': value.avatarFile,
                placeholder: {
                    'icon': 'fas fa-user',
                    'iconColor': 'white'
                }
            }"
            [readonly]="true"
        ></app-avatar>
    </div>

    <div class="person-info">
        <p class="name" *ngIf="value?.fullName">
            {{value.fullName}}
        </p>

        <div class="item value crafts" *ngIf="value?.crafts?.length">
            <p class="sub-text crafts-list">
                <ng-container *ngFor="let craft of value.crafts; let isLast=last">
                    {{craft}}{{isLast ? '' : ', '}}
                </ng-container>
            </p>
        </div>
    </div>

    <div class="company-logo" *ngIf="value?.company?.logo">
        <img [src]="value.company.logo">
    </div>
</ng-template>
    