<div *ngIf="control"
    (clickOutside)="control.options && displayOptions ? closeOptions() : null"
    appClickOutside
    class="control-container"
    [class.teams]="teamsApp"
>
    <app-callout *ngIf="control?.callout" [options]="control.callout"></app-callout>

    <button
        [id]="control.id"
        class="control gray-dk circle p0"
        [ngClass]="{'active-control': (control.isActive && control.isActive() && !control.color), 'inactive-control': !(control.isActive && control.isActive()) && !control.color}"
        [ngStyle]="{'background-color': (control.isActive && control.isActive() && control.color) ? colors.WHITE : control.color}"
        [matTooltip]="translateService.getImmediate(control.tooltip)"
        matTooltipPosition="before"
        (click)="control.options ? toggleOptions() : control.onClick()"
        (mouseenter)="buttonHovers[control.id]=true"
        (mouseleave)="buttonHovers[control.id]=false"
    >
        <app-icon [faIcon]="buttonHovers[control.id] ? (control.iconHover ?? control.icon) : control.icon" [color]="teamsApp ? '' : (control.isActive && control.isActive() ? colors.GRAY_DK : colors.WHITE)"></app-icon>
    </button>

    <div *ngIf="displayOptions" class="control-options">
        <ng-container *ngFor="let option of control.options">
            <div 
                *ngIf="!(option?.hide && option.hide())"
                (click)="handleControlOptionClick(option)"
                [ngClass]="{
                    'active-control': (option.isActive && option.isActive() && !option.color),
                    'inactive-control': !(option.isActive && option.isActive()) && !option.color
                }"
                class="option"
                (mouseenter)="buttonHovers[option.id]=true"
                (mouseleave)="buttonHovers[option.id]=false"
            >
                <app-icon [faIcon]="buttonHovers[option.id] ? (option.iconHover ?? option.icon) : option.icon" class="mR5"></app-icon>
                <p>{{ translateService.getImmediate(option.tooltip) }}</p>
            </div>
        </ng-container>
    </div>
</div>
