import { Injectable } from '@angular/core';
import { WaltServiceStub } from '@weavix/services/src/walt.service';
import { Topic } from '@weavix/models/src/topic/topic';
import { Walt } from '../models/walt.model';
import { AccountService } from './account.service';
import { HttpService } from './http.service';
import { PubSubService } from './pub-sub.service';


@Injectable()
export class WaltService extends WaltServiceStub {
    constructor(
        private httpService: HttpService,
        private pubSubService: PubSubService,
        private accountService: AccountService,
    ) {
        super();
    }

    static cacheCollection = 'walt';

    static url = (id?: string) => id ? `/track/walt/${id}` : `/track/walt`;

    getAllByFacility(component: any, facilityId: string) {
        return this.httpService.get<Walt[]>(component, `${WaltService.url()}/facility/${facilityId}`);
    }

    getAllByAccount(component: any, accountId: string) {
        return this.httpService.get<Walt[]>(component, `${WaltService.url()}/account/${accountId}`);
    }

    subscribeWaltUpdates(w: any) {
        return this.pubSubService.subscribe<{[key: string]: Walt}>(w, Topic.AccountWaltUpdated, [this.accountService.getAccountId()]);
    }

}
