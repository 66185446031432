import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';

import { AlertService } from 'weavix-shared/services/alert.service';
import { AnalyticsService, StAction, StObject } from '../analytics.service';
import { MediaService } from './media.service';

import {
    AddMeetingAttendeesRequest,
    AnnotateType, CreateMeetingRequest, MediaEndedReason, Meeting,
    MeetingAnnotateUpdate, MeetingAttendee, MeetingToken,
} from 'weavix-shared/models/media.model';
import { Person } from 'weavix-shared/models/person.model';

import { ProfileService } from 'weavix-shared/services/profile.service';
import { sleep } from 'weavix-shared/utils/sleep';

import { MeetingServiceStub } from '@weavix/services/src/meeting.service';
import { AutocompleteOption } from 'crews/app/shared/input/input.model';
import { debounce, DebouncedFunc } from 'lodash';
import { v4 as uuid } from 'uuid';
import { LocalDeviceUtility } from './local-device-utility';
import { MediasoupConnection } from './mediasoup-connection';
import { PttService } from './ptt.service';
import { WebrtcConnection } from './webrtc-connection';
import { WebrtcInterface, WEBRTC_CONNECTIONS } from './webrtc-interface';

const useMediasoup = true;
const rtcConnection = useMediasoup ? MediasoupConnection : WebrtcConnection;

export interface PushStream {
    id: string;
    close: Function;
    closed: boolean;
    stream$: Subject<MediaStream>;
    stream: MediaStream;
    connectionId?: string;
    attendee?: MeetingAttendee;
    personId?: string;
    meetingId?: string;
    self?: boolean;
    connected?: boolean;
    audioEnabled?: boolean;
    resize?: ((fraction: any) => Promise<void>) & DebouncedFunc<any>;
    toggleAudio?: (enabled: boolean) => Promise<void>;
    videoEnabled?: boolean;
    videoMirrored?: boolean;
    toggleVideo?: (enabled: boolean) => Promise<void>;
    toggleScreenShare?: (enabled: boolean) => Promise<boolean>;
    screenShareEnabled?: boolean;
    swapAudio?: (deviceId: string) => Promise<boolean>;
    swapVideo?: (deviceId: string) => Promise<boolean>;
    attendeeUpdate?: Observable<Partial<MeetingAttendee>>;
    annotateUpdate?: Observable<MeetingAnnotateUpdate>;
    annotateHistory: MeetingAnnotateUpdate[];
    poorConnection: boolean;
}

const commonAudio = false;

@Injectable()
export class MeetingService extends MeetingServiceStub {

    constructor(
        private mediaService: MediaService,
        private alertsService: AlertService,
        private profileService: ProfileService,
        private pttService: PttService,
    ) {
        super();
    }
    get streamCount() { return Object.keys(this.streams).length; }

    static MEETING_INVITE_EMAILS_STORAGE_KEY = 'meeting-invite-emails';

    newMeeting: CreateMeetingRequest = {};

    newMeetingAttendees: string[];

    person: Person;
    meetingToken: MeetingToken;
    audioInputEnabled: boolean;
    videoEnabled: boolean;
    screenStream: MediaStreamTrack;
    videoDeviceId: string;
    audioInputDeviceId: string;
    audioOutputDeviceId: string;

    private newStreamSub: Subscription;
    private viewers: { [key: string]: MeetingAttendee } = {};
    myStream: PushStream;
    myStreamUpdate$: Subject<PushStream> = new Subject<PushStream>();
    myStreamLoading$: Subject<boolean> = new Subject();
    streams: { [key: string]: PushStream } = {};
    addStream$: Subject<PushStream> = new Subject<PushStream>();
    removeStream$: Subject<PushStream> = new Subject<PushStream>();
    streamLoading$: Subject<string> = new Subject<string>();
    audioOutputDevice$: Subject<string> = new Subject();

    audioStream: PushStream;
    audioStream$ = new Subject<PushStream>();

    attendeeUpdate$: Subject<MeetingAttendee> = new Subject<MeetingAttendee>();

    meetingDisconnect$: Subject<Meeting> = new Subject<Meeting>();

    annotationEnabled$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
    fullScreenStream$: Subject<PushStream> = new Subject<PushStream>();
    mapOpen$: Subject<boolean> = new Subject<boolean>();
    private attendeeAnnotationHistory: { [authorAttendeeId: string]: { [targetAttendeeId: string]: MeetingAnnotateUpdate[] } } = {}; // Used for undoing attendee annotations
    private targetAnnotationHistory: { [targetAttendeeId: string]: MeetingAnnotateUpdate[] } = {}; // Used for reloading annotations

    attendeesInfo: { [personId: string]: Partial<MeetingAttendee> } = {};

    userStreamMaximized: boolean;
    userStreamInGrid: boolean;

    static getEmailAutoCompleteOptions(): AutocompleteOption[] {
        return JSON.parse(localStorage.getItem(MeetingService.MEETING_INVITE_EMAILS_STORAGE_KEY) ?? '[]');
    }

    static storeEmail(email: string): AutocompleteOption[] {
        const localEmails: AutocompleteOption[] = JSON.parse(localStorage.getItem(MeetingService.MEETING_INVITE_EMAILS_STORAGE_KEY) ?? '[]');
        let localEmail: AutocompleteOption = localEmails.find(e => e.value === email);
        if (localEmail) {
            localEmail.priority++;
        } else {
            localEmail = { value: email, priority: 0 };
            localEmails.push(localEmail);
        }
        localStorage.setItem(MeetingService.MEETING_INVITE_EMAILS_STORAGE_KEY, JSON.stringify(localEmails));
        return localEmails;
    }

    async checkValidMeeting(component, meetingId: string, accountId: string) {
        try {
            return await this.mediaService.getMeeting(component, meetingId, accountId);
        } catch (e) {
            if (e instanceof HttpErrorResponse) {
                if (e.error?.message === 'NOT_FOUND') return null;
                if (e.error?.message === 'FORBIDDEN') return null;
            }
            throw e;
        }
    }

    async checkSelfAttendance(component, meetingId: string): Promise<boolean> {
        try {
            const userPersonId = (await this.profileService.getUserProfile(this)).id;
            const attendees = await this.mediaService.getMeetingAttendees(component, meetingId);
            const found = attendees.find(a => a.id?.personId === userPersonId && a.connectionId && !a.disconnected);
            return found ? true : false;
        } catch (e) {
            throw e;
        }
    }

    async setNewMeeting(newMeeting: Partial<Meeting>) {
        this.newMeeting = newMeeting;

        const userPersonId = (await this.profileService.getUserProfile(this)).id;
        if (!this.newMeeting.people.some(p => p.personId === userPersonId)) this.newMeeting.people.push({ personId: userPersonId });
    }

    async createMeeting(component, join: boolean) {
        AnalyticsService.track(StObject.Meeting, StAction.Created, this.constructor.name);

        this.newMeeting.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const meeting = await this.mediaService.createMeeting(component, this.newMeeting);
        if (join) this.joinedMeeting = meeting;
        return meeting;
    }

    async leaveMeeting() {
        AnalyticsService.track(StObject.Meeting, StAction.Left, this.constructor.name);

        // Send event to clear any of my own annotations on my stream
        if (this.joinedMeeting != null) await this.sendAnnotationClearEvent(this.joinedMeeting.id, this.person.id, this.person.id);

        const streamers = Object.values(this.streams);
        if (this.myStream && !streamers.some(x => x.id === this.myStream.id)) streamers.push(this.myStream);
        if (streamers.length) {
            await Promise.race([
                sleep(5000),
                Promise.all(streamers.map(async x => {
                    this.streams[x.id]?.resize?.cancel();
                    try {
                        await x.close();
                    } catch (e) {
                        console.error(e);
                    }
                    delete this.streams[x.id];
                })),
            ]);
        }

        this.newMeetingAttendees = null;
        this.mediaService.meetingId = null;
        this.joinedMeeting = null;
        this.audioInputDeviceId = null;
        this.videoDeviceId = null;
        this.attendeeAnnotationHistory = {};
        this.targetAnnotationHistory = {};
        this.newStreamSub?.unsubscribe();
    }

    async joinMeeting(component, meetingId: string, accountId: string, videoEnabled: boolean, videoDeviceId: string, audioEnabled: boolean, audioInputDeviceId: string, audioOutputDeviceId: string) {
        await this.leaveMeeting();

        try {
            const meeting = await this.mediaService.getMeeting(component, meetingId, accountId);
            this.joinedMeeting = meeting;
            this.mediaService.meetingId = meeting.id;

            this.videoEnabled = videoEnabled;
            this.videoDeviceId = videoDeviceId;
            this.audioInputEnabled = audioEnabled;
            this.audioInputDeviceId = audioInputDeviceId;
            this.audioOutputDeviceId = audioOutputDeviceId;

            await this.mediaService.updateMyAttendee(component, meeting.id, {
                audioEnabled: audioEnabled,
                videoEnabled: videoEnabled,
                videoMirrored: true,
                videoOrientation: 'landscape',
            });

            AnalyticsService.track(StObject.Meeting, StAction.Joined, this.constructor.name);

            return true;
        } catch (e) {
            this.alertsService.sendError(e, 'ERRORS.MEETING.JOIN');
            return false;
        }
    }

    async inviteToMeeting(component: any, meetingId: string, people: { personId?: string, email?: string }[]) {
        const update: AddMeetingAttendeesRequest = { people };
        const meeting = await this.mediaService.addMeetingAttendees(component, meetingId, update);
        this.joinedMeeting = meeting;
        this.meetingUpdate$.next(meeting);
    }

    async startMeetingStreams(component, meetingId: string, admitter: ((attendee: MeetingAttendee) => Promise<boolean>)) {
        if (this.joinedMeeting.id !== meetingId) return;

        (await this.mediaService.subscribeConnections(component, meetingId, this.person.id)).subscribe(obj => {
            const payload = obj.payload;
            if (payload) {
                WEBRTC_CONNECTIONS[payload.connectionId]?.[payload.event]?.(payload.data);
            }
        });

        const attendees = await this.mediaService.getMeetingAttendees(component, meetingId);
        const self = attendees.find(x => x.id.personId === this.person.id);

        if (commonAudio) {
            const payload = { id: { personId: 'audio' }, connectionId: 'audio' };
            try { await this.viewerStream(component, meetingId, payload); } catch (e) { console.error(e); }
        }
        attendees.forEach(async x => {
            this.attendeesInfo[x.id.personId] = x;
            if (x.id) this.attendeeUpdate$.next(x);
            try { await this.viewerStream(component, meetingId, x); } catch (e) { console.error(e); }
        });
        const broadcastPromise = this.broadcastStream(component, meetingId, self);

        this.mediaService.subscribeAttendeeDisconnects(component, meetingId, this.person.id).subscribe(obj => {
            this.meetingDisconnect$.next(obj.payload);
        });

        const creator = this.person.id === this.joinedMeeting.creator;
        this.newStreamSub = this.mediaService.subscribeAttendeeUpdates(component, this.joinedMeeting.id, '+').subscribe(async x => {
            if (x.payload.waited && !x.payload.admitted && !x.payload.rejected && creator) {
                this.pttService.playAsset('ding');
                const result = await admitter(x.payload as MeetingAttendee);
                if (result) {
                    await this.mediaService.updateInvitee(component, meetingId, { personId: x.payload.id.personId, admitted: true });
                } else {
                    await this.mediaService.updateInvitee(component, meetingId, { personId: x.payload.id.personId, rejected: true });
                }
            }
            this.attendeesInfo[x.replacements[1]] = { ...(this.attendeesInfo[x.replacements[1]] ?? {}), ...x.payload } as any;
            this.attendeeUpdate$.next(x.payload as MeetingAttendee);

            if (x.replacements[1] !== this.person.id) {
                try { await this.viewerStream(component, meetingId, this.attendeesInfo[x.replacements[1]] as MeetingAttendee); } catch (e) { console.error(e); }
                if (x.payload.disconnected) {
                    Object.values(this.streams).forEach(y => {
                        if (y.personId === x.replacements[1]) y.close(MediaEndedReason.Closed);
                    });
                }
            }
        });

        await broadcastPromise;

        return attendees;
    }

    async viewerStream(component, meetingId: string, attendee: MeetingAttendee, reset?: boolean) {
        if (this.joinedMeeting.id !== meetingId) return;
        if (!attendee.id) return;

        const { personId } = attendee.id;

        const connectionId = attendee.connectionId;
        if (!connectionId || personId === this.person.id || !reset && this.viewers[connectionId]) {
            return;
        }
        this.viewers[connectionId] = attendee;

        this.streamLoading$.next(personId);

        const personStream = personId !== 'audio';
        let stream: PushStream;
        const subs = [];
        const attendeeUpdates = new BehaviorSubject<Partial<MeetingAttendee>>(attendee);
        const annotateUpdates = new Subject<MeetingAnnotateUpdate>();

        const id = uuid();
        const connection: WebrtcInterface = new rtcConnection(this.mediaService, meetingId, personId, { broadcastId: connectionId, broadcastServer: attendee.connectionServer });
        let closed = false;
        let poorConnection: boolean;
        const close = async (reason) => {
            if (closed) return;
            closed = true;

            // eslint-disable-next-line no-console
            console.log('WEBRTC CLOSING');
            subs.forEach(x => x.unsubscribe());

            if (this.streams[id] === stream) delete this.streams[id];
            if (!Object.values(this.streams).some(x => x.connectionId === connectionId)) delete this.viewers[connectionId];

            if (personStream && stream) this.removeStream$.next(stream);
            connection.close();
        };

        connection.poor$.subscribe(value => poorConnection = stream.videoEnabled && value);
        connection.reconnect$.subscribe(async () => {
            if (closed) return;
            poorConnection = true;
            setTimeout(() => close(MediaEndedReason.Disconnected), 30000);
            const updatedAttendee = personId === 'audio' ? attendee : this.attendeesInfo[personId] as MeetingAttendee;
            if (updatedAttendee?.connectionId !== connectionId) return;
            try { await this.viewerStream(component, meetingId, updatedAttendee, true); } catch (e) { console.error(e); }
        });

        if (personStream) {
            subs.push(this.mediaService.subscribeAttendeeUpdates(component, meetingId, personId).subscribe(obj => {
                attendee = { ...attendee, ...obj.payload };
                attendeeUpdates.next(obj.payload);
            }));

            subs.push(this.mediaService.subscribeAnnotationUpdates(component, meetingId, personId).subscribe(x => {
                this.saveAnnotateUpdate(x.payload, personId);
                // Need replayed annotations. Skip my own live annotations
                if (x.payload.replay || x.payload.attendeeId !== this.person.id) annotateUpdates.next(x.payload);
            }));
        }

        await connection.start();

        const t = this;
        stream = {
            id,
            get stream$() {
                return connection.stream$;
            },
            get stream() {
                return connection.mediaStream;
            },
            get attendee() {
                return attendee;
            },
            personId: personId,
            meetingId,
            get audioEnabled() { return attendee.audioEnabled; },
            get videoEnabled() { return attendee.videoEnabled; },
            get videoMirrored() { return attendee.videoMirrored; },
            self: false,
            connectionId,
            attendeeUpdate: attendeeUpdates,
            annotateUpdate: annotateUpdates,
            get annotateHistory() { return t.getAnnotationHistoryByTarget(personId); },
            resize: debounce(async (fraction) => {
                try {
                    if (closed) return;
                    // const updated = Math.max(0, Math.min(1, fraction));
                    // if (updated !== bandwidth) {
                    //     bandwidth = updated;
                    //     await this.mediaService.setBandwidth(null, server.id, bandwidth);
                    // }
                } catch (e) {
                    console.error(e);
                }
            }, 5000, { trailing: true }),
            get connected() { return connection.connected; },
            close: async (reason?) => {
                await close(reason ?? MediaEndedReason.Closed);
            },
            get closed() { return closed; },
            get poorConnection() { return poorConnection; },
        };
        if (personId === 'audio') {
            this.audioStream = stream;
            this.audioStream$.next(stream);
            this.pttService.playStream(stream.stream);
        }

        const existing = Object.values(this.streams).filter(x => x.personId === stream.personId);
        this.streams[id] = stream;
        if (personStream) this.addStream$.next(stream);
        existing.forEach(x => x.close(MediaEndedReason.Disconnected));
    }

    /**
     * Streams local audio/video devices through negotiated webRTC connection
     *
     * Gets the video and audio tracks based on selections from meeting-join.component.
     * If no video or audio was selected, disabled dummy tracks get used for initial connection.
     * This is done to allow using RTCRtpSender.replaceTrack which does not require webRTC renegotiation,
     * whereas adding/removing tracks does.
     * Overrides RTCPeerConnection.close to handle connection/resource cleanup
     */
    private async broadcastStream(component, meetingId: string, attendee: MeetingAttendee) {
        if (this.joinedMeeting.id !== meetingId) return;

        const tracks = await this.getLocalDeviceTracksFromJoinSettings();
        if (!tracks.audioTrack && !tracks.videoTrack) throw new Error('No media devices');

        this.myStreamLoading$.next(true);

        const connection: WebrtcInterface = new rtcConnection(this.mediaService, meetingId, attendee.id.personId, { ...tracks, screenshare: tracks.videoTrack === this.screenStream });

        const subs = [];
        const attendeeUpdates = new ReplaySubject<Partial<MeetingAttendee>>(1);
        const annotateUpdates = new Subject<MeetingAnnotateUpdate>();

        let closed = false;
        let poorConnection: boolean;
        const id = uuid();
        const close = async (reason) => {
            if (closed) return;
            closed = true;

            subs.forEach(x => x.unsubscribe());

            connection?.close(reason);
            if (this.streams[id] === stream) delete this.streams[id];
            if (this.myStream === stream) {
                this.myStream = null;
                this.myStreamUpdate$.next(null);
            }
        };

        connection.poor$.subscribe(value => poorConnection = stream.videoEnabled && value);
        connection.reconnect$.subscribe(async () => {
            if (closed) return;
            poorConnection = true;
            try { await this.broadcastStream(component, meetingId, attendee); } catch (e) { console.error(e); }
        });

        subs.push(this.mediaService.subscribeAttendeeUpdates(component, this.joinedMeeting.id, this.person.id).subscribe(async obj => {
            attendee = { ...attendee, ...obj.payload };
            attendeeUpdates.next(obj.payload);
        }));

        subs.push(this.mediaService.subscribeAnnotationUpdates(component, this.joinedMeeting.id, this.person.id).subscribe(x => {
            this.saveAnnotateUpdate(x.payload, this.person.id);
            if (x.payload.attendeeId !== this.person.id) {
                annotateUpdates.next(x.payload);
            }
        }));

        let screenShareEnabled: boolean = tracks.videoTrack === this.screenStream;
        const t = this;
        const stream: PushStream = {
            id,
            get stream$() {
                return connection.stream$;
            },
            get stream() {
                return connection.videoStream;
            },
            attendee,
            personId: this.person.id,
            meetingId: this.joinedMeeting.id,
            self: true,
            attendeeUpdate: attendeeUpdates.asObservable(),
            annotateUpdate: annotateUpdates.asObservable(),
            get annotateHistory() { return t.getAnnotationHistoryByTarget(t.person.id); },
            get connected() { return connection.connected; },
            close: async (reason?) => {
                await close(reason ?? MediaEndedReason.Closed);
            },
            swapAudio: async (deviceId: string | boolean) => {
                if (typeof deviceId === 'string') this.audioInputDeviceId = deviceId; // Don't save if switching to empty track
                if (deviceId == null) {
                    connection.setAudio(null);
                    return true;
                }
                const newTracks = await LocalDeviceUtility.getAudioVideoTracks(this.videoEnabled && !screenShareEnabled ? this.videoDeviceId : null, this.audioInputEnabled ? this.audioInputDeviceId : null);
                if (!newTracks.audioTrack) return false;
                connection.setAudio(null);
                connection.setVideo(null);
                await Promise.all([
                    newTracks.audioTrack ? connection.setAudio(newTracks.audioTrack) : null,
                    newTracks.videoTrack ? connection.setVideo(newTracks.videoTrack) : null,
                ]);
                this.myStreamUpdate$.next(this.myStream);
                return true;
            },
            swapVideo: async (deviceId: string | boolean) => {
                if (typeof deviceId === 'string') this.videoDeviceId = deviceId; // Don't save if switching to empty track
                if (deviceId == null) {
                    connection.setVideo(null);
                    return true;
                }
                const newTracks = await LocalDeviceUtility.getAudioVideoTracks(this.videoEnabled && !screenShareEnabled ? this.videoDeviceId : null, this.audioInputEnabled ? this.audioInputDeviceId : null);
                if (!newTracks.videoTrack) return false;
                connection.setAudio(null);
                connection.setVideo(null);
                await Promise.all([
                    newTracks.audioTrack ? connection.setAudio(newTracks.audioTrack) : null,
                    newTracks.videoTrack ? connection.setVideo(newTracks.videoTrack) : null,
                ]);
                this.myStreamUpdate$.next(this.myStream);
                return true;
            },
            get audioEnabled() {
                // Audio sender track will be null if user clicked to disable
                return connection.audioEnabled;
            },
            toggleAudio: async (enable: boolean) => {
                if (!!enable === !!connection.audioEnabled) return;
                this.audioInputEnabled = enable;
                if (enable) {
                    if (!this.audioInputDeviceId) {
                        this.audioInputDeviceId = (await LocalDeviceUtility.getDefaultAudioInputDevice()).deviceId;
                    }
                    if (!await stream.swapAudio(this.audioInputDeviceId)) return;
                } else {
                    await stream.swapAudio(null);
                }
                await this.mediaService.updateMyAttendee(component, this.joinedMeeting.id, { audioEnabled: enable });
            },
            get videoEnabled() {
                // Video sender track will be null if user clicked to disable
                return connection.videoEnabled;
            },
            toggleVideo: async (enable: boolean) => {
                if (!!enable === !!connection.videoEnabled) return;
                this.videoEnabled = enable;
                if (enable) {
                    if (!this.videoDeviceId) {
                        this.videoDeviceId = (await LocalDeviceUtility.getDefaultVideoDevice()).deviceId;
                    }
                    if (!await stream.swapVideo(this.videoDeviceId)) return;
                } else {
                    this.screenStream = null;
                    await stream.swapVideo(null);
                    await resetAnnotations();
                }
                await this.mediaService.updateMyAttendee(component, this.joinedMeeting.id, { videoEnabled: enable, videoMirrored: true });
            },
            toggleScreenShare: async (enabled: boolean): Promise<boolean> => {
                await resetAnnotations();
                if (enabled) {
                    let screenStream: MediaStream;
                    try {
                        screenStream = await LocalDeviceUtility.getScreenStream();
                    } catch (e) {
                        // User may hit cancel on the screen share popup
                        screenShareEnabled = false;
                        return false;
                    }
                    if (screenStream != null && screenStream.getVideoTracks().length > 0) {
                        this.screenStream = screenStream.getVideoTracks()[0];
                        await connection.setVideo(this.screenStream, true);
                        screenShareEnabled = true;
                        connection.setScreenShareEnabled(true);
                    }
                } else {
                    connection.setVideo(null);
                    this.screenStream = null;
                    screenShareEnabled = false;
                    connection.setScreenShareEnabled(false);
                }
                await this.mediaService.updateMyAttendee(component, this.joinedMeeting.id, { videoEnabled: screenShareEnabled, screenShareEnabled, videoMirrored: !screenShareEnabled });

                return true;
            },
            get screenShareEnabled() { return screenShareEnabled; },
            get poorConnection() { return poorConnection; },
            get closed() { return closed; },
        };


        await connection.start();

        const resetAnnotations = async () => {
            const update = await this.sendAnnotationClearEvent(this.joinedMeeting.id, this.person.id, this.person.id);
            annotateUpdates.next(update); // Add directly to subject to fix timing issues with maximizing
        };

        const existing = this.myStream;
        this.myStream = stream;
        this.myStreamUpdate$.next(stream);
        if (this.userStreamInGrid) {
            this.streams[this.myStream.id] = this.myStream;
            this.addStream$.next(this.myStream);
        }
        if (existing) existing.close(MediaEndedReason.Disconnected);
    }

    private async sendAnnotationClearEvent(meetingId: string, attendeeId: string, targetAttendeeId: string) {
        const update: MeetingAnnotateUpdate = {
            meetingId,
            attendeeId,
            draws: [{ type: AnnotateType.Clear }],
        };
        await this.mediaService.publishAnnotationUpdate(meetingId, targetAttendeeId, update);
        return update;
    }

    private saveAnnotateUpdate(u: MeetingAnnotateUpdate, targetAttendeeId: string) {
        const author = u.attendeeId;
        const target = targetAttendeeId;

        if (this.attendeeAnnotationHistory[author] == null) this.attendeeAnnotationHistory[author] = {};
        if (!Array.isArray(this.attendeeAnnotationHistory[author][target])) this.attendeeAnnotationHistory[author][target] = [];
        this.attendeeAnnotationHistory[author][target].push(u);

        if (this.targetAnnotationHistory[target] == null) this.targetAnnotationHistory[target] = [];
        this.targetAnnotationHistory[target].push(u);
    }

    getAttendeeAnnotationHistory(authorAttendeeId: string, targetAttendeeId: string) {
        return this.attendeeAnnotationHistory?.[authorAttendeeId]?.[targetAttendeeId] ?? [];
    }

    private getAnnotationHistoryByTarget(targetAttendeeId: string) {
        return this.targetAnnotationHistory?.[targetAttendeeId] ?? [];
    }

    async getLocalDeviceTracksFromJoinSettings() {
        const videoId = this.videoEnabled && !this.screenStream ? this.videoDeviceId || true : null;
        const audioId = this.audioInputEnabled ? this.audioInputDeviceId || true : null;
        const result = await LocalDeviceUtility.getAudioVideoTracks(videoId, audioId, true);
        if (this.screenStream) result.videoTrack = this.screenStream;
        return result;
    }

    setAudioOutputDeviceId(deviceId: string) {
        this.audioOutputDeviceId = deviceId;
        this.audioOutputDevice$.next(this.audioOutputDeviceId);
    }

    public setAnnotationEnabled(enabled: boolean) {
        this.annotationEnabled$.next(enabled);
    }

    public setFullScreenStream(stream) {
        this.fullScreenStream$.next(stream);
    }

    public maximizeUserStream(maximize: boolean) {
        this.userStreamMaximized = maximize;
        this.setUserStreamInGrid(maximize);
    }

    public setMapOpen(mapOpen: boolean) {
        this.mapOpen$.next(mapOpen);
    }

    public setUserStreamInGrid(userInGrid: boolean) {
        if (!this.myStream) return;

        if (userInGrid && !this.streams[this.myStream.id]) {
            this.streams[this.myStream.id] = this.myStream;
            this.addStream$.next(this.myStream);
            this.userStreamInGrid = true;
        } else if (!userInGrid && this.streams[this.myStream.id]) {
            delete this.streams[this.myStream.id];
            this.removeStream$.next(this.myStream);
            this.userStreamInGrid = false;
        }
    }

    getPersonStream(personId: string): PushStream {
        return personId === this.person.id ? this.myStream : Object.values(this.streams).find(s => s.personId === personId);
    }
}
