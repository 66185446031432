import { PermissionAction, PermissionApiResponse, PermissionApiResponseItem } from './permissions.model';
import { memoize } from 'lodash';

export class PermissionChecker {

    constructor(private permissions: PermissionApiResponse) {}

    public hasFacilitiesPermission = memoize((action: PermissionAction, facilityIds?: string[], folderId?: string): boolean => {
        return this.permissions.grants
            .filter(perm => this.isPermissionInScope(perm, facilityIds, folderId))
            .some(perm => this.isPermissionGranted(perm, action));
    }, (...args) => args.join(':'));

    public hasPermission = memoize((action: PermissionAction, facilityId?: string, folderId?: string): boolean => {
        return this.permissions.grants
            .filter(perm => this.isPermissionInScope(perm, [facilityId], folderId))
            .some(perm => this.isPermissionGranted(perm, action));
    }, (...args) => args.join(':'));

    // Looks for any permission in the given facility or all facilities, including folders
    public hasAnyPermission = memoize((action: PermissionAction, facilityId?: string, folderId?: string): boolean => {
        return this.permissions.grants
            .filter(perm => !facilityId || perm.scope.facilityId === facilityId)
            .filter(perm => !folderId || !perm.scope.folderIds?.length || perm.scope.folderIds?.includes(folderId))
            .some(perm => this.isPermissionGranted(perm, action));
    }, (...args) => args.join(':'));

    hasAccountPermission = (action: PermissionAction) => {
        return this.permissions.enabledActions.includes(action);
    }

    private isPermissionInScope(permission: PermissionApiResponseItem, facilityIds?: string[], folderId?: string): boolean {
        if (facilityIds?.length && permission.scope.facilityId && !facilityIds.includes(permission.scope.facilityId)) {
            return false;
        }
        if (permission.scope.folderIds?.length && !permission.scope.folderIds.some(scopeFolderId => folderId === scopeFolderId)) {
            return false;
        }
        return true;
    }

    private isPermissionGranted(permission: PermissionApiResponseItem, action: PermissionAction): boolean {
        return permission.grantedActions?.some(grantedAction => this.isActionGranted(action, grantedAction));
    }

    private isActionGranted(desiredAction: PermissionAction, grantedAction: PermissionAction): boolean {
        if (!this.permissions.enabledActions.includes(desiredAction)) return false;
        return grantedAction === PermissionAction.AdministerAccount ||
            grantedAction === desiredAction;
    }

}