import {
    computed,
    Context,
    createStore,
    ExtendedModel,
    idProp,
    Model,
    model,
    prop,
} from '@weavix/mobx';
import { SyncService } from '@weavix/sync';
import { BadgeUpdate } from 'weavix-shared/models/event.model';
import { companiesContext } from '../companies-store/companies-store';
import { craftsContext } from '../crafts/crafts-store';
import { myUserId } from '../my-profile-store/my-profile-store';
import { accountPersonRef } from '../people-store/people-store';
import { Batcher } from '@weavix/utils/src/batcher';

export const myUser = () => usersContext.getDefault().get(myUserId());

const REFRESH_AVAILABILITY_EVERY = 5 * 60000;
const badgeUpdater = new Batcher<string, BadgeUpdate[]>(async userIds => {
    const req = userIds.map(userId => ({
        method: 'get', path: `/core/me/badges/${userId}`
    }));
    const results = await SyncService.instance.post<any[]>('/batch', req);
    return results.map(result => result.data ?? []);
});

export interface UserTeamsId {
    accountId: string;
    teamsId: string;
}
@model('User')
export class User extends Model({
    id: idProp,
    aboutMe: prop<string>(),
    avatarFile: prop<string>(),
    bleIds: prop<number[]>(),
    companyId: prop<string>(),
    email: prop<string>(),
    firstName: prop<string>(),
    lastName: prop<string>(),
    locale: prop<string>(),
    middleName: prop<string>(),
    phone: prop<string>(),
    accountIds: prop<string[]>(),
    deleted: prop<boolean>(),
    teamsOnlyUser: prop<boolean>(),
    teamsIds: prop<UserTeamsId[]>(),
    weavixTeamsUserId: prop<string>(),
}) {
    private badgeUpdated: Promise<number>;
    private badges: BadgeUpdate[] = [];

    @computed
    get company() {
        return companiesContext.get().get(this.companyId);
    }

    get myAccountIds() {
        return myUser().accountIds.filter(v => this.accountIds.includes(v));
    }

    get accounts() {
        return this.accountIds.map(x => companiesContext.get().get(x));
    }

    @computed
    get fullName() {
        return `${this.firstName?.trim() ?? ''} ${this.lastName?.trim() ?? ''}`.trim();
    }

    @computed
    get searchText() {
        return `${this.fullName}|${this.company?.name ?? ''}|${this.email}|${this.phone}`?.toLowerCase();
    }

    @computed
    get firstNameLastInitial() {
        return `${this.firstName?.trim() ?? ''} ${this.lastName?.trim()?.[0] ?? ''}`.trim();
    }

    @computed
    get available(): boolean {
        return this.badges.some(x => x.facilities?.length);
    }

    get connected(): boolean {
        return this.badges.some(x => new Date().getTime() - new Date(x.heartbeat).getTime() < 5 * 60000);
    }

    get companyName() {
        return this.company?.name;
    }

    get companyLogo() {
        return this.company?.logo;
    }

    get companyColor() {
        return this.company?.color;
    }

    @computed
    get crafts() {
        return [].concat(
            ...this.accountIds.map(x => accountPersonRef(x, this.id).maybeCurrent?.crafts ?? []),
        );
    }

    @computed
    get accountPerson() {
        const resp = [];
        this.accountIds.map(x => resp.push(accountPersonRef(x, this.id).maybeCurrent));
        resp.push(accountPersonRef(this.companyId, this.id).maybeCurrent);
        return resp.filter(x => x);
    }

    @computed
    get craftNames() {
        return this.crafts.map(x => craftsContext.get().get(x)?.name).filter(x => x);
    }

    get badge() {
        return null;
    }

    async updateBadge() {
        const lastUpdated = await this.badgeUpdated;
        if (new Date().getTime() - lastUpdated < REFRESH_AVAILABILITY_EVERY) return this;
        this.badgeUpdated = this.updateBadgeImpl();
        await this.badgeUpdated;
        return this;
    }

    updateAccountBadge(accountId: string, badge: BadgeUpdate) {
        (badge as any).accountId = accountId;
        const found = this.badges.findIndex(x => (x as any).accountId === accountId);
        if (found >= 0) this.badges[found] = badge;
        else this.badges.push(badge);
    }

    private async updateBadgeImpl() {
        this.badges = await badgeUpdater.add(this.id);
        return new Date().getTime();
    }
}

const base = createStore<User>('users', User);

export const usersContext = base.context as Context<UsersStore>;
export const userRef = base.ref;

@model('UsersStore')
export class UsersStore extends ExtendedModel(base.Store, {
}) {

    getUserByTeamsId(teamsId: string) {
        return usersContext.getDefault().getAllStatic(user => user.weavixTeamsUserId === teamsId || user.teamsIds?.some(x => x.teamsId === teamsId))[0] || null;
    }

    async searchUsers(text: string) {
        try {
            const users = await SyncService.instance.get<any[]>('/sync/users/search', { text });
            return users.map(user => {
                return this.get(user.id, user);
            });
        } catch (e) {
            console.error(e);
        }
    }
}
