
import { Injectable } from '@angular/core';
import { CommunicationStateServiceStub } from '@weavix/services/src/communication-state.service';
import { AccountService } from './account.service';
import { AlertService } from './alert.service';
import { ChannelService } from './channel.service';

@Injectable()
export class CommunicationStateService extends CommunicationStateServiceStub {
    constructor(
        private channelService: ChannelService,
        private accountService: AccountService,
        private alertService: AlertService,
    ) {
        super();
    }

    async subscribe(component, facilityId: string) {
        if (this.subscription) this.subscription.unsubscribe();
        this.subscription = (await this.channelService.subscribeCommunications(component, facilityId)).subscribe(payload => {
            if (new Date().getTime() < this.to?.getTime()) {
                this.communications[payload.payload.personId] = this.communications[payload.payload.personId] ?? [];
                this.communications[payload.payload.personId].push(payload.payload);
            }
        });
    }

    async initDvrRange(component: any, from: Date, to: Date, timestamp: number, facilityId?: string) {
        from = new Date(timestamp - 30 * 60000);
        to = new Date(timestamp + 30 * 60000);

        const accountId = await this.accountService.getAccountId();
        if (this.from?.getTime() === from.getTime()
            && this.to?.getTime() === to.getTime()
            && this.facilityId === facilityId
            && this.accountId === accountId) {
            await this.promise;
            return;
        }
        this.accountId = accountId;
        this.facilityId = facilityId;
        this.from = from;
        this.to = to;
        this.weekFrom = new Date(this.from.getTime() - 6 * 86400000);

        this.communications = {};

        this.promise = this.channelService.getCommunications(component, facilityId, this.weekFrom, this.to);
        this.alertService.withLoading(this.promise);
        const comms = await this.promise;
        const map = {};
        Object.values(this.communications).forEach(list => list.forEach(v => map[v.id] = v));
        comms.forEach(comm => {
            if (map[comm.id]) return;
            this.communications[comm.personId] = this.communications[comm.personId] ?? [];
            this.communications[comm.personId].push(comm);
        });
    }
}
